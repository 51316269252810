import { axiosCsisApi } from "@csis.com/tip/src/App";
import { TicketFeedbackCreateBody } from "@csis.com/tip/src/api/openapi/data-contracts";
import { FlagName } from "../../models/Flag/Flag";
import { Severity } from "../../models/Severity";
import { Status } from "../../models/Status";
import { NewComment } from "../types";

export async function fetchTicketApi(id: string) {
  return axiosCsisApi.getTicketApi20TicketExternalIdGet(id);
}

export async function fetchCommentsForTicketApi(id: string) {
  return axiosCsisApi.getCommentsApi20TicketExternalIdCommentGet(id);
}

export async function postCommentByTicketIdApi(
  newComment: NewComment,
  ticketId: string,
) {
  return axiosCsisApi.createCommentApi20TicketExternalIdCommentPost(
    ticketId,
    newComment,
  );
}

export async function patchTicketStatusApi({
  ticketId,
  status,
}: {
  ticketId: string;
  status: Status;
}) {
  return axiosCsisApi.updateTicketApi20TicketExternalIdPatch(ticketId, {
    status: status,
  });
}

export async function patchTicketSeverityApi({
  ticketId,
  severity,
}: {
  ticketId: string;
  severity: Severity;
}) {
  return axiosCsisApi.updateTicketApi20TicketExternalIdPatch(ticketId, {
    severity: severity,
  });
}

export async function patchTicketCustomerReference({
  ticketId,
  customerReference,
}: {
  ticketId: string;
  customerReference: string;
}) {
  return axiosCsisApi.updateTicketApi20TicketExternalIdPatch(ticketId, {
    customer_reference: customerReference,
  });
}

export async function patchTicketFlagsApi({
  ticketId,
  flags,
}: {
  ticketId: string;
  flags: FlagName[];
}) {
  return axiosCsisApi.updateTicketApi20TicketExternalIdPatch(ticketId, {
    add_flags: flags,
  });
}

export async function postFeedbackByTicketIdApi(
  feedback: TicketFeedbackCreateBody,
  ticketId: string,
) {
  return axiosCsisApi.createFeedbackApi20TicketExternalIdFeedbackPost(
    ticketId,
    feedback,
  );
}

export async function fetchTicketAttachmentApi(
  ticketId: string,
  attachmentId: string,
) {
  return axiosCsisApi.getAttachmentApi20TicketExternalIdAttachmentAttachmentExternalIdGet(
    attachmentId,
    ticketId,
    {
      format: "blob",
    },
  );
}
