import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

const getEmergencyCaseFetchPending = (state: RootState) =>
  state.emergencyCaseReducer.isEmergencyCasePending;

const getEmergencyCaseFetchError = (state: RootState) =>
  state.emergencyCaseReducer.emergencyCaseFetchError;

export const getEmergencyCase = (state: RootState) =>
  state.emergencyCaseReducer.emergencyCase;

export const getEmergencyCaseResult = createSelector(
  [getEmergencyCase, getEmergencyCaseFetchPending, getEmergencyCaseFetchError],
  (emergencyCase, isEmergencyCasePending, emergencyCaseFetchError) => {
    return {
      emergencyCase,
      isEmergencyCasePending,
      emergencyCaseFetchError,
    };
  },
);

const getEmergencyCaseUpdatePending = (state: RootState) =>
  state.emergencyCaseReducer.isEmergencyCaseUpdating;

const getEmergencyCaseUpdateError = (state: RootState) =>
  state.emergencyCaseReducer.emergencyCaseUpdateError;

const getEmergencyCaseUpdateSuccess = (state: RootState) =>
  state.emergencyCaseReducer.emergencyCaseUpdateSuccess;

export const getEmergencyCaseUpdateResult = createSelector(
  [
    getEmergencyCaseUpdatePending,
    getEmergencyCaseUpdateError,
    getEmergencyCaseUpdateSuccess,
  ],
  (
    emergencyCaseUpdatePending,
    emergencyCaseUpdateError,
    emergencyCaseUpdateSuccess,
  ) => {
    return {
      emergencyCaseUpdatePending,
      emergencyCaseUpdateError,
      emergencyCaseUpdateSuccess,
    };
  },
);

export const getUpdateEmergencyCaseAttachmentSuccess = (state: RootState) =>
  state.emergencyCaseReducer.emergencyCaseAttachmentUpdateSuccess;
export const getIsUpdateEmergencyCaseAttachmentPending = (state: RootState) =>
  state.emergencyCaseReducer.isEmergencyCaseAttachmentUpdating;
export const getUpdateEmergencyCaseAttachmentError = (state: RootState) =>
  state.emergencyCaseReducer.emergencyCaseAttachmentUpdateError;
export const getUpdateEmergencyCaseAttachmentResult = createSelector(
  [
    getUpdateEmergencyCaseAttachmentSuccess,
    getIsUpdateEmergencyCaseAttachmentPending,
    getUpdateEmergencyCaseAttachmentError,
  ],
  (
    emergencyCaseAttachmentUpdateSuccess,
    isEmergencyCaseAttachmentUpdating,
    emergencyCaseAttachmentUpdateError,
  ) => {
    return {
      emergencyCaseAttachmentUpdateSuccess,
      isEmergencyCaseAttachmentUpdating,
      emergencyCaseAttachmentUpdateError,
    };
  },
);

export const getDeleteEmergencyCaseAttachmentSuccess = (state: RootState) =>
  state.emergencyCaseReducer.emergencyCaseAttachmentDeleteSuccess;
export const getIsDeleteEmergencyCaseAttachmentPending = (state: RootState) =>
  state.emergencyCaseReducer.isEmergencyCaseAttachmentDeleting;
export const getDeleteEmergencyCaseAttachmentError = (state: RootState) =>
  state.emergencyCaseReducer.emergencyCaseAttachmentDeleteError;
export const getDeleteEmergencyCaseAttachmentResult = createSelector(
  [
    getDeleteEmergencyCaseAttachmentSuccess,
    getIsDeleteEmergencyCaseAttachmentPending,
    getDeleteEmergencyCaseAttachmentError,
  ],
  (
    emergencyCaseAttachmentDeleteSuccess,
    isEmergencyCaseAttachmentDeleting,
    emergencyCaseAttachmentDeleteError,
  ) => {
    return {
      emergencyCaseAttachmentDeleteSuccess,
      isEmergencyCaseAttachmentDeleting,
      emergencyCaseAttachmentDeleteError,
    };
  },
);

export const getIsPostResendCaseLinkPending = (state: RootState) =>
  state.emergencyCaseReducer.isPostResendCaseLinkPending;

export const getPostResendCaseLinkError = (state: RootState) =>
  state.emergencyCaseReducer.postResendCaseLinkError;

export const getPostResendCaseLinkSuccess = (state: RootState) =>
  state.emergencyCaseReducer.postResendCaseLinkSuccess;

export const getPostResendCaseLinkResult = createSelector(
  [
    getIsPostResendCaseLinkPending,
    getPostResendCaseLinkError,
    getPostResendCaseLinkSuccess,
  ],
  (
    isPostResendCaseLinkPending,
    postResendCaseLinkError,
    postResendCaseLinkSuccess,
  ) => {
    return {
      isPostResendCaseLinkPending,
      postResendCaseLinkError,
      postResendCaseLinkSuccess,
    };
  },
);
