import { getEndDateAsDayBefore } from "./CalendarView/utils";
import { DatePickerOption, DateRange, TimePeriod, timePeriods } from "./types";

export function isDate(x: any): x is Date {
  return x instanceof Date;
}

export function isValidRange(range?: any): range is DateRange {
  return Boolean(
    range && Array.isArray(range) && isDate(range[0]) && isDate(range[1]),
  );
}

export function isValidDate(date?: any): date is Date {
  return Boolean(date && date instanceof Date);
}

function defaultFormatDate(date: Date) {
  return date.toLocaleDateString("en-GB");
}

export function getHeaderTitle(
  headerTitle: string,
  date?: Date,
  range?: DateRange,
  formatDate?: (date?: Date | null) => string,
) {
  if (date) {
    return formatDate ? formatDate(date) : defaultFormatDate(date);
  }
  if (isValidRange(range)) {
    const startDate = range[0];
    const endDate = getEndDateAsDayBefore(range[1]);
    return formatDate
      ? `${formatDate(startDate)} - ${formatDate(endDate)}`
      : `${defaultFormatDate(startDate)} - ${defaultFormatDate(endDate)}`;
  }
  return headerTitle;
}

export function getHeaderValue(
  canSelectRange?: boolean,
  selectedTimePeriod?: TimePeriod,
  availableOptions?: DatePickerOption[],
): TimePeriod | undefined {
  if (canSelectRange) {
    return selectedTimePeriod !== timePeriods.CUSTOM &&
      availableOptions?.find((x) => x.value === selectedTimePeriod)
      ? selectedTimePeriod
      : undefined;
  }
  return undefined;
}

// given a "fixed" TimePeriod (like yesterday/last week etc)
// it returns based on "now" a DateRange tuple [startDate,endDate]
// always start at midnight time (00:00) so "today" means "today midnight" until "tomorrow midnight"
export function getRangeFromTimePeriod(
  timePeriod: TimePeriod,
): DateRange | undefined {
  const TIME_RANGES: Record<TimePeriod, DateRange | undefined> = {
    [timePeriods.TODAY]: [getTodayMidnightUTC(), getTomorrowMidnightUTC()],
    [timePeriods.LAST_7_DAYS]: [
      getSevenDaysAgoMidnightUTC(),
      getTomorrowMidnightUTC(),
    ],
    [timePeriods.PREVIOUS_WEEK]: [
      getFirstDayOfPreviousWeekMidnightUTC(),
      getLastDayOfPreviousWeekMidnightUTC(),
    ],
    [timePeriods.LAST_14_DAYS]: [
      getFourteenDaysAgoMidnightUTC(),
      getTomorrowMidnightUTC(),
    ],
    [timePeriods.LAST_30_DAYS]: [
      getThirtyDaysAgoMidnightUTC(),
      getTomorrowMidnightUTC(),
    ],
    [timePeriods.PREVIOUS_MONTH]: [
      getFirstDayOfPreviousMonthMidnightUTC(),
      getLastDayOfPreviousMonthMidnightUTC(),
    ],
    [timePeriods.LAST_90_DAYS]: [
      getNinetyDaysAgoMidnightUTC(),
      getTomorrowMidnightUTC(),
    ],
    [timePeriods.LAST_365_DAYS]: [
      getOneYearAgoMidnightUTC(),
      getTomorrowMidnightUTC(),
    ],
    [timePeriods.PREVIOUS_QUARTER]: [
      getFirstDayOfPreviousQuarterMidnightUTC(),
      getLastDayOfPreviousQuarterMidnightUTC(),
    ],
    [timePeriods.YTD]: [getStartOfYearMidnightUTC(), getTomorrowMidnightUTC()],
    [timePeriods.ALL_TIME]: undefined,
    [timePeriods.CUSTOM]: undefined,
  };

  return TIME_RANGES[timePeriod];
}

// The opposite of the function above
// given a range [Date,Date] return a TimePeriod
// always start at midnight time (00:00) so "today" means "today midnight" until "tomorrow midnight" etc
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getTimePeriodFromRange(
  range?: DateRange,
): TimePeriod | undefined {
  if (!range) {
    return timePeriods.ALL_TIME;
  }

  const startDate = range[0];
  const endDate = range[1];

  if (
    getTodayMidnightUTC().getTime() === startDate.getTime() &&
    getTomorrowMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.TODAY;
  } else if (
    getSevenDaysAgoMidnightUTC().getTime() === startDate.getTime() &&
    getTomorrowMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.LAST_7_DAYS;
  } else if (
    getFirstDayOfPreviousWeekMidnightUTC().getTime() === startDate.getTime() &&
    getLastDayOfPreviousWeekMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.PREVIOUS_WEEK;
  } else if (
    getFourteenDaysAgoMidnightUTC().getTime() === startDate.getTime() &&
    getTomorrowMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.LAST_14_DAYS;
  } else if (
    getThirtyDaysAgoMidnightUTC().getTime() === startDate.getTime() &&
    getTomorrowMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.LAST_30_DAYS;
  } else if (
    getNinetyDaysAgoMidnightUTC().getTime() === startDate.getTime() &&
    getTomorrowMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.LAST_90_DAYS;
  } else if (
    getFirstDayOfPreviousMonthMidnightUTC().getTime() === startDate.getTime() &&
    getLastDayOfPreviousMonthMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.PREVIOUS_MONTH;
  } else if (
    getOneYearAgoMidnightUTC().getTime() === startDate.getTime() &&
    getTomorrowMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.LAST_365_DAYS;
  } else if (
    getFirstDayOfPreviousQuarterMidnightUTC().getTime() ===
      startDate.getTime() &&
    getLastDayOfPreviousQuarterMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.PREVIOUS_QUARTER;
  } else if (
    getStartOfYearMidnightUTC().getTime() === startDate.getTime() &&
    getTomorrowMidnightUTC().getTime() === endDate.getTime()
  ) {
    return timePeriods.YTD;
  } else {
    return timePeriods.CUSTOM;
  }
}

export function getTodayMidnightUTC() {
  const todayMidnight = new Date();
  todayMidnight.setUTCHours(0, 0, 0, 0);
  return todayMidnight;
}

export function getTomorrowMidnightUTC() {
  const tomorrowMidnight = new Date();
  tomorrowMidnight.setUTCHours(24, 0, 0, 0);
  return tomorrowMidnight;
}

export function getSevenDaysAgoMidnightUTC() {
  const today = new Date();
  const sevenDaysAgo = new Date(new Date().setDate(today.getDate() - 7));
  sevenDaysAgo.setUTCHours(0, 0, 0, 0);
  return sevenDaysAgo;
}

export function getFirstDayOfPreviousWeekMidnightUTC() {
  const today = new Date();
  const day = today.getUTCDay();
  const difference = (day < 1 ? -6 : 1) - day; // if today is Sunday, go back 6 days to last Monday, else go back to the previous Monday
  const lastMonday = new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate() + difference - 7,
    ),
  );
  lastMonday.setUTCHours(0, 0, 0, 0); // set time to midnight
  return lastMonday;
}

export function getLastDayOfPreviousWeekMidnightUTC() {
  const today = new Date();
  const day = today.getUTCDay();
  const difference = (day < 1 ? -6 : 1) - day; // if today is Sunday, go back 6 days to last Monday, else go back to the previous Monday
  const lastMonday = new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate() + difference,
    ),
  );
  lastMonday.setUTCHours(0, 0, 0, 0); // set time to midnight
  return lastMonday;
}

export function getFirstDayOfPreviousMonthMidnightUTC() {
  const today = new Date();
  const firstDayOfPreviousMonth = new Date(
    Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - 1, 1),
  );
  firstDayOfPreviousMonth.setUTCHours(0, 0, 0, 0);
  return firstDayOfPreviousMonth;
}

export function getLastDayOfPreviousMonthMidnightUTC() {
  const today = new Date();
  const lastDayOfPreviousMonth = new Date(
    Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1),
  );
  lastDayOfPreviousMonth.setUTCHours(0, 0, 0, 0);
  return lastDayOfPreviousMonth;
}

export function getFirstDayOfPreviousQuarterMidnightUTC() {
  // quarter starts in January, April, July, October
  // always returns the previews fully completed quarter
  const today = new Date();
  const month = today.getMonth();
  const quarter = Math.floor(month / 3);
  const firstDayOfPreviousQuarter = new Date(
    Date.UTC(today.getUTCFullYear(), quarter * 3 - 3, 1),
  );
  firstDayOfPreviousQuarter.setUTCHours(0, 0, 0, 0);
  return firstDayOfPreviousQuarter;
}

export function getLastDayOfPreviousQuarterMidnightUTC() {
  // quarter starts in January, April, July, October
  // always returns the previews fully completed quarter
  const today = new Date();
  const month = today.getMonth();
  const quarter = Math.floor(month / 3);
  const lastDayOfPreviousQuarter = new Date(
    Date.UTC(today.getUTCFullYear(), quarter * 3, 1),
  );
  lastDayOfPreviousQuarter.setUTCHours(0, 0, 0, 0);
  return lastDayOfPreviousQuarter;
}

export function getFourteenDaysAgoMidnightUTC() {
  const today = new Date();
  const fourtneenDaysAgo = new Date(new Date().setDate(today.getDate() - 14));
  fourtneenDaysAgo.setUTCHours(0, 0, 0, 0);
  return fourtneenDaysAgo;
}

export function getThirtyDaysAgoMidnightUTC() {
  const today = new Date();
  const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));
  thirtyDaysAgo.setUTCHours(0, 0, 0, 0);
  return thirtyDaysAgo;
}

export function getNinetyDaysAgoMidnightUTC() {
  const today = new Date();
  const ninetyDaysAgo = new Date(new Date().setDate(today.getDate() - 90));
  ninetyDaysAgo.setUTCHours(0, 0, 0, 0);
  return ninetyDaysAgo;
}

export function getOneYearAgoMidnightUTC() {
  const today = new Date();
  const oneYearAgo = new Date(new Date().setDate(today.getDate() - 365));
  oneYearAgo.setUTCHours(0, 0, 0, 0);
  return oneYearAgo;
}

export function getFiveYearsAgoAgoMidnightUTC() {
  const today = new Date();
  const fiveYearsAgo = new Date(new Date().setDate(today.getDate() - 1825));
  fiveYearsAgo.setUTCHours(0, 0, 0, 0);
  return fiveYearsAgo;
}

export function getStartOfYearMidnightUTC() {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  startOfYear.setUTCHours(0, 0, 0, 0);
  return startOfYear;
}
