export function checkIfTextContainsSubstring(label: string, substring: string) {
  return label.toLowerCase().includes(substring.toLowerCase());
}

// given a string and a substring that EXISTS in the string,
// it returns the index that the substring starts and ends
export function getSubstringStartAndEnd(
  text: string,
  substring: string,
): [number, number] {
  const substringStart = text.toLowerCase().indexOf(substring.toLowerCase());
  const substringEnd =
    text.toLowerCase().indexOf(substring.toLowerCase()) + substring.length;

  return [substringStart, substringEnd];
}
