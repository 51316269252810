import { ChartColor } from "./types";

// this is a spectrum that goes from csis -> green -> orange -> red
export const DEFAULT_COLORS: [
  ChartColor,
  ChartColor,
  ChartColor,
  ChartColor,
  ChartColor,
  ChartColor,
  ChartColor,
  ChartColor,
  ChartColor,
  ChartColor,
  ChartColor,
] = [
  "csis",
  "data1",
  "data2",
  "data3",
  "data4",
  "data5",
  "data6",
  "data7",
  "data8",
  "data9",
  "data10",
];

export const fontWeight = 500;
export const fontSize = 12;
export const axisMargin = 10;

// Widths
export const cartesianStrokeWidth = "2";

// Line
export const lineStyle = "monotone";
export const lineWidth = "3";

// Bar
export const maxBarSize = 12;
