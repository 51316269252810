import * as qs from "query-string";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Toast } from "@csis.com/components";
import { isNullOrUndefined } from "@csis.com/components/src/utils/utils";
import AppHeader from "@csis.com/tip/src/components/AppHeader/AppHeader";
import { SidebarWrapper as Sidebar } from "@csis.com/tip/src/components/wrappers/SidebarWrapper";
import sidebarConfig from "@csis.com/tip/src/config/sidebarConfig";
import Loading from "@csis.com/tip/src/pages/Loading/Loading";
import * as loginSelectors from "@csis.com/tip/src/pages/Login/selectors";
import {
  postLogout,
  setRedirectUrl,
} from "@csis.com/tip/src/pages/Login/slice";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import AppErrorBoundary from "./AppErrorBoundary";
import { useHandleOrgIdInParams } from "./hooks/useHandleOrgIdInParams";
import { getSelectedOrgId } from "./pages/Profile/Security/selectors";
import routes from "./routes/routes";
import {
  getUserPreferences,
  getUserPreferencesForGeneral,
} from "./userPreferences/selectors";
import { fetchUserPreferences } from "./userPreferences/slice";
import { setHighContrast, setTheme } from "./utils/setTheme";
import { useUpdateAndReplaceQueryParams } from "./utils/updateAndReplaceQueryParams";

export const ORG_ID_PARAM_KEY = "organizationId";

function MainApp() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslations();
  const themePreference = useSelector(
    getUserPreferencesForGeneral,
  )?.themePreference;
  const prefersHighContrast = useSelector(
    getUserPreferencesForGeneral,
  )?.prefersHighContrast;
  const isSystemThemeDark = window.matchMedia(
    "(prefers-color-scheme: dark)",
  ).matches;

  const [showOrgIdChanged, setShowOrgIdChanged] = useState(false);

  const handleOrgIdInParams = useHandleOrgIdInParams();
  const selectedOrgId = useSelector(getSelectedOrgId);

  const updateAndReplaceQueryParams = useUpdateAndReplaceQueryParams();
  const queryParams = qs.parse(window.location.search);

  useEffect(() => {
    if (location.pathname === urls.logout) {
      dispatch(postLogout());
      return;
    }

    // this handles the case where the user has already a selected organizationId and it does NOT exist in the query params
    // so we just add it in the url
    if (selectedOrgId && !location.search.includes(ORG_ID_PARAM_KEY)) {
      updateAndReplaceQueryParams(ORG_ID_PARAM_KEY, selectedOrgId);
    }

    // this is the "opposite" of the above
    // if the user has an organizationId in the query params, we try to see if it is a valid one and then set it in the store
    // this SHOULD NOT matter during the first visit for cases where we want to handle
    // "deep linking" or "refreshing" of the page with a specific organizationId in the URL
    // since the profile slice will handle that
    // BUT this handles the cases where the the user navigates using the back/forward buttons thus changing the URL
    // so we need to update the store in that case
    const orgIdFromParams = queryParams[ORG_ID_PARAM_KEY];
    if (orgIdFromParams) {
      handleOrgIdInParams(orgIdFromParams, setShowOrgIdChanged);
    }
  }, [
    dispatch,
    handleOrgIdInParams,
    location.pathname,
    location.search,
    queryParams,
    selectedOrgId,
    updateAndReplaceQueryParams,
  ]);

  const userPreferences = useSelector(getUserPreferences);

  useEffect(() => {
    if (isNullOrUndefined(userPreferences)) {
      dispatch(fetchUserPreferences());
    }

    // Theme handling logic
    const effectiveThemePreference =
      themePreference === "system"
        ? isSystemThemeDark
          ? "dark"
          : "light"
        : themePreference;
    setTheme(effectiveThemePreference || "light");

    // High contrast setting
    setHighContrast(prefersHighContrast || false);
  }, [
    themePreference,
    isSystemThemeDark,
    userPreferences,
    dispatch,
    prefersHighContrast,
  ]);

  useEffect(() => {
    setHighContrast(prefersHighContrast || false);
  }, [prefersHighContrast]);

  const redirectUrl = useSelector(loginSelectors.getRedirectUrl);
  const currentUrl = window.location.pathname + window.location.search;

  if (redirectUrl && redirectUrl !== currentUrl) {
    // if there is a redirectUrl - after a successful login, redirect the user there
    // and "null-ify" it
    dispatch(setRedirectUrl(null));
    history.push(redirectUrl);
    return;
  }

  return (
    <React.StrictMode>
      <div className="main-app">
        <AppErrorBoundary>
          <Sidebar route={location.pathname} menuItems={sidebarConfig} />
        </AppErrorBoundary>
        <div className="main-app__container">
          {/* @ts-ignore */}
          <Switch>
            {routes.map((route) => (
              // @ts-ignore
              <Route
                exact
                key={route.path}
                path={route.path}
                render={() => (
                  <AppHeader
                    pageTitle={t(route.title)}
                    pageSubTitle={t(route.subtitle)}
                  />
                )}
              />
            ))}
          </Switch>
          <AppErrorBoundary>
            <Suspense fallback={<Loading />}>
              {/* @ts-ignore */}
              <Switch>
                {/* @ts-ignore */}
                <Route
                  exact
                  path="/"
                  render={() => (
                    // @ts-ignore
                    <Redirect to={urls.products} />
                  )}
                />
                {routes.map((route) => (
                  // @ts-ignore
                  <Route
                    exact
                    key={route.path}
                    path={route.path}
                    // @ts-ignore
                    component={route.component}
                  />
                ))}
              </Switch>
            </Suspense>
            <Toast
              icon="info"
              isShown={showOrgIdChanged}
              onHide={() => setShowOrgIdChanged(false)}
              text="You tried to access this page for an organization ID that is invalid. Using your default organization."
            />
          </AppErrorBoundary>
        </div>
      </div>
    </React.StrictMode>
  );
}

export default MainApp;
