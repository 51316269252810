import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { all, put, select, takeLatest } from "redux-saga/effects";
import { isNullOrUndefined } from "@csis.com/components/src/utils/utils";
import {
  deleteCookie,
  getCookieValue,
  getLocalStorage,
  setLocalStorage,
} from "@csis.com/tip/src/pages/Login/utils";
import { defaultPreferences } from "./defaultPreferences";
import { getUserPreferences } from "./selectors";
import { Localization, ThemePreference, UserPreferences } from "./types";

interface StateSlice {
  userPreferences: UserPreferences | null;
  isPending: boolean;
  fetchError: string | null;
}
const initialState: StateSlice = {
  userPreferences: null,
  isPending: false,
  fetchError: null,
};

const userPreferencesSlice = createSlice({
  name: "userPreferences",
  initialState: initialState,
  reducers: {
    fetchUserPreferences() {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<UserPreferences>) {
      state.isPending = false;
      state.userPreferences = action.payload;
      state.fetchError = null;
    },
    setUserPreferences(state, action: PayloadAction<UserPreferences>) {
      state.isPending = false;
      state.userPreferences = action.payload;
    },

    updateUserPreferences(_state, _action: PayloadAction<UserPreferences>) {
      //empty handled by saga
    },

    changeTheme(_state, _action: PayloadAction<ThemePreference>) {},
    changeHighContrast(_state, _action: PayloadAction<boolean>) {},
    changeLocalization(_state, _action: PayloadAction<Localization>) {},
  },
});

export default userPreferencesSlice.reducer;

export const {
  fetchUserPreferences,
  setPending,
  setFetchError,
  fetchSuccess,
  setUserPreferences,

  updateUserPreferences,

  changeTheme,
  changeHighContrast,
  changeLocalization,
} = userPreferencesSlice.actions;

function* fetchUserPreferencesSaga() {
  yield put(setPending());

  try {
    // this will be received from a server request
    const responseFromCookie = getCookieValue("userPreferences");
    const responseFromLocalStorage = getLocalStorage("userPreferences");

    if (
      isNullOrUndefined(responseFromLocalStorage) ||
      responseFromLocalStorage === ""
    ) {
      // 1 if local storage prefs do not exist:
      if (isNullOrUndefined(responseFromCookie) || responseFromCookie === "") {
        // 1.1 they do not exist on the cookie either so we set the default ones
        yield put(fetchSuccess(defaultPreferences));
        yield put(updateUserPreferences(defaultPreferences));
      } else {
        // 1.2 they exist on cookie, so we use those
        const userPrefs = JSON.parse(responseFromCookie) as unknown;
        yield put(updateUserPreferences(userPrefs as UserPreferences));
        yield put(fetchSuccess(userPrefs as UserPreferences));
        // and delete the cookie!
        deleteCookie("userPreferences");
      }
    } else {
      // 2 local storage prefs exist
      const userPrefs = JSON.parse(responseFromLocalStorage) as unknown;
      yield put(fetchSuccess(userPrefs as UserPreferences));
    }
  } catch (e) {}
}

function* updateUserPreferencesSaga(action: PayloadAction<UserPreferences>) {
  try {
    setLocalStorage("userPreferences", JSON.stringify(action.payload));
    // also update the "local" object so its up to date
    yield put(setUserPreferences(action.payload as UserPreferences));
  } catch (e) {}
}

function* changeThemeSaga(action: PayloadAction<ThemePreference>) {
  const userPreferences: UserPreferences = yield select(getUserPreferences);

  if (userPreferences) {
    const newPrefs = { ...userPreferences };
    newPrefs.general = { ...newPrefs.general, themePreference: action.payload };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* changeHighContrastSaga(action: PayloadAction<boolean>) {
  const userPreferences: UserPreferences = yield select(getUserPreferences);

  if (userPreferences) {
    const newPrefs = { ...userPreferences };
    newPrefs.general = {
      ...newPrefs.general,
      prefersHighContrast: action.payload,
    };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* changeLocalizationSaga(action: PayloadAction<Localization>) {
  const localization = action.payload;
  const userPreferences: UserPreferences = yield select(getUserPreferences);

  if (userPreferences) {
    const newPrefs = { ...userPreferences };
    newPrefs.localization = localization;

    yield put(updateUserPreferences(newPrefs));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchUserPreferences.toString(), fetchUserPreferencesSaga);
  yield takeLatest(updateUserPreferences.toString(), updateUserPreferencesSaga);
  yield takeLatest(changeTheme.toString(), changeThemeSaga);
  yield takeLatest(changeHighContrast.toString(), changeHighContrastSaga);
  yield takeLatest(changeLocalization.toString(), changeLocalizationSaga);
}

export function* userPreferencesSagas() {
  yield all([actionWatcher()]);
}
