import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  ServiceRead,
  ServiceReadListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";

const servicesSlice = createAsyncArrayFetchSlice<ServiceRead, {}>("services");

export default servicesSlice.reducer;

export const {
  fetchData: fetchServices,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = servicesSlice.actions;

// Async stuff - sagas

function* fetchServicesSaga() {
  yield put(setIsPending());
  try {
    const response: AxiosResponse<ServiceReadListResponse> = yield call(
      axiosCsisApi.getServicesApi10InternalServiceGet,
      {},
    );

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    yield put(setFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchServices.toString(), fetchServicesSaga);
}

export function* servicesSagas() {
  yield all([actionWatcher()]);
}
