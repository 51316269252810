import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  AdminUserPreview,
  EmergencyResponseCasePreview,
  EmergencyResponseCasePreviewPageDetailResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import {
  STRINGIFY_QUERY_PARAMS,
  handleRequestError,
} from "@csis.com/tip/src/api/utils";
import { axiosCsisApi } from "../../../App";
import { createAsyncArrayFetchSlice } from "../../../sliceHelpers/createAsyncArrayFetchSlice";
import { EmergencyCasesColumns, columns } from "./Table/columns";
import { AdminUsersResponse, QueryParams } from "./types";

const emergencyCasesSlice = createAsyncArrayFetchSlice<
  EmergencyResponseCasePreview,
  QueryParams
>("emergencyCases");

export const emergencyCasesReducer = emergencyCasesSlice.reducer;

export const {
  fetchData: fetchEmergencyCases,
  setIsPending: setEmergencyCasesPending,
  setFetchError: setFetchEmergencyCasesError,
  setFetchSuccess: fetchEmergencyCasesSuccess,
  setHasNextPage,
} = emergencyCasesSlice.actions;

interface StateSlice {
  columns: EmergencyCasesColumns;
  adminUsers: AdminUserPreview[];
  isAdminUsersPending: boolean;
  fetchAdminUsersError: string | null;
}

const initialState: StateSlice = {
  columns: columns,
  adminUsers: [],
  isAdminUsersPending: false,
  fetchAdminUsersError: null,
};

const emergencyCasesAdminSlice = createSlice({
  name: "emergencyCasesAdmin",
  initialState: initialState,
  reducers: {
    reorderColumns(state, action: PayloadAction<EmergencyCasesColumns>) {
      state.columns = action.payload;
    },

    fetchAdminUsers(_state, _action: PayloadAction<void>) {
      //empty handled by saga
    },
    fetchAdminUsersPending(state) {
      state.isAdminUsersPending = true;
      state.fetchAdminUsersError = null;
      state.adminUsers = [];
    },
    fetchAdminUsersSuccess(state, action: PayloadAction<any[]>) {
      state.isAdminUsersPending = false;
      state.fetchAdminUsersError = null;
      state.adminUsers = action.payload;
    },
    fetchAdminUsersError(state, action: PayloadAction<string>) {
      state.isAdminUsersPending = false;
      state.fetchAdminUsersError = action.payload;
      state.adminUsers = [];
    },
  },
});

export const emergencyCasesAdminReducer = emergencyCasesAdminSlice.reducer;

export const {
  reorderColumns,
  fetchAdminUsers,
  fetchAdminUsersPending,
  fetchAdminUsersSuccess,
  fetchAdminUsersError,
} = emergencyCasesAdminSlice.actions;

function* fetchAdminUsersSaga() {
  yield put(fetchAdminUsersPending());
  try {
    const response: AxiosResponse<AdminUsersResponse> = yield call(
      axiosCsisApi.getAdminUsersApi10AdminUserAdminGet,
    );
    yield put(fetchAdminUsersSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(fetchAdminUsersError(errorMessage));
  }
}

function* fetchEmergencyCasesSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setEmergencyCasesPending());
  try {
    const response: AxiosResponse<EmergencyResponseCasePreviewPageDetailResponse> =
      yield call(
        axiosCsisApi.getEmergencyResponseCasesApi10AdminEmergencyResponseCaseGet,
        action.payload,
        STRINGIFY_QUERY_PARAMS,
      );

    yield put(fetchEmergencyCasesSuccess(response.data.payload.page));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchEmergencyCasesError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchEmergencyCases.toString(), fetchEmergencyCasesSaga);
  yield takeLatest(fetchAdminUsers.toString(), fetchAdminUsersSaga);
}

export function* emergencyCasesSaga() {
  yield all([actionWatcher()]);
}
