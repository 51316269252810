import { isValidDate } from "../utils";

export function getStartDate(
  date?: Date,
  startDate?: Date,
  canSelectRange?: boolean,
) {
  if (canSelectRange) {
    return isValidDate(startDate) ? startDate : new Date();
  } else {
    return date || new Date();
  }
}

// the "end date" in the calendar is always the "next" day midnight
// but in the calendar it doesnt make sense to "highlight" that day so we highlight until 1 day before
// like turning a day : 00:00:00 to day-1 23:59:59
export function getEndDateAsDayBefore(endDate: Date) {
  // always clone the object, dont modify
  const dayBefore = new Date(endDate);
  dayBefore.setDate(endDate.getDate() - 1);
  return dayBefore;
}

// transform the "end date" as "day+1 00:00:00"
export function getEndDateAsDayAfter(endDate: Date) {
  // always clone the object, dont modify
  const dayAfter = new Date(endDate);
  dayAfter.setDate(endDate.getDate() + 1);
  dayAfter.setUTCHours(0, 0, 0, 0);
  return dayAfter;
}
