import { Attachment } from "./types";

export function getEmergencyCaseAttachments(
  files: Array<Attachment> | undefined,
) {
  if (!files) return [];
  return files.map((item: Attachment) => ({
    filename: item.filename,
    id: item.external_id,
    size: item.content_length,
  }));
}
