import { urlsKeys } from "../constants";
import { QueryParams } from "../types";

export function mapUrlsStartAndEndDateToCorrectType(
  queryParams: Partial<QueryParams>,
) {
  return Object.assign({}, queryParams, {
    [urlsKeys.CREATED_AFTER]: queryParams[urlsKeys.START_DATE],
    [urlsKeys.CREATED_BEFORE]: queryParams[urlsKeys.END_DATE],
  });
}

// this uses the new and improved params start-end but not all endpoint support it
export function mapUrlsStartAndEndDateToCorrectType2(
  queryParams: Partial<QueryParams>,
) {
  return Object.assign({}, queryParams, {
    [urlsKeys.START]: queryParams[urlsKeys.START_DATE],
    [urlsKeys.END]: queryParams[urlsKeys.END_DATE],
  });
}
