/* eslint-disable sonarjs/cognitive-complexity */
import classNames from "classnames";
import React, {
  KeyboardEvent,
  MouseEvent,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { LARGE, NORMAL, SMALL } from "../../sizes";
import { toggleArrayItem } from "../../utils/utils";
import CheckBox from "../CheckBox/CheckBox";
import IconButton from "../IconButton/IconButton";
import BookmarkableCell from "./BookmarkableCell/BookmarkableCell";
import TableCell from "./TableCell";
import * as types from "./types";
import * as utils from "./utils";

function getCheckBoxValue<ROW extends types.RowInterface>(
  row: ROW,
  selectedRows?: types.SelectedRows,
) {
  if (!selectedRows) {
    return undefined;
  }
  const rowValue = row[selectedRows?.key];
  if (typeof rowValue === "string" || typeof rowValue === "number") {
    return rowValue;
  }
  return undefined;
}

function renderCell(cell: types.Cell | React.ReactNode) {
  if (typeof cell === "number" || typeof cell === "string") {
    return cell;
  }
  if (Array.isArray(cell)) {
    return (
      <>
        {cell.map((val) => {
          if (typeof val === "string") {
            return <div key={"sub_cell_" + val}>{val}</div>;
          } else {
            return null;
          }
        })}
      </>
    );
  }
  return null;
}

const CalculateCell = <
  ROW extends types.RowInterface,
  COLUMN_KEY extends string,
>({
  row,
  column,
  index,
  formatters,
  bookmarkedRows,
  isBookmarkable,
}: {
  row: ROW;
  column: types.ColumnInterface<COLUMN_KEY, string>;
  index: number;
  formatters?: types.TableCellFormatters<COLUMN_KEY>;
  bookmarkedRows?: types.BookmarkedRows;
  isBookmarkable?: boolean;
}) => {
  const ckey = column.key;

  const cellContent = useMemo(() => {
    // helper type checker function to verify a string is of type N
    function isN(a: string): a is COLUMN_KEY {
      return Boolean(formatters && Object.keys(formatters).includes(a));
    }

    // extract the formatter for the current column key / if it exists
    const formatter = formatters?.[ckey];

    if (formatter && isN(ckey)) {
      return formatter(row, ckey);
    } else {
      return renderCell(row[ckey]);
    }
  }, [ckey, formatters, row]);

  // we need to make sure a formatter exists
  // and that the column key is part of the formatters object
  // or in other words ckey (type K) is in type N

  return isBookmarkable && index === 0 ? (
    <BookmarkableCell
      key={"table_cell_" + ckey + index}
      isBookmarked={utils.isCellBookmarked(row[ckey], bookmarkedRows)}
      cellContent={cellContent}
    />
  ) : (
    <TableCell column={column} index={index} cellContent={cellContent} />
  );
};

const SubRowIcon = <ROW extends types.RowInterface>({
  row,
  isRowExpanded,
  onClick,
}: {
  row: ROW;
  isRowExpanded: boolean;
  onClick: () => void;
}) => {
  return (
    <td className="table__subrow-arrow-cell cell">
      {hasSubElementCell(row) && (
        <IconButton
          type="text"
          icon={isRowExpanded ? "up-arrow" : "down-arrow"}
          color="secondary"
          isCircle
          onButtonClick={onClick}
        />
      )}
    </td>
  );
};

const hasSubElementCell = <ROW extends types.RowInterface>(row: ROW) => {
  return Boolean(row.subElement);
};

const isRowExpanded = <ROW extends types.RowInterface>(
  row: ROW,
  expandedRows: Array<string>,
) => {
  // @ts-ignore
  return expandedRows.includes(row.id?.toString() ?? "");
};

const MemoCalculateCell = memo(CalculateCell) as typeof CalculateCell;
export interface TableRowInterface<
  ROW extends types.RowInterface,
  COLUMN_KEY extends string,
> {
  rows?: ROW[];
  columns?: types.ColumnInterface<COLUMN_KEY, string>[];
  formatters?: types.TableCellFormatters<COLUMN_KEY>;
  colSpan?: number;
  tableName: string;
  isSelectable?: boolean;
  subElementsConfig?: types.SubElementsConfig<COLUMN_KEY>;
  size?: SMALL | NORMAL | LARGE;
  isBookmarkable?: boolean;
  dataTestId?: string;
  selectedRows?: types.SelectedRows;
  bookmarkedRows?: types.BookmarkedRows;
  onSelectRow?: types.OnSelectRow;
  onRowClick?: types.OnRowClick<ROW>;
}

const TableRows = <ROW extends types.RowInterface, COLUMN_KEY extends string>({
  rows,
  columns,
  tableName,
  formatters,
  colSpan,
  size,
  isSelectable,
  subElementsConfig,
  isBookmarkable,
  bookmarkedRows,
  dataTestId,
  selectedRows,
  onSelectRow,
  onRowClick,
}: TableRowInterface<ROW, COLUMN_KEY>) => {
  const classes = classNames("table__row", {
    "table__row--clickable": Boolean(onRowClick),
  });

  const handleRowClick = useCallback(
    (e: MouseEvent, row: ROW) => {
      // we check if any text is selected, so we can ignore the onClick
      const selection = window.getSelection()?.toString();
      // uf the ctrl key is pressed or the cmd key on mac
      //we want to open the row in a new tab
      if (onRowClick && (selection?.length === 0 || e.ctrlKey || e.metaKey)) {
        onRowClick(row, e.ctrlKey || e.metaKey);
      }
    },
    [onRowClick],
  );

  const handleAuxRowClick = useCallback(
    (_e: MouseEvent, row: ROW) => {
      // handle middle click or mousewheel click ->
      // trigger onRowClick with ctrl key pressed = "true"
      // which ultimately leads the same behaviour which is to
      // open the row in a new window
      if (onRowClick) {
        onRowClick(row, true);
      }
    },
    [onRowClick],
  );

  // when the row is focused we can press enter
  const handleKeyPress = useCallback(
    (event: KeyboardEvent, row: ROW) => {
      if (event.key === "Enter" && onRowClick) {
        event.stopPropagation();
        event.preventDefault();
        onRowClick(row);
      }
    },
    [onRowClick],
  );

  // keep a local state for each row and if it is expanded or not
  // for now we assume id cell exists and is unique later to add the option for the user to specify the key
  // also add a case where all rows are expanded by default
  const [expandedRows, setExpandedRows] = useState<Array<string>>([]);

  return (
    <>
      {rows?.map((row: ROW, index) => (
        <React.Fragment key={"table_row_" + index}>
          <tr
            className={classes}
            data-test-id={
              dataTestId
                ? dataTestId + "-table-row-" + index
                : "table-row-" + index
            }
            onClick={(ev) => handleRowClick(ev, row)}
            onAuxClick={(ev) => handleAuxRowClick(ev, row)}
            onKeyPress={(ev) => handleKeyPress(ev, row)}
            tabIndex={Boolean(onRowClick) ? 0 : -1}
          >
            {isSelectable && (
              <td className="checkbox-cell">
                <CheckBox
                  onClick={
                    onSelectRow
                      ? () => onSelectRow(getCheckBoxValue(row, selectedRows))
                      : undefined
                  }
                  isChecked={utils.isRowSelected(row, selectedRows)}
                  name={`${tableName}-check-${Math.random()}`}
                  clickableArea={size === "large" ? "big" : size}
                  dataTestId={dataTestId + "_checkbox_row_" + index}
                />
              </td>
            )}
            {subElementsConfig && (
              <SubRowIcon
                row={row}
                isRowExpanded={isRowExpanded(row, expandedRows)}
                onClick={() => {
                  setExpandedRows((prev) => {
                    return toggleArrayItem(
                      prev,
                      // @ts-ignore
                      row[subElementsConfig.key]?.toString() ?? "",
                    );
                  });
                }}
              />
            )}
            {columns?.map((column, index) => {
              if (column.isHidden) {
                return null;
              }

              return (
                <MemoCalculateCell
                  key={"calc_cell_" + column.key + index}
                  row={row}
                  column={column}
                  index={index}
                  formatters={formatters}
                  isBookmarkable={isBookmarkable}
                  bookmarkedRows={bookmarkedRows}
                />
              );
            })}
          </tr>
          {hasSubElementCell(row) && isRowExpanded(row, expandedRows) && (
            <tr>
              <td colSpan={colSpan}>
                <div className="table__subrow-element">
                  {row.subElement as React.ReactNode}
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default TableRows;
