import classNames from "classnames";
import React, { useCallback } from "react";
import Icon, { IconKind } from "../../icons/Icon";
import { Spinner } from "../../atoms";
import { BLUE, CSIS, GREY, RED, SECONDARY, YELLOW } from "../../colors";
import { BIG, LARGE, NORMAL, SIZE, SMALL, TINY } from "../../sizes";

export interface IconButtonInterface {
  type?: "primary" | "text";
  color?: BLUE | RED | GREY | CSIS | YELLOW | SECONDARY;
  icon: IconKind;
  isDisabled?: boolean;
  isLoading?: boolean;
  isCircle?: boolean;
  size?: SIZE;
  spacing?: TINY | SMALL | NORMAL | BIG | LARGE;
  tooltipText?: string;
  onButtonClick: () => void;
  onHover?: () => void;
  fixedBottom?: boolean; // button is positioned fixed to the bottom right
  ariaHasPopup?: "menu";
  ariaExpanded?: boolean;
  id?: string;
  dataTestId?: string;
}

const IconButton: React.FC<IconButtonInterface> = ({
  type = "primary",
  icon,
  isDisabled,
  isLoading,
  isCircle,
  onButtonClick,
  onHover,
  color,
  spacing = "small",
  fixedBottom,
  size,
  tooltipText,
  ariaHasPopup,
  ariaExpanded,
  id,
  dataTestId,
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onButtonClick();
    },
    [onButtonClick],
  );

  const classes = classNames("button-icon", {
    "button-icon--primary": type === "primary",
    "button-icon--text": type === "text",
    "button-icon--disabled": isDisabled,
    "button-icon--circle": isCircle,
    "button-icon--blue": color === "blue",
    "button-icon--red": color === "red",
    "button-icon--grey": color === "grey",
    "button-icon--secondary": color === "secondary",
    "button-icon--yellow": color === "yellow",
    "button-icon--spacing-tiny": spacing === "tiny",
    "button-icon--spacing-small": spacing === "small",
    "button-icon--spacing-normal": spacing === "normal",
    "button-icon--spacing-big": spacing === "big",
    "button-icon--spacing-large": spacing === "large",
    "button-icon--fixed-bottom": fixedBottom,
  });
  return (
    <button
      disabled={isDisabled || isLoading}
      onClick={handleClick}
      className={classes}
      aria-label={tooltipText || icon}
      title={tooltipText}
      data-test-id={dataTestId}
      aria-haspopup={ariaHasPopup}
      aria-expanded={ariaExpanded}
      onMouseOver={onHover}
      type="button"
      id={id}
    >
      {isLoading ? (
        <Spinner size={"small"} />
      ) : (
        <Icon kind={icon} size={size} />
      )}
    </button>
  );
};

export default IconButton;
