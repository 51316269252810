import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  StatisticsCountListResponse,
  StatisticsHistogramCountListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { QueryParams, TypeHistogramEntry, TypeTotalEntry } from "../types";
import {
  fetchTypeStatisticsApi,
  fetchTypeStatisticsHistogramApi,
} from "./api/api";

interface StateSlice {
  typeDistribution: TypeTotalEntry[];
  isTypeDistributionPending: boolean;
  typeDistributionFetchError: string | null;

  typeDistributionOverTime: TypeHistogramEntry[];
  isTypeDistributionOverTimePending: boolean;
  typeDistributionOverTimeFetchError: string | null;
}
const initialState: StateSlice = {
  typeDistribution: [],
  isTypeDistributionPending: false,
  typeDistributionFetchError: null,

  typeDistributionOverTime: [],
  isTypeDistributionOverTimePending: false,
  typeDistributionOverTimeFetchError: null,
};

const ticketsStatisticsSlice = createSlice({
  name: "ticketsStatisticsType",
  initialState: initialState,
  reducers: {
    fetchTypeDistribution(
      _state,
      _action: PayloadAction<Partial<QueryParams>>,
    ) {
      //empty handled by saga
    },
    setIsTypeDistributionPending(state) {
      state.isTypeDistributionPending = true;
      state.typeDistributionFetchError = null;
    },
    setTypeDistributionFetchError(state) {
      state.isTypeDistributionPending = false;
      state.typeDistributionFetchError = "Something went wrong";
    },
    setFetchTypeDistributionSuccess(
      state,
      action: PayloadAction<TypeTotalEntry[]>,
    ) {
      state.isTypeDistributionPending = false;
      state.typeDistribution = action.payload;
      state.typeDistributionFetchError = null;
    },

    fetchTypeDistributionOverTime(
      _state,
      _action: PayloadAction<Partial<QueryParams>>,
    ) {
      //empty handled by saga
    },
    setIsTypeDistributionOverTimePending(state) {
      state.isTypeDistributionOverTimePending = true;
      state.typeDistributionOverTimeFetchError = null;
    },
    setTypeDistributionOverTimeFetchError(state) {
      state.isTypeDistributionOverTimePending = false;
      state.typeDistributionOverTimeFetchError = "Something went wrong";
    },
    setFetchTypeDistributionOverTimeSuccess(
      state,
      action: PayloadAction<TypeHistogramEntry[]>,
    ) {
      state.isTypeDistributionOverTimePending = false;
      state.typeDistributionOverTime = action.payload;
      state.typeDistributionOverTimeFetchError = null;
    },
  },
});

export default ticketsStatisticsSlice.reducer;

export const {
  fetchTypeDistribution,
  setIsTypeDistributionPending,
  setTypeDistributionFetchError,
  setFetchTypeDistributionSuccess,

  fetchTypeDistributionOverTime,
  setIsTypeDistributionOverTimePending,
  setTypeDistributionOverTimeFetchError,
  setFetchTypeDistributionOverTimeSuccess,
} = ticketsStatisticsSlice.actions;

// Async stuff - sagas

function* fetchTypeDistributionSaga(
  action: PayloadAction<Partial<QueryParams>>,
) {
  yield put(setIsTypeDistributionPending());

  try {
    const response: AxiosResponse<StatisticsCountListResponse> = yield call(
      fetchTypeStatisticsApi,
      action.payload,
    );

    yield put(setFetchTypeDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setTypeDistributionFetchError());
  }
}

function* fetchTypeDistributionOverTimeSaga(
  action: PayloadAction<Partial<QueryParams>>,
) {
  yield put(setIsTypeDistributionOverTimePending());

  try {
    const response: AxiosResponse<StatisticsHistogramCountListResponse> =
      yield call(fetchTypeStatisticsHistogramApi, action.payload);

    yield put(setFetchTypeDistributionOverTimeSuccess(response.data.payload));
  } catch (e) {
    yield put(setTypeDistributionOverTimeFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchTypeDistribution.toString(), fetchTypeDistributionSaga);
  yield takeLatest(
    fetchTypeDistributionOverTime.toString(),
    fetchTypeDistributionOverTimeSaga,
  );
}

export function* ticketsStatisticsTypeSagas() {
  yield all([actionWatcher()]);
}
