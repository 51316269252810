import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import {
  fetchBusinessUnitsApi,
  fetchBusinessUnitsWithRetainersApi,
} from "./api/api";
import { BusinessUnitsResponse } from "./api/types";
import { BusinessUnit, BusinessUnitWithRetainer } from "./types";

export interface BusinessUnitSelectState {
  businessUnits: BusinessUnit[];
  businessUnitsWithRetainer: BusinessUnitWithRetainer[];
  isBusinessUnitsPending: boolean;
  businessUnitsFetchError: string | null;
}

const initialState: BusinessUnitSelectState = {
  businessUnits: [],
  businessUnitsWithRetainer: [],
  isBusinessUnitsPending: false,
  businessUnitsFetchError: null,
};

const businessUnitSelectSlice = createSlice({
  name: "businessUnitSelect",
  initialState,
  reducers: {
    fetchBusinessUnitsWithRetainers(_state) {
      //empty handled by saga
    },
    fetchBusinessUnits(_state) {
      //empty handled by saga
    },
    setBusinessUnitsPending(state) {
      state.isBusinessUnitsPending = true;
      state.businessUnitsFetchError = null;
      state.businessUnits = [];
    },
    setFetchBusinessUnitsError(state, action: PayloadAction<string>) {
      state.isBusinessUnitsPending = false;
      state.businessUnitsFetchError = action.payload;
      state.businessUnits = [];
    },
    fetchBusinessUnitsSuccess(
      state,
      action: PayloadAction<Array<BusinessUnit>>,
    ) {
      state.isBusinessUnitsPending = false;
      state.businessUnitsFetchError = null;
      state.businessUnits = action.payload;
    },
    fetchBusinessUnitsWithRetainerSuccess(
      state,
      action: PayloadAction<Array<BusinessUnitWithRetainer>>,
    ) {
      state.isBusinessUnitsPending = false;
      state.businessUnitsFetchError = null;
      state.businessUnitsWithRetainer = action.payload;
    },
  },
});

export const {
  fetchBusinessUnits,
  fetchBusinessUnitsWithRetainers,
  fetchBusinessUnitsSuccess,
  setBusinessUnitsPending,
  setFetchBusinessUnitsError,
  fetchBusinessUnitsWithRetainerSuccess,
} = businessUnitSelectSlice.actions;

export default businessUnitSelectSlice.reducer;

function* fetchBusinessUnitsSaga() {
  yield put(setBusinessUnitsPending());
  try {
    const response: AxiosResponse<BusinessUnitsResponse> = yield call(
      fetchBusinessUnitsApi,
      {
        offset: 0,
        limit: 1000,
      },
    );

    yield put(fetchBusinessUnitsSuccess(response.data.payload.page));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchBusinessUnitsError(errorMessage));
  }
}

function* fetchBusinessUnitsWithRetainersSaga() {
  yield put(setBusinessUnitsPending());
  try {
    const response: AxiosResponse<BusinessUnitsResponse> = yield call(
      fetchBusinessUnitsWithRetainersApi,
      {
        offset: 0,
        limit: 1000,
      },
    );

    yield put(
      fetchBusinessUnitsWithRetainerSuccess(response.data.payload.page),
    );
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchBusinessUnitsError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchBusinessUnits.toString(), fetchBusinessUnitsSaga);
  yield takeLatest(
    fetchBusinessUnitsWithRetainers.toString(),
    fetchBusinessUnitsWithRetainersSaga,
  );
}

export function* businessUnitsSagas() {
  yield all([actionWatcher()]);
}
