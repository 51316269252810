import classNames from "classnames";
import { useCallback, useState } from "react";
import Icon from "../../../icons/Icon";
import Grid from "../../../layout/Grid/Grid";
import GridItem from "../../../layout/Grid/GridItem/GridItem";
import Stack from "../../../layout/Stack/Stack";
import Button from "../../Button/Button";
import Card from "../../Card/Card";
import Dialog from "../../Dialog/Dialog";
import DraggableList from "../../DraggableList/DraggableList";
import IconButton from "../../IconButton/IconButton";
import { ColumnInterface } from "../types";
import "./columnreorder.scss";
import {
  arrayMoveItem,
  getHiddenColumnsCount,
  toggleColumnVisibility,
} from "./utils";

export interface ColumnReorderInterface<
  COLUMN_KEY extends string,
  COLUMN_NAME extends string,
> {
  columns: ColumnInterface<COLUMN_KEY, COLUMN_NAME>[];
  onClose: () => void;
  onReorder: (columns: ColumnInterface<COLUMN_KEY, COLUMN_NAME>[]) => void; // returns the re-ordered columns
  columnNameTransform?: (columnName: COLUMN_NAME) => string; // can be used for translations
}

const ColumnReorder = <COLUMN_KEY extends string, COLUMN_NAME extends string>({
  columns,
  onClose,
  columnNameTransform,
  onReorder,
}: ColumnReorderInterface<COLUMN_KEY, COLUMN_NAME>) => {
  const classes = classNames("columnreorder", {});

  const [newColumns, setNewColumns] =
    useState<ColumnInterface<COLUMN_KEY, COLUMN_NAME>[]>(columns);

  const handleDrop = useCallback(
    (originalIndex: number, newIndex: number) => {
      setNewColumns(arrayMoveItem(newColumns, originalIndex, newIndex));
    },
    [newColumns],
  );

  const handleShowHideColumn = useCallback(
    (column: ColumnInterface<COLUMN_KEY, COLUMN_NAME>) => {
      setNewColumns(toggleColumnVisibility(newColumns, column));
    },
    [newColumns],
  );

  const handleConfirmReorder = useCallback(() => {
    onReorder(newColumns);
    onClose();
  }, [newColumns, onReorder, onClose]);

  return (
    <Dialog
      name="column-reorder-dlg"
      header="Column settings"
      onClose={onClose}
      dataTestId="column-reorder-dlg"
    >
      <Stack isVertical align="stretch" gutterSize="large">
        <div>Drag to reorder</div>
        <div className={classes}>
          <Stack isVertical align="stretch">
            <DraggableList onDrop={handleDrop}>
              {newColumns.map((column) => (
                <Card
                  key={column.key}
                  isFullWidth
                  spacing="normal"
                  color="alt2"
                  radius="small"
                >
                  <Stack align="center" justify="space-between">
                    <Stack align="center">
                      <Icon kind="drag" color="grey" />
                      <div
                        className={
                          column.isHidden ? "f_disabled" : "f_secondary"
                        }
                      >
                        {columnNameTransform
                          ? columnNameTransform(column.name)
                          : column.name}
                      </div>
                    </Stack>
                    <IconButton
                      tooltipText="Show/Hide column"
                      icon={column.isHidden ? "visibility_off" : "visibility"}
                      onButtonClick={() => handleShowHideColumn(column)}
                      isCircle
                      isDisabled={
                        !column.isHidden &&
                        getHiddenColumnsCount(newColumns) ===
                          newColumns.length - 1
                      }
                      type="text"
                      size="big"
                      spacing="small"
                      dataTestId={"show-hide-column-" + column.key}
                    />
                  </Stack>
                </Card>
              ))}
            </DraggableList>
          </Stack>
        </div>
        <Grid gutterSize="large">
          <GridItem span={6}>
            <Button
              name="cancel-column-reorder-btn"
              isFullWidth
              color={"grey"}
              text={"Cancel"}
              onButtonClick={onClose}
              dataTestId="column-reorder-dlg-cancel-btn"
            />
          </GridItem>
          <GridItem span={6}>
            <Button
              name="apply-column-reorder-btn"
              isFullWidth
              text={"Apply Columns"}
              onButtonClick={handleConfirmReorder}
              dataTestId="column-reorder-dlg-apply-btn"
            />
          </GridItem>
        </Grid>
      </Stack>
    </Dialog>
  );
};

export default ColumnReorder;
