import { axiosCsisApi } from "@csis.com/tip/src/App";
import { TicketCreateBody } from "../../../../api/openapi/data-contracts";

export async function postTicketApi(newTicket: TicketCreateBody) {
  return axiosCsisApi.createTicketApi20TicketPost(newTicket);
}

export async function postTicketAttachmentApi(files: File[]) {
  return axiosCsisApi.uploadAttachmentApi20TicketAttachmentPost({
    files: files,
  });
}
