import classNames from "classnames";
import React from "react";

export interface CheckBoxInterface {
  title?: string | React.ReactNode;
  name: string;
  isChecked?: boolean;
  // a "middle" state to indicate that is checked/unchecked. This can only be set programmatically and cannot be set by the user
  // to be only used for a checkbox that aggregates more checkboxes, for example a "check all"/"uncheck all" checkbox
  // that will indicate that not all checkboxes are checked/unchecked
  isMiddleState?: boolean;
  isDisabled?: boolean;
  tabIndex?: number;
  clickableArea?: "small" | "normal" | "big" | "large";
  dataTestId?: string;
  onClick?: () => void;
}

const CheckBox = ({
  title,
  name,
  isChecked = false,
  isMiddleState,
  isDisabled = false,
  onClick,
  dataTestId,
  tabIndex = 0,
  clickableArea,
}: CheckBoxInterface) => {
  // prefer onClick events instead of onChange
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (onClick) {
        onClick();
      }
    },
    [onClick],
  );

  // when the checkbox is focused we can check/uncheck it with space
  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (event.key === " " && onClick) {
        onClick();
      }
    },
    [onClick],
  );

  const classes = classNames("checkbox", {
    "checkbox--disabled": isDisabled,
    "checkbox--middle": isMiddleState && !isChecked && !isDisabled,
    "checkbox--clickable-area-small": clickableArea === "small",
    "checkbox--clickable-area-normal": clickableArea === "normal",
    "checkbox--clickable-area-big": clickableArea === "big",
    "checkbox--clickable-area-large": clickableArea === "large",
  });

  return (
    <label
      tabIndex={tabIndex}
      className={classes}
      onClick={(e) => e.stopPropagation()}
      onKeyPress={handleKeyPress}
      htmlFor={name}
      data-test-id={dataTestId}
    >
      <input
        onChange={() => {}}
        onClick={handleClick}
        checked={isChecked}
        id={name}
        disabled={isDisabled}
        type="checkbox"
        data-test-id={dataTestId + "-input"}
      />
      <div className="checkbox__checkmark">
        <div className="checkbox__minusmark" />
      </div>

      {title && <div className="checkbox__label">{title}</div>}
    </label>
  );
};

export default React.memo(CheckBox);
