import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserPreferencesForGeneral } from "../userPreferences/selectors";
import { changeHighContrast, changeTheme } from "../userPreferences/slice";

const useThemePreference = () => {
  const dispatch = useDispatch();
  const { themePreference, prefersHighContrast } =
    useSelector(getUserPreferencesForGeneral) || {};
  const [prefersDarkTheme, setPrefersDarkTheme] = useState<boolean | null>(
    null,
  );
  const isSystemThemeDark = window.matchMedia(
    "(prefers-color-scheme: dark)",
  ).matches;

  useEffect(() => {
    if (themePreference === "dark") setPrefersDarkTheme(true);
    else if (themePreference === "light") setPrefersDarkTheme(false);
    else if (themePreference === "system")
      setPrefersDarkTheme(isSystemThemeDark);
  }, [themePreference, isSystemThemeDark]);

  const handleToggleTheme = useCallback(() => {
    if (prefersDarkTheme !== null) {
      dispatch(changeTheme(prefersDarkTheme ? "light" : "dark"));
    } else {
      dispatch(changeTheme("light"));
    }
  }, [prefersDarkTheme, dispatch]);

  const handleToggleSystemTheme = useCallback(() => {
    dispatch(changeTheme(themePreference === "system" ? "light" : "system"));
  }, [themePreference, dispatch]);

  const handleToggleHighContrast = useCallback(() => {
    dispatch(changeHighContrast(!prefersHighContrast));
  }, [prefersHighContrast, dispatch]);

  return {
    themePreference,
    prefersHighContrast,
    handleToggleTheme,
    handleToggleSystemTheme,
    handleToggleHighContrast,
  };
};

export default useThemePreference;
