/* eslint-disable sonarjs/no-duplicate-string */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  AuditlogEventPageDetailResponse,
  AuditlogEventRead,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { PredefinedResultsPerPage } from "@csis.com/tip/src/models/pagination/constants";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { handleRequestError } from "../../../../../api/utils";
import { getSelectedOrgId } from "../../../../Profile/Security/selectors";
import { AuditLogColumns, columns } from "./Table/columns";
import { exportAuditLogCsvApi, fetchAuditLogApi } from "./api/api";
import { QueryParams } from "./types";

interface StateSlice {
  auditLog: AuditlogEventRead[] | null;
  hasNextPage: boolean;
  isPending: boolean;
  fetchError: string | null;
  columns: AuditLogColumns;
  isCsvExportPending: boolean;
}
const initialState: StateSlice = {
  auditLog: null,
  hasNextPage: false,
  isPending: false,
  fetchError: null,
  columns: columns,
  isCsvExportPending: false,
};

const auditLogSlice = createSlice({
  name: "auditLog",
  initialState: initialState,
  reducers: {
    fetchAuditLog(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setAuditLogPending(state) {
      state.isPending = true;
      state.fetchError = null;
      state.auditLog = [];
    },
    setFetchAuditLogError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
      state.auditLog = [];
    },
    fetchAuditLogSuccess(state, action: PayloadAction<AuditlogEventRead[]>) {
      state.isPending = false;
      state.fetchError = null;
      state.auditLog = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    reorderColumns(state, action: PayloadAction<AuditLogColumns>) {
      state.columns = action.payload;
    },
    setResultsPerPageUserPreference(
      _state,
      _action: PayloadAction<PredefinedResultsPerPage>,
    ) {
      //empty handled by saga
    },

    exportAuditLogCsv(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setCsvExportPending(state) {
      state.isCsvExportPending = true;
    },
    setCsvExportComplete(state) {
      state.isCsvExportPending = false;
    },
  },
});

export default auditLogSlice.reducer;

export const {
  fetchAuditLog,
  setAuditLogPending,
  setFetchAuditLogError,
  fetchAuditLogSuccess,
  setHasNextPage,
  reorderColumns,
  setResultsPerPageUserPreference,
  exportAuditLogCsv,
  setCsvExportPending,
  setCsvExportComplete,
} = auditLogSlice.actions;

function* fetchAuditLogSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setAuditLogPending());

  const organizationId: string = yield select(getSelectedOrgId) || "";

  const params = {
    queryParams: action.payload,
    organizationId,
  };

  try {
    const response: AxiosResponse<AuditlogEventPageDetailResponse> = yield call(
      fetchAuditLogApi,
      params,
    );

    yield put(fetchAuditLogSuccess(response.data.payload.page));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    yield put(setHasNextPage(false));
    const errorMessage = handleRequestError(e);
    yield put(setFetchAuditLogError(errorMessage));
  }
}

function* exportAuditLogCsvSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setCsvExportPending());

  try {
    const response: AxiosResponse<Blob> = yield call(
      exportAuditLogCsvApi,
      action.payload,
    );

    const blob = response.data;
    downloadBlobForUser(blob, "auditLog_csv.csv");

    yield put(setCsvExportComplete());
  } catch (e) {
    yield put(setCsvExportComplete());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchAuditLog.toString(), fetchAuditLogSaga);
  yield takeLatest(exportAuditLogCsv.toString(), exportAuditLogCsvSaga);
}

export function* auditLogSagas() {
  yield all([actionWatcher()]);
}
