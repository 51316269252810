import { RetainerAttachment } from "./types";

export function getRetainerAttachments(
  files: Array<RetainerAttachment> | undefined,
) {
  if (!files) return [];
  return files.map((item: RetainerAttachment) => ({
    filename: item.filename,
    id: item.external_id,
    size: item.content_length,
  }));
}
