import classNames from "classnames";
import { FC, KeyboardEvent, MouseEvent, ReactNode, useCallback } from "react";
import { Icon } from "../../icons";
import { Stack } from "../../layout";

export interface ListMenuItemInterface {
  children: ReactNode;
  isSelected?: boolean;
  isDisabled?: boolean;
  tooltipText?: string;
  isIdented?: boolean; // make the item have some extra padding in the left
  // if exists it makes the whole item "Selectable", meaning hover works, is tabbable etc
  isSemibold?: boolean;
  onClick?: (e?: MouseEvent) => void;
  dataTestId?: string;
}

const ListMenuItem: FC<ListMenuItemInterface> = ({
  children,
  isSelected,
  isIdented,
  tooltipText,
  isDisabled,
  isSemibold = false,
  onClick,
  dataTestId,
}) => {
  const isSelectable = Boolean(onClick) && !isDisabled;

  const classes = classNames("listmenu__item", {
    "listmenu__item--selected": isSelected,
    "listmenu__item--selectable": isSelectable,
    "listmenu__item--idented": isIdented,
    "listmenu__item--semibold": isSemibold,
    "listmenu__item--disabled": isDisabled,
  });

  // when the dropdown is focused we can open/close it with space
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter" && onClick) {
        event.stopPropagation();
        event.preventDefault();
        onClick();
      }
    },
    [onClick],
  );

  return (
    <div
      onClick={isDisabled ? () => null : onClick}
      role="option"
      aria-selected={isSelected}
      className={classes}
      data-test-id={dataTestId}
      tabIndex={isSelectable ? 0 : -1}
      onKeyPress={handleKeyPress}
      title={tooltipText}
    >
      <Stack>
        {children}
        {tooltipText && (
          <Icon
            color="grey"
            kind="help_filled"
            size="small"
            tooltipText={tooltipText}
          />
        )}
      </Stack>
    </div>
  );
};

export default ListMenuItem;
