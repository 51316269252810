import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getIsCreateTicketPending = (state: RootState) =>
  state.newTicketReducer.isPending;

export const getCreateTicketPostError = (state: RootState) =>
  state.newTicketReducer.postError;

export const getCreateTicketPostSuccess = (state: RootState) =>
  state.newTicketReducer.postSuccess;

export const getCreateTicketResult = createSelector(
  [
    getIsCreateTicketPending,
    getCreateTicketPostError,
    getCreateTicketPostSuccess,
  ],
  (isPending, postError, postSuccess) => {
    return { isPending, postError, postSuccess };
  },
);

export const getUploadedAttachments = (state: RootState) =>
  state.newTicketReducer.uploadedAttachments;

export const getUploadedAttachmentsResult = (state: RootState) => {
  return {
    uploadedAttachments: state.newTicketReducer.uploadedAttachments,
    isAttachmentUploadPending: state.newTicketReducer.isAttachmentUploadPending,
    attachmentUploadError: state.newTicketReducer.attachmentUploadError,
  };
};
