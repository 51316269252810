import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PieChart } from "@csis.com/components";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import { ticketsKeys } from "../../TicketsSearch/constants";
import {
  SeverityChartColors,
  SeverityTranslationKeys,
  isSeverity,
} from "../../models/Severity";
import {
  getPieLabelGenerator,
  transformTotalDataToPiechartData,
} from "../transformers";
import { getSeveritiesDistributionResult } from "./selectors";

export const SeverityPieChart = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslations();
  const history = useHistory();

  const {
    severitiesDistributions,
    isSeveritiesDistributionPending,
    severitiesDistributionError,
  } = useSelector(getSeveritiesDistributionResult);

  const severitiesDistributionsAsPieChart = transformTotalDataToPiechartData(
    severitiesDistributions,
    getPieLabelGenerator(t, SeverityTranslationKeys),
  );

  function handlePieChartClick(chartData: unknown) {
    // @ts-ignore
    const severity = chartData?.key;

    if (isSeverity(severity)) {
      const ticketsSeveritySelectedUrl = getNewLocationUrl(
        urls.tickets_search,
        {
          [ticketsKeys.SEVERITY]: [severity],
          [ticketsKeys.START_DATE]: startDate,
          [ticketsKeys.END_DATE]: endDate,
        },
      );

      history.push(ticketsSeveritySelectedUrl);
    }
  }

  return (
    <PieChart
      dataKey="amount"
      hasLegend={false}
      data={severitiesDistributionsAsPieChart}
      isLoading={isSeveritiesDistributionPending}
      error={severitiesDistributionError}
      onClick={handlePieChartClick}
      colorKeys={SeverityChartColors}
    />
  );
};
