import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { fetchIsValidFormApi, postFormByIdApi } from "./api/api";
import { MdfeForm } from "./types";

interface StateSlice {
  isValidFormId: boolean;
  isFetchValidFormIdPending: boolean;

  postFormSuccess: boolean;
  isPostFormPending: boolean;
  postFormError: string | null;
}
const initialState: StateSlice = {
  isValidFormId: true,
  isFetchValidFormIdPending: false,

  postFormSuccess: false,
  isPostFormPending: false,
  postFormError: null,
};

const mdfeOnboardingSlice = createSlice({
  name: "mdfeOnboarding",
  initialState: initialState,
  reducers: {
    fetchIsValidFormById(_state, _action: PayloadAction<{ id: string }>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isFetchValidFormIdPending = true;
      state.isValidFormId = true;
    },
    setFetchError(state) {
      state.isFetchValidFormIdPending = false;
      state.isValidFormId = false;
    },
    fetchSuccess(state) {
      state.isFetchValidFormIdPending = false;
      state.isValidFormId = true;
    },

    postForm(
      _state,
      _action: PayloadAction<{ newForm: MdfeForm; id: string }>,
    ) {
      //empty handled by saga
    },
    setPostFormPending(state) {
      state.isPostFormPending = true;
      state.postFormError = null;
      state.postFormSuccess = false;
    },
    setPostFormError(state, action: PayloadAction<string>) {
      state.isPostFormPending = false;
      state.postFormSuccess = false;
      state.postFormError = action.payload;
    },
    setPostFormSuccess(state) {
      state.isPostFormPending = false;
      state.postFormSuccess = true;
      state.postFormError = null;
    },
  },
});

export default mdfeOnboardingSlice.reducer;

export const {
  fetchIsValidFormById,
  setPending,
  setFetchError,
  fetchSuccess,

  postForm,
  setPostFormPending,
  setPostFormError,
  setPostFormSuccess,
} = mdfeOnboardingSlice.actions;

// Async stuff - sagas

function* fetchIsValidFormByIdSaga(action: PayloadAction<{ id: string }>) {
  yield put(setPending());
  try {
    yield call(fetchIsValidFormApi, action.payload.id);

    yield put(fetchSuccess());
  } catch (e) {
    yield put(setFetchError());
  }
}

function* postFormSaga(
  action: PayloadAction<{ newForm: MdfeForm; id: string }>,
) {
  yield put(setPostFormPending());
  const newForm = action.payload.newForm;
  const id = action.payload.id;

  try {
    yield call(postFormByIdApi, newForm, id);

    yield put(setPostFormSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setPostFormError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchIsValidFormById.toString(), fetchIsValidFormByIdSaga);
  yield takeLatest(postForm.toString(), postFormSaga);
}

export function* mdfeOnboardingSagas() {
  yield all([actionWatcher()]);
}
