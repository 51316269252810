import classNames from "classnames";
import { useCallback } from "react";
import { isNullOrUndefined } from "../../utils/utils";

export interface TabInterface {
  text: string;
  selectedTab?: number;
  index?: number;
  onTabClick?: (tab: number) => void;
}

const Tab: React.FC<TabInterface> = ({
  text,
  index,
  selectedTab,
  onTabClick,
}) => {
  const classes = classNames("tabs__tab", {
    "tabs__tab--selected": selectedTab === index,
  });
  const handleTabClick = () => {
    if (onTabClick && !isNullOrUndefined(index)) {
      onTabClick(index);
    }
  };

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && onTabClick && !isNullOrUndefined(index)) {
        event.stopPropagation();
        event.preventDefault();
        onTabClick(index);
      }
    },
    [index, onTabClick],
  );

  return (
    <div
      onClick={handleTabClick}
      className={classes}
      role="tab"
      id={text}
      tabIndex={0}
      aria-selected={selectedTab === index}
      onKeyPress={handleKeyPress}
      title={text}
    >
      {text}
    </div>
  );
};

export default Tab;
