import { axiosCsisApi } from "@csis.com/tip/src/App";
import { UpdateClientBody } from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchApiClientsApi() {
  return axiosCsisApi.getClientsApi10OrganizationClientGet();
}

export async function postApiClientsApi(data: UpdateClientBody) {
  return axiosCsisApi.createClientApi10OrganizationClientPost(data);
}

export async function updateApiClientsApi(id: string, data: UpdateClientBody) {
  return axiosCsisApi.updateClientApi10OrganizationClientExternalIdPut(
    id,
    data,
  );
}

export async function deleteApiClientsApi(id: string) {
  return axiosCsisApi.deleteClientApi10OrganizationClientExternalIdDelete(id);
}
