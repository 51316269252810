import { axiosCsisApi } from "../../App";
import { EmergencyCaseQuestionaryUpdateBody } from "./types";

export async function getEmergencyCaseInfoAPI(caseId: string, code: string) {
  return axiosCsisApi.getEmergencyResponseCaseInfoApi10EmergencyResponseCaseErCaseExternalIdGet(
    {
      erCaseExternalId: caseId,
      submission_code: code,
    },
  );
}

export async function setEmergencyCaseQuestionaryDataAPI(
  caseId: string,
  code: string,
  data: EmergencyCaseQuestionaryUpdateBody,
  csrfToken: string,
) {
  return axiosCsisApi.setErCaseQuestionnaireDataApi10EmergencyResponseCaseErCaseExternalIdQuestionnairePatch(
    {
      erCaseExternalId: caseId,
      submission_code: code,
    },
    data,
    { headers: { "X-CSRF-Token": csrfToken } },
  );
}

export async function fetchCsrfTokenApi() {
  return axiosCsisApi.csrfTokenRouteApi10CsrfTokenGet();
}

export async function getEmergencyCaseAttachmentsAPI(
  caseId: string,
  submissionCode: string,
  csrfToken: string,
) {
  return axiosCsisApi.retrieveAllErCaseAttachmentsWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentGet(
    {
      erCaseExternalId: caseId,
      submission_code: submissionCode,
    },
    {
      headers: { "X-CSRF-Token": csrfToken },
    },
  );
}

export async function updateEmergencyCaseAttachmentsAPI(
  caseId: string,
  submissionCode: string,
  formData: File[],
) {
  return axiosCsisApi.uploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPost(
    {
      erCaseExternalId: caseId,
      submission_code: submissionCode,
    },
    {
      files: formData,
    },
  );
}

export async function deleteEmergencyCaseAttachmentByIdAPI(
  caseId: string,
  attachmentId: string,
  submissionCode: string,
  csrfToken: string,
) {
  return axiosCsisApi.deleteErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdDelete(
    {
      erCaseExternalId: caseId,
      erCaseAttachmentExternalId: attachmentId,
      submission_code: submissionCode,
    },
    { headers: { "X-CSRF-Token": csrfToken } },
  );
}

export async function downloadEmergencyCaseAttachmentAPI(
  caseId: string,
  attachmentId: string,
  submissionCode: string,
) {
  return axiosCsisApi.downloadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdGet(
    {
      erCaseExternalId: caseId,
      erCaseAttachmentExternalId: attachmentId,
      submission_code: submissionCode,
    },
  );
}
