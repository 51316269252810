import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getStatusDistribution = (state: RootState) =>
  state.ticketsStatisticsStatusReducer.data;

export const getIsStatusDistributionPending = (state: RootState) =>
  state.ticketsStatisticsStatusReducer.isDataPending;

export const getStatusDistributionFetchError = (state: RootState) =>
  state.ticketsStatisticsStatusReducer.dataFetchError;

export const getStatusDistributionResult = createSelector(
  [
    getStatusDistribution,
    getIsStatusDistributionPending,
    getStatusDistributionFetchError,
  ],
  (
    statusDistributions,
    isStatusDistributionPending,
    statusDistributionError,
  ) => {
    return {
      statusDistributions,
      isStatusDistributionPending,
      statusDistributionError,
    };
  },
);
