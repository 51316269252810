import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { Status } from "../../models/Status";
import { QueryParams } from "../types";
import { mapVictimsInfoStartAndEndDateToCorrectType } from "./utils";

export async function fetchVictimsInfoApi(queryParams: Partial<QueryParams>) {
  const params = mapVictimsInfoStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getVictimReportsApi20CompromisedDataCredentialReportGet(
    {
      ...params,
    },
    STRINGIFY_QUERY_PARAMS,
  );
}

export async function exportVictimsInfoCsvApi(
  queryParams: Partial<QueryParams>,
) {
  const params = mapVictimsInfoStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getVictimExportCsvApi20CompromisedDataCredentialReportExportCsvGet(
    {
      ...params,
    },
    {
      format: "blob",
      ...STRINGIFY_QUERY_PARAMS,
    },
  );
}

export async function patchBulkStatusApi({
  ids,
  status,
}: {
  ids: string[];
  status: Status;
}) {
  return axiosCsisApi.bulkEditVictimReportsApi20CompromisedDataCredentialReportBulkPatch(
    { external_ids: ids, status: status },
  );
}
