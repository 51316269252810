import { RetainerTypePreview } from "@csis.com/tip/src/api/openapi/data-contracts";

export function isRetainerType(x: any): x is RetainerTypePreview {
  return x.name !== undefined && x.display_name !== undefined;
}

export function getRetainerDisplayName(
  retainerTypeName: string,
  retainerTypes: RetainerTypePreview[],
) {
  const displayName = retainerTypes.find(
    (retainerType) => retainerType.name === retainerTypeName,
  )?.display_name;

  return displayName ? displayName : retainerTypeName;
}
