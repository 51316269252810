// use this info only for deciding the color of the flag tag
// the rest of the info we get from the api
import { Color } from "@csis.com/components/src/colors";
import { Guard, isArrayOf } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const FlagValues = {
  ESCALATED: "escalated",
  NEAR_MISS: "near-miss",
  GDPR_POTENTIAL: "gdpr-possible-incident",
  GDPR: "gdpr-incident",
  GDPR_RESOLVED: "gdpr-resolved",
  AV_SCAN_INITIATED: "av-scan-initiated",
  FILE_QUARANTINED: "file-quarantined",
  HOST_ISOLATED: "host-isolated",
  INDICATOR_ADDED: "indicator-added",
  PENTEST: "pentest",
  URL_BLOCKED: "url-blocked",
  USER_DISABLE: "user-disabled",
  WHITELIST_CANDIDATE: "whitelist-candidate",
} as const;

export type FlagName = (typeof FlagValues)[keyof typeof FlagValues];

export type Flag = {
  name: FlagName | string;
  display_name: string;
  user_addable: boolean;
};

export const isFlag = (x: any): x is Flag => {
  return (
    typeof x.name === "string" &&
    typeof x.display_name === "string" &&
    typeof x.user_addable === "boolean"
  );
};

export const isFlagName = (x: any): x is FlagName => {
  return Object.values(FlagValues).includes(x);
};

export const isArrayOfFlags: Guard<Flag[]> = isArrayOf(isFlag);

export const isArrayOfFlagNames: Guard<FlagName[]> = isArrayOf(isFlagName);

export const FlagColors: Record<FlagName, Color> = {
  [FlagValues.ESCALATED]: "red",
  [FlagValues.NEAR_MISS]: "orange",
  [FlagValues.GDPR_POTENTIAL]: "orange",
  [FlagValues.GDPR]: "red",
  [FlagValues.GDPR_RESOLVED]: "green",
  [FlagValues.AV_SCAN_INITIATED]: "orange",
  [FlagValues.FILE_QUARANTINED]: "orange",
  [FlagValues.HOST_ISOLATED]: "orange",
  [FlagValues.INDICATOR_ADDED]: "blue",
  [FlagValues.PENTEST]: "blue",
  [FlagValues.URL_BLOCKED]: "orange",
  [FlagValues.USER_DISABLE]: "orange",
  [FlagValues.WHITELIST_CANDIDATE]: "blue",
} as const;

// TODO: remove this at some point since we get the display names from the api /flag
export const FlagTranslationKeys: {
  [key in FlagName]: TranslationKey;
} = {
  [FlagValues.ESCALATED]: "escalated",
  [FlagValues.GDPR]: "gdpr_incident",
  [FlagValues.GDPR_POTENTIAL]: "gdpr_possible_incident",
  [FlagValues.GDPR_RESOLVED]: "gdpr_resolved",
  [FlagValues.NEAR_MISS]: "near_miss",
  [FlagValues.AV_SCAN_INITIATED]: "av-scan-initiated",
  [FlagValues.FILE_QUARANTINED]: "file-quarantined",
  [FlagValues.HOST_ISOLATED]: "host-isolated",
  [FlagValues.INDICATOR_ADDED]: "indicator-added",
  [FlagValues.PENTEST]: "pentest",
  [FlagValues.URL_BLOCKED]: "url-blocked",
  [FlagValues.USER_DISABLE]: "user-disabled",
  [FlagValues.WHITELIST_CANDIDATE]: "whitelist_candidate",
} as const;
