import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getNews = (state: RootState) => state.newsReducer.news;

export const getIsNewsPending = (state: RootState) =>
  state.newsReducer.isPending;

export const getNewsFetchError = (state: RootState) =>
  state.newsReducer.fetchError;

export const getNewsUpdateError = (state: RootState) =>
  state.newsReducer.updateError;

export const getHasNewsBeenDisplayed = (state: RootState) =>
  state.newsReducer.hasNewsBeenDisplayed;

export const getNewsResult = createSelector(
  [getNews, getIsNewsPending, getNewsFetchError, getNewsUpdateError],
  (news, isNewsPending, newsFetchError, newsUpdateError) => {
    return { news, isNewsPending, newsFetchError, newsUpdateError };
  },
);
