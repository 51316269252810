import React from "react";
import { FormLabel, Input, Stack } from "@csis.com/components";

// A Standard parametrized text input field with label

interface BasicTextInputProps {
  label: string;
  name: string;
  type?: "text" | "textarea" | "password" | "tel" | "email";
  placeholder?: string;
  value?: string;
  isOptional?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
  onChange: (value: string, name: string) => void;
  dataTestId?: string;
}

const BasicTextInput: React.FC<BasicTextInputProps> = ({
  label,
  name,
  type = "text",
  placeholder,
  isOptional,
  isDisabled,
  isReadonly,
  value,
  onChange,
  dataTestId,
}) => {
  return (
    <Stack isVertical>
      <FormLabel text={label} htmlFor={name} />
      <Input
        name={name}
        type={type}
        isOptional={isOptional}
        isDisabled={isDisabled}
        isReadonly={isReadonly}
        isFullWidth
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        dataTestId={dataTestId}
      />
    </Stack>
  );
};

export default BasicTextInput;
