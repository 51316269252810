import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, FormLabel, Stack } from "@csis.com/components";
import { emergencyCaseKeys } from "../EmergencyCaseSearch/constants";
import { getBusinessUnitsResult } from "./selectors";
import { fetchBusinessUnits, fetchBusinessUnitsWithRetainers } from "./slice";
import { BusinessUnit, BusinessUnitWithRetainer } from "./types";
import { getCustomerLabel } from "./utils";

export interface BusinessUnitSelectProps {
  businessUnitId?: string;
  withRetainers?: boolean;
  onBusinessUnitChange: (
    value: BusinessUnit | BusinessUnitWithRetainer,
  ) => void;
}

const BusinessUnitSelect = ({
  businessUnitId,
  withRetainers,
  onBusinessUnitChange,
}: BusinessUnitSelectProps) => {
  const dispatch = useDispatch();
  const { businessUnits, businessUnitsWithRetainer } = useSelector(
    getBusinessUnitsResult,
  );
  const filteredBusinessUnits = useMemo(
    () =>
      withRetainers
        ? businessUnitsWithRetainer
        : businessUnits.filter(
            (unit) =>
              !businessUnitsWithRetainer.some(
                (retainerUnit) => retainerUnit.id === unit.id,
              ),
          ),
    [businessUnits, businessUnitsWithRetainer, withRetainers],
  );
  const handleBusinessUnitChange = (value: string) => {
    const selectedBusinessUnit = filteredBusinessUnits.find(
      (unit) => unit.id === value,
    ) as BusinessUnit | BusinessUnitWithRetainer;
    onBusinessUnitChange(selectedBusinessUnit);
  };

  useEffect(() => {
    dispatch(fetchBusinessUnitsWithRetainers());
    dispatch(fetchBusinessUnits());
  }, [dispatch]);

  return (
    <Stack isVertical>
      <FormLabel
        text={
          withRetainers
            ? "Select Customer"
            : "Select existing non-retainer Customer"
        }
        htmlFor={emergencyCaseKeys.BUSINESS_UNIT_ID}
      />
      <Dropdown
        name={emergencyCaseKeys.BUSINESS_UNIT_ID}
        headerTitle={"Select Customer"}
        value={businessUnitId}
        isFullWidth
        options={filteredBusinessUnits.map((unit) => ({
          value: unit.id,
          label: withRetainers ? getCustomerLabel(unit) : unit.name,
        }))}
        type={"shadow"}
        onSelect={handleBusinessUnitChange}
        isUppercase={false}
        dataTestId="business-unit-dropdown"
      />
    </Stack>
  );
};

export default BusinessUnitSelect;
