import { MenuItems } from "@csis.com/tip/src/components/shared/Sidebar/types";
import urls from "../routes/urls";

const sidebarConfig: MenuItems = {
  products: {
    icon: "dashboard",
    onboardingId: "sidebar-dashboard",
    label: "dashboard",
    url: urls.products,
    children: {
      threat_insights: {
        permissionProductKey: "threat_insights",
        label: "threat_insights",
        url: urls.products_threatinsights,
      },
      phishing: {
        label: "anti-phishing",
        permissionProductKey: "phishing",
        url: urls.products_phishing_urls,
        children: {
          search: {
            label: "search",
            url: urls.products_phishing_urls,
          },
          phishing_statistics: {
            label: "statistics",
            url: urls.products_phishing_statistics,
          },
          phishing_usage: {
            label: "service_usage",
            url: urls.products_phishing_usage,
          },
        },
      },
      payment_cards: {
        label: "compromised_cards",
        permissionProductKey: "payment_cards",
        url: urls.products_compromiseddata_cc_search,
        children: {
          search: {
            label: "search",
            url: urls.products_compromiseddata_cc_search,
          },
          statistics: {
            label: "statistics",
            url: urls.products_compromiseddata_cc_statistics,
          },
        },
      },
      credentials: {
        label: "compromised_credentials",
        permissionProductKey: "credentials",
        url: urls.products_compromiseddata_victims_search,
        children: {
          search: {
            label: "search",
            url: urls.products_compromiseddata_victims_search,
          },
          statistics: {
            label: "statistics",
            url: urls.products_compromiseddata_victims_statistics,
          },
        },
      },
      investigations: {
        label: "investigations",
        url: urls.products_investigations_search,
        permissionProductKey: "investigations",
        children: {
          search: {
            label: "search",
            url: urls.products_investigations_search,
          },

          threat_cloud: {
            label: "threat_cloud",
            url: urls.products_investigations_threatcloud,
          },
        },
      },
      emergency_response: {
        label: "emergency_response",
        permissionProductKey: "retainers",
        url: urls.products_emergencyresponse_retainer,
        children: {
          retainer: {
            label: "retainer_information",
            url: urls.products_emergencyresponse_retainer,
          },
        },
      },
    },
  },

  tickets: {
    icon: "tickets",
    onboardingId: "sidebar-tickets",
    permissionProductKey: "tickets",
    label: "tickets",
    url: urls.tickets_search,
    children: {
      search: {
        label: "search",
        url: urls.tickets_search,
      },
      statistics: {
        label: "statistics",
        url: urls.tickets_statistics,
      },
    },
  },
  alerts: {
    icon: "alert_circle",
    onboardingId: "sidebar-alerts",
    permissionProductKey: "alerts",
    label: "alerts",
    url: urls.alerts_search,
    children: {
      search: {
        label: "search",
        url: urls.alerts_search,
      },
      statistics: {
        label: "statistics",
        url: urls.alerts_statistics,
      },
    },
  },
  reports: {
    onboardingId: "sidebar-reports",
    permissionProductKey: "reports",
    url: urls.reports,
    icon: "reports",
    label: "reports",
  },

  ir: {
    url: urls.ir_cases_search,
    icon: "malware",
    label: "ir_call_tracker",
    isAdminOnlyVisible: true,
    children: {
      emergency_cases: {
        url: urls.ir_cases_search,
        label: "cases",
      },
      retainers: {
        url: urls.ir_retainers_search,
        label: "retainers",
      },
    },
  },

  help_and_support: {
    url: urls.helpandsupport_apidocumentation,
    icon: "help_filled",
    label: "help_and_support",
    children: {
      api_documentation: {
        label: "api_documentation",
        url: urls.helpandsupport_apidocumentation,
      },
    },
  },

  administration: {
    url: urls.administration,
    icon: "settings",
    isAdminOnlyVisible: true,
    label: "administration",
  },

  organizations: {
    url: urls.organizations_customers_search,
    icon: "organization",
    isAdminOnlyVisible: true,
    label: "organizations",
  },
};

export default sidebarConfig;
