import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { TicketCreateBody } from "../../../api/openapi/data-contracts";
import { TicketAttachment } from "../Ticket/types";
import { ticketsKeys } from "../TicketsSearch/constants";
import { postTicketApi, postTicketAttachmentApi } from "./api/api";
import { getUploadedAttachments } from "./selector";

interface StateSlice {
  isPending: boolean;
  postError: string | null;
  postSuccess: boolean;

  uploadedAttachments: TicketAttachment[];
  isAttachmentUploadPending: boolean;
  attachmentUploadError: string | null;
  attachmentUploadSuccess: boolean;
}
const initialState: StateSlice = {
  isPending: false,
  postError: null,
  postSuccess: false,

  uploadedAttachments: [],
  isAttachmentUploadPending: false,
  attachmentUploadError: null,
  attachmentUploadSuccess: false,
};

const newTicketSlice = createSlice({
  name: "newTicket",
  initialState: initialState,
  reducers: {
    createTicket(
      _state,
      _action: PayloadAction<{ newTicket: TicketCreateBody }>,
    ) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.postError = null;
      state.postSuccess = false;
    },
    setPostError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.postError = action.payload;
    },
    setPostSuccess(state) {
      state.isPending = false;
      state.postSuccess = true;
    },
    resetState(state) {
      state.isPending = false;
      state.postError = null;
      state.postSuccess = false;

      state.uploadedAttachments = [];
      state.attachmentUploadSuccess = false;
      state.isAttachmentUploadPending = false;
      state.attachmentUploadError = null;
    },

    postAttachment(_state, _action: PayloadAction<File[]>) {
      //empty handled by saga
    },
    setIsAttachmentPending(state) {
      state.isAttachmentUploadPending = true;
      state.attachmentUploadSuccess = false;
      state.attachmentUploadError = null;
    },
    setAttachmentPostError(state, action: PayloadAction<string>) {
      state.isAttachmentUploadPending = false;
      state.attachmentUploadSuccess = false;
      state.attachmentUploadError = action.payload;
    },
    setAttachmentPostSuccess(state, action: PayloadAction<TicketAttachment[]>) {
      state.uploadedAttachments = [
        ...state.uploadedAttachments,
        ...action.payload,
      ];
      state.isAttachmentUploadPending = false;
      state.attachmentUploadSuccess = true;
      state.attachmentUploadError = null;
    },
    removeAttachmentById(state, action: PayloadAction<string>) {
      const id = action.payload;
      state.uploadedAttachments = state.uploadedAttachments.filter(
        (attachment) => attachment.id !== id,
      );
    },
    removeAllAttachments(state) {
      // used to clear the attachments after successful upload
      state.uploadedAttachments = [];
    },
  },
});

export default newTicketSlice.reducer;

export const {
  createTicket,
  setPending,
  setPostError,
  setPostSuccess,
  resetState,

  postAttachment,
  setIsAttachmentPending,
  setAttachmentPostError,
  setAttachmentPostSuccess,
  removeAttachmentById,
  removeAllAttachments,
} = newTicketSlice.actions;

// Async stuff - sagas

function* createTicketSaga(
  action: PayloadAction<{ newTicket: TicketCreateBody }>,
) {
  yield put(setPending());
  const newTicket = action.payload.newTicket;
  const attachments: TicketAttachment[] = yield select(getUploadedAttachments);

  newTicket[ticketsKeys.TICKET_ATTACHMENTS] = attachments.map((at) => {
    return at.id;
  });

  try {
    yield call(postTicketApi, action.payload.newTicket);

    yield put(setPostSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setPostError(errorMessage));
  }
}

function* postAttachmentSaga(action: PayloadAction<File[]>) {
  yield put(setIsAttachmentPending());

  try {
    const response: AxiosResponse<any> = yield call(
      postTicketAttachmentApi,
      action.payload,
    );
    yield put(setAttachmentPostSuccess(response.data?.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setAttachmentPostError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(createTicket.toString(), createTicketSaga);
  yield takeLatest(postAttachment.toString(), postAttachmentSaga);
}

export function* newTicketSagas() {
  yield all([actionWatcher()]);
}
