import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  SectorListResponse,
  SectorRead,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";

const sectorsSlice = createAsyncArrayFetchSlice<SectorRead, {}>("sectors");

export default sectorsSlice.reducer;

export const {
  fetchData: fetchSectors,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = sectorsSlice.actions;

// Async stuff - sagas

function* fetchSectorsSaga() {
  yield put(setIsPending());
  try {
    const response: AxiosResponse<SectorListResponse> = yield call(
      axiosCsisApi.getSectorsApi10InternalOrganizationSectorGet,
    );

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    yield put(setFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchSectors.toString(), fetchSectorsSaga);
}

export function* sectorsSagas() {
  yield all([actionWatcher()]);
}
