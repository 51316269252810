import axios from "axios";
import * as qs from "query-string";
import { AUTH_API_ENDPOINTS } from "@csis.com/tip/src/auth/apiEndpoints";

export async function requestBearerTokenApi({
  code,
  codeVerifier,
}: {
  code: string;
  codeVerifier: string;
}) {
  return await axios.post(
    process.env.REACT_APP_CSIS_AUTH_ENDPOINT + AUTH_API_ENDPOINTS.token,
    qs.stringify({
      client_id: process.env.REACT_APP_CSIS_CLIENT_ID,
      grant_type: "authorization_code",
      code: code,
      redirect_uri: process.env.REACT_APP_CSIS_REDIRECT_URI,
      code_verifier: codeVerifier,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  );
}
