import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  RoleDetailListResponse,
  RolePreview,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { fetchRolesApi } from "./api/api";

const rolesSlice = createAsyncArrayFetchSlice<RolePreview, undefined>("roles");

export default rolesSlice.reducer;

export const {
  fetchData: fetchRoles,
  setIsPending: setIsRolesFetchPending,
  setFetchError: setRolesFetchError,
  setFetchSuccess: setFetchRolesSuccess,
} = rolesSlice.actions;

// Async stuff - sagas

function* fetchRolesSaga() {
  yield put(setIsRolesFetchPending());

  try {
    const response: AxiosResponse<RoleDetailListResponse> =
      yield call(fetchRolesApi);
    yield put(setFetchRolesSuccess(response.data.payload));
  } catch (e) {
    yield put(setRolesFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchRoles.toString(), fetchRolesSaga);
}

export function* rolesSagas() {
  yield all([actionWatcher()]);
}
