// TO FIX once this is resolved https://github.com/facebook/create-react-app/issues/11770
// eslint-disable-next-line import/no-webpack-loader-syntax
import LogoWhite from "!file-loader!../../img/logo_white.svg";
import * as qs from "query-string";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  FormLabel,
  Form as GenericForm,
  GenericFormError,
  Icon,
  Input,
  Stack,
} from "@csis.com/components";
import { isString } from "@csis.com/tip/src/models/helpers";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import PasswordStrengthIndicator from "../Profile/Security/ChangePasswordDialog/PasswordStrengthIndicator/PasswordStrengthIndicator";
import { PASSWORD_REQUIREMENTS } from "../Profile/Security/ChangePasswordDialog/PasswordStrengthIndicator/constants";
import { checkPasswordStrength } from "../Profile/Security/ChangePasswordDialog/utils";
import { CsisFooter } from "./CsisFooter";
import {
  getIsPasswordResetPending,
  getIsPasswordResetSuccess,
  getPasswordResetError,
} from "./selectors";
import { postPasswordReset } from "./slice";

const Logo = () => (
  <div role="banner" className="passwordreset-page__logo">
    <img src={LogoWhite} alt="CSIS Security Group Logo" />
  </div>
);

const Title = () => {
  const { t } = useTranslations();
  return (
    <div className="passwordreset-page__title">
      <h1 className="f_light">{t("create_new_password_for_your")}</h1>
      <h1>{t("threat_intelligence_portal")}</h1>
    </div>
  );
};
export interface FormInterface {
  formDetails: FormDetails;
  handleInputChange: (value: string, name: string) => void;
  onPasswordReset: () => void;
}

const Form: React.FC<FormInterface> = ({
  formDetails,
  handleInputChange,
  onPasswordReset,
}) => {
  const [formError, setFormError] = useState<string | null>(null);

  const isPasswordResetSuccess = useSelector(getIsPasswordResetSuccess);
  const isPasswordResetPending = useSelector(getIsPasswordResetPending);
  const passwordResetError = useSelector(getPasswordResetError);
  const { t } = useTranslations();

  const checkForFormClientSideErrors = () => {
    let hasFormErrors = false;

    if (formDetails.password !== formDetails.password_repeat) {
      hasFormErrors = true;
      setFormError("The passwords do not match.");
    } else if (checkPasswordStrength(formDetails.password) < 75) {
      hasFormErrors = true;
      setFormError(PASSWORD_REQUIREMENTS);
    } else {
      setFormError(null);
    }
    return hasFormErrors;
  };

  const handleResetPassword = () => {
    if (!checkForFormClientSideErrors()) {
      onPasswordReset();
    }
  };

  return (
    <div className="passwordreset-page__form">
      <Card hasShadow={false} color="alt">
        {isPasswordResetSuccess ? (
          <Stack isVertical gutterSize="huge">
            <Stack align="center">
              <Icon kind="check" color="csis" />
              <div className="f_csis f_semibold">
                You have successfully updated your password!
              </div>
            </Stack>
            <a className="passwordreset-page__login-redirect" href="/">
              Go to Login
            </a>
          </Stack>
        ) : (
          <GenericForm
            id={"password-reset-form"}
            onFormSubmit={handleResetPassword}
          >
            <Stack isVertical gutterSize="large" align="stretch">
              <Stack isVertical>
                <FormLabel text={t("new_password")} htmlFor={"password"} />
                <Stack gutterSize="small" isVertical isExpanded>
                  <Input
                    dataTestId="password-input"
                    size="large"
                    name="password"
                    icon="lock"
                    placeholder={t("new_password")}
                    type="password"
                    value={formDetails.password}
                    isFullWidth
                    onChange={handleInputChange}
                    tabIndex={0}
                  />
                </Stack>
                <PasswordStrengthIndicator
                  strength={checkPasswordStrength(formDetails.password)}
                />
              </Stack>
              <Stack isVertical>
                <FormLabel
                  text={t("confirm_new_password")}
                  htmlFor={"password_repeat"}
                />
                <Stack gutterSize="small" isVertical isExpanded>
                  <Input
                    dataTestId="password-repeat-input"
                    size="large"
                    name="password_repeat"
                    icon="lock"
                    placeholder={t("confirm_new_password")}
                    type="password"
                    value={formDetails.password_repeat}
                    onChange={handleInputChange}
                    isFullWidth
                    tabIndex={0}
                  />
                  <PasswordStrengthIndicator
                    strength={checkPasswordStrength(
                      formDetails.password_repeat,
                    )}
                  />
                </Stack>
              </Stack>
              {formError && <GenericFormError errorText={formError} />}
              {passwordResetError && (
                <GenericFormError errorText={passwordResetError} />
              )}
              <Button
                formId={"password-reset-form"}
                dataTestId="submit-button"
                isLoading={isPasswordResetPending}
                onButtonClick={() => null}
                name="confirm-btn"
                text={t("confirm")}
              />
            </Stack>
          </GenericForm>
        )}
      </Card>
    </div>
  );
};

export interface FormDetails {
  password: string;
  password_repeat: string;
}

const PasswordReset = () => {
  const dispatch = useDispatch();
  const queryParams = qs.parse(window.location.search);

  const [formDetails, setFormDetails] = useState<FormDetails>({
    password: "",
    password_repeat: "",
  });

  const handleInputChange = useCallback((value: string, name: string) => {
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handlePasswordReset = useCallback(() => {
    if (queryParams?.token && isString(queryParams?.token)) {
      dispatch(
        postPasswordReset({
          newPassword: formDetails.password,
          resetPasswordToken: queryParams.token,
        }),
      );
    } else {
      // still dispatch and let the server respond with the error
      dispatch(
        postPasswordReset({
          newPassword: formDetails.password,
        }),
      );
    }
  }, [dispatch, formDetails, queryParams?.token]);

  return (
    <div className="passwordreset-page">
      <Stack isVertical isExpanded gutterSize="gigantic">
        <Logo />
        <Title />
        <Form
          formDetails={formDetails}
          handleInputChange={handleInputChange}
          onPasswordReset={handlePasswordReset}
        />
      </Stack>
      <CsisFooter />
    </div>
  );
};

export default PasswordReset;
