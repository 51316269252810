import classNames from "classnames";
import React from "react";
import {
  BLUE,
  CSIS,
  GREEN,
  GREY,
  GREYALT,
  ORANGE,
  RED,
  SECONDARY,
} from "../colors";
import { SIZE } from "../sizes";

export const IconKinds = [
  "account",
  "add",
  "alert_attached",
  "alert_circle",
  "alert",
  "android_logo",
  "asn",
  "attachment",
  "autoplay",
  "awaiting_csis_reply",
  "awaiting_your_reply",
  "bell",
  "bitcoin",
  "block",
  "category",
  "check",
  "close",
  "closed",
  "cloud_off",
  "cloud",
  "comments",
  "confirmed",
  "copy",
  "create_ticket",
  "cve",
  "dark_mode",
  "dashboard",
  "delete",
  "detail_view",
  "dns",
  "domain",
  "dot",
  "download",
  "down-arrow",
  "down-arrow-drop",
  "down-arrow-with-line",
  "drag",
  "edit",
  "focus",
  "fullscreen_exit",
  "fullscreen",
  "global_search",
  "globe",
  "grid_view",
  "hash",
  "help_filled",
  "hub",
  "info",
  "in_progress",
  "ip",
  "left-arrow",
  "light_mode",
  "linux_logo",
  "list_view",
  "lock_reset",
  "lock",
  "logout",
  "malware",
  "menu_collapsed",
  "menu_expanded",
  "more",
  "new",
  "no_lock",
  "north",
  "open_in_new",
  "organization",
  "paused",
  "pdf",
  "phone",
  "print",
  "questionmark",
  "refresh",
  "reports",
  "resolved",
  "right-arrow",
  "send",
  "settings",
  "shield",
  "sort",
  "source",
  "south",
  "star",
  "stats",
  "threat_actor",
  "star_outline",
  "threat_insights",
  "ticket_attached",
  "ticket_author",
  "tickets",
  "unfold_less",
  "unfold_more",
  "up-arrow",
  "up-arrow-drop",
  "up-and-down-arrow-drop",
  "up-arrow-with-line",
  "viewlist",
  "visibility_off",
  "visibility",
  "warning",
  "windows_logo",
  "world",
  "x509",
  "zoom_in",
  "zoom_out",
  "zoom_reset",
] as const;

export type IconKind = (typeof IconKinds)[number];

export interface IconInterface {
  kind?: IconKind;
  tooltipText?: string;
  size?: SIZE;
  isInline?: boolean;
  // try to not use the colors directly to the icon
  // prefer setting it from the parent especially when there
  // are interactive events like hover etc.
  // only use these in static icons (so not icon buttons, menu icons etc)
  color?:
    | CSIS
    | GREY
    | GREEN
    | RED
    | BLUE
    | SECONDARY
    | ORANGE
    | GREYALT
    | "transparent";
  dataTestId?: string;
}

const Icon: React.FC<IconInterface> = ({
  kind,
  size,
  color = "csis",
  tooltipText,
  isInline,
  dataTestId,
}) => {
  const classes = classNames("icon", {
    "icon--tiny": size === "tiny",
    "icon--small": size === "small",
    "icon--normal": size === "normal",
    "icon--big": size === "big",
    "icon--large": size === "large",
    "icon--huge": size === "huge",
    "icon--gigantic": size === "gigantic",
    "icon--green": color === "green",
    "icon--grey": color === "grey",
    "icon--grey-alt": color === "grey-alt",
    "icon--red": color === "red",
    "icon--blue": color === "blue",
    "icon--secondary": color === "secondary",
    "icon--transparent": color === "transparent",
    "icon--orange": color === "orange",
    "icon--inline": isInline,
  });
  const classesWithType = classes.concat(" icon--" + kind);
  return (
    <div
      title={tooltipText}
      className={classesWithType}
      data-test-id={dataTestId}
    />
  );
};

export default React.memo(Icon);
