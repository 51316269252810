/* eslint-disable sonarjs/no-duplicate-string */
import { OpenAPIV3 } from "openapi-types";
import { FC, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
  CodeBlock,
  Stack,
  Tabs,
} from "@csis.com/components";
import Tab from "@csis.com/components/src/atoms/Tabs/Tab";
import TabsBody from "@csis.com/components/src/atoms/Tabs/TabsBody";
import TabsHeader from "@csis.com/components/src/atoms/Tabs/TabsHeader";
import TabsPanel from "@csis.com/components/src/atoms/Tabs/TabsPanel";
import { isSchemaObject } from "./types";
import { transformRequestSchema } from "./utils";

// The following components are for the RequestBodyObject
export const RequestBodyExample: FC<{
  requestBody: OpenAPIV3.RequestBodyObject | undefined;
}> = ({ requestBody }) => {
  return (
    <Accordion headerGutterSize="space-between" headerColor="grey">
      <AccordionItem>
        <AccordionItemHeader>
          <span className="f_normal">Request Body</span>
        </AccordionItemHeader>
        <AccordionItemBody>
          <Stack isVertical align="stretch">
            <RequestBodyObject requestBody={requestBody} />
          </Stack>
        </AccordionItemBody>
      </AccordionItem>
    </Accordion>
  );
};

const MediaType: FC<{
  jsonRequestBody: OpenAPIV3.SchemaObject | undefined;
  multipartRequestBody: OpenAPIV3.SchemaObject | undefined;
}> = ({ jsonRequestBody, multipartRequestBody }) => {
  return (
    <span className="f_normal">
      <span className="f_bold"> Media Type: </span>
      {jsonRequestBody
        ? " application/json"
        : multipartRequestBody
          ? " multipart/form-data"
          : ""}
    </span>
  );
};

const RequestBodyObject: FC<{
  requestBody: OpenAPIV3.RequestBodyObject | undefined;
}> = ({ requestBody }) => {
  const jsonRequestBody = isSchemaObject(
    requestBody?.content?.["application/json"]?.schema,
  )
    ? (requestBody?.content?.["application/json"]
        ?.schema as OpenAPIV3.SchemaObject)
    : undefined;
  const multipartRequestBody = isSchemaObject(
    requestBody?.content?.["multipart/form-data"]?.schema,
  )
    ? (requestBody?.content?.["multipart/form-data"]
        ?.schema as OpenAPIV3.SchemaObject)
    : undefined;

  const requestBodyContent = jsonRequestBody || multipartRequestBody;

  const requestBodyProperties = isSchemaObject(requestBodyContent)
    ? requestBodyContent.properties
    : undefined;

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabClick = (tab: number) => {
    setSelectedTab(tab);
  };

  const transformedRequestBody = isSchemaObject(requestBodyContent)
    ? transformRequestSchema(requestBodyContent)
    : undefined;

  const codeBlockRequest = JSON.stringify(requestBodyProperties, null, 3);

  const exampleRequestFromSchema = JSON.stringify(
    transformedRequestBody,
    null,
    3,
  );

  return (
    <Stack isVertical align="stretch">
      <Tabs selectedTab={selectedTab} onSelectTab={handleTabClick}>
        <TabsHeader>
          <Tab text="Example" />
          <Tab text="Schema" />
        </TabsHeader>
        <TabsBody>
          <TabsPanel>
            {requestBodyContent && (
              <>
                <MediaType
                  jsonRequestBody={jsonRequestBody}
                  multipartRequestBody={multipartRequestBody}
                />
                <CodeBlock
                  isFullWidth
                  color="grey"
                  isCopyable
                  text={exampleRequestFromSchema}
                />
              </>
            )}
          </TabsPanel>
          <TabsPanel>
            <MediaType
              jsonRequestBody={jsonRequestBody}
              multipartRequestBody={multipartRequestBody}
            />
            <CodeBlock
              isFullWidth
              color="grey"
              isCopyable
              text={codeBlockRequest}
            />
          </TabsPanel>
        </TabsBody>
      </Tabs>
    </Stack>
  );
};
