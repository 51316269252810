import classNames from "classnames";
import React from "react";
import { Skeleton } from "../../..";
import { Icon } from "../../icons";
import { Stack } from "../../layout";
import { formatBigNumbers } from "../../utils/utils";
import {
  getColorBasedOnValues,
  getRelativeFontSize,
  getRelativeValue,
  getRotation,
} from "./utils";

export interface GaugeInterface {
  value?: number;
  maxValue?: number;
  background?: "app" | "card";
  fieldDescriptor?: "percentage" | "fraction";
  name: string;
  isLoading?: boolean;
  size?: "small" | "normal";
}

const Gauge: React.FC<GaugeInterface> = ({
  value = 0,
  maxValue = 100,
  background = "app",
  fieldDescriptor = "percentage",
  name,
  isLoading,
  size = "normal",
}) => {
  const gaugeClass = `gauge`;
  const sizeClass = `gauge--size-${size}`;
  const backgroundClass = `gauge--background-${background}`;
  const gaugeClasses = classNames(gaugeClass, sizeClass, backgroundClass);

  const progressClass = `gauge__progress`;
  const color = getColorBasedOnValues(value, maxValue);
  const colorClass = `gauge--color-${color}`;

  const progressClasses = classNames(progressClass, colorClass);

  const rotation = getRotation(value, maxValue);
  const relativeValue = formatBigNumbers(
    getRelativeValue(value, maxValue, fieldDescriptor),
  );
  const fontSize = getRelativeFontSize(size);
  const valueDescriptor =
    fieldDescriptor === "percentage" ? "%" : ` / ${formatBigNumbers(maxValue)}`;

  return (
    <div
      role="meter"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={maxValue}
      aria-label={name}
      className={gaugeClasses}
    >
      {isLoading ? (
        <Skeleton type="chart" />
      ) : (
        <>
          <div className="gauge__background" />
          <div className="gauge__negative" />
          <div className={progressClasses} style={rotation} />
          <div className="gauge__data">
            <div className={fontSize}>
              {relativeValue}
              {valueDescriptor}
            </div>
            {value > maxValue && (
              <Stack align="center" justify="center">
                <Icon kind="alert" color="red" isInline size="large" />
                <div className="f_red">Limit exceeded</div>
              </Stack>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Gauge;
