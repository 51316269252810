import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ListResponseCountryPreview } from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { CountryEntry } from "./Country";
import { fetchCountriesApi } from "./api/api";

const countriesSlice = createAsyncArrayFetchSlice<CountryEntry, {}>(
  "countries",
);

export default countriesSlice.reducer;

export const {
  fetchData: fetchCountries,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = countriesSlice.actions;

// Async stuff - sagas

function* fetchCountriesSaga() {
  yield put(setIsPending());
  try {
    const response: AxiosResponse<ListResponseCountryPreview> =
      yield call(fetchCountriesApi);

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    yield put(setFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchCountries.toString(), fetchCountriesSaga);
}

export function* phishingCountriesSagas() {
  yield all([actionWatcher()]);
}
