import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  PortalsFrontendApiInternalOrganizationModelsUserDetail,
  PortalsFrontendApiInternalOrganizationModelsUserListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { handleRequestError } from "../../../../../api/utils";
import { QueryParams } from "./types";

const usersSearchSlice = createAsyncArrayFetchSlice<
  PortalsFrontendApiInternalOrganizationModelsUserDetail,
  QueryParams
>("usersSearch");

export default usersSearchSlice.reducer;

export const {
  fetchData: fetchUsers,
  setIsPending: setIsUsersFetchPending,
  setFetchError: setUsersFetchError,
  setFetchSuccess: setFetchUsersSuccess,
} = usersSearchSlice.actions;

// Async stuff - sagas

function* fetchUsersSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setIsUsersFetchPending());

  try {
    const response: AxiosResponse<PortalsFrontendApiInternalOrganizationModelsUserListResponse> =
      yield call(
        axiosCsisApi.getUsersApi10InternalOrganizationUserGet,
        action.payload,
      );
    yield put(setFetchUsersSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setUsersFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchUsers.toString(), fetchUsersSaga);
}

export function* usersSearchSagas() {
  yield all([actionWatcher()]);
}
