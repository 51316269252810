import { OpenAPIV3 } from "openapi-types";
import { FC, useState } from "react";
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
  CodeBlock,
  Stack,
  Tabs,
} from "@csis.com/components";
import Tab from "@csis.com/components/src/atoms/Tabs/Tab";
import TabsBody from "@csis.com/components/src/atoms/Tabs/TabsBody";
import TabsHeader from "@csis.com/components/src/atoms/Tabs/TabsHeader";
import TabsPanel from "@csis.com/components/src/atoms/Tabs/TabsPanel";
import { isSchemaObject } from "./types";
import { transformResponseSchema } from "./utils";

export const ResponseCodeObject: FC<{
  response: OpenAPIV3.ResponseObject;
  code: String;
}> = ({ response, code }) => {
  return (
    <AccordionItem>
      <AccordionItemHeader>
        <span className="f_normal">
          Status code: {code} "{response.description}"
        </span>
      </AccordionItemHeader>
      <AccordionItemBody>
        <Stack isVertical align="stretch">
          {response.content ? (
            <ResponseContent content={response.content} />
          ) : (
            <span>No response content available.</span>
          )}
        </Stack>
      </AccordionItemBody>
    </AccordionItem>
  );
};

const MediaType: FC<{
  jsonContent?: OpenAPIV3.MediaTypeObject;
  multipartContent?: OpenAPIV3.MediaTypeObject;
}> = ({ jsonContent, multipartContent }) => {
  return (
    <span className="f_normal">
      <span className="f_bold"> Media Type: </span>
      {jsonContent
        ? " application/json"
        : multipartContent
          ? " multipart/form-data"
          : ""}
    </span>
  );
};

const ResponseContent: FC<{
  content: OpenAPIV3.MediaTypeObject;
}> = ({ content }) => {
  // Access the schema object within content
  const jsonContent = (content as Record<string, OpenAPIV3.MediaTypeObject>)[
    "application/json"
  ];

  const multipartContent = (
    content as Record<string, OpenAPIV3.MediaTypeObject>
  )["multipart/form-data"];

  const schema = jsonContent?.schema || multipartContent?.schema;

  const schemaProperties = isSchemaObject(schema)
    ? schema.properties
    : undefined;

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabClick = (tab: number) => {
    setSelectedTab(tab);
  };

  // Transform the schema if available
  const transformedSchema = isSchemaObject(schema)
    ? transformResponseSchema(schema)
    : {};

  if (!schemaProperties) {
    return <span>There isn't any response content.</span>;
  }

  const contentStringified = JSON.stringify(schemaProperties, null, 3);

  const exampleContentFromSchema = JSON.stringify(transformedSchema, null, 3);

  return (
    <Stack isVertical align="stretch">
      <Tabs selectedTab={selectedTab} onSelectTab={handleTabClick}>
        <TabsHeader>
          <Tab text="Example" />
          <Tab text="Schema" />
        </TabsHeader>

        <TabsBody>
          <TabsPanel>
            {content && (
              <>
                <MediaType
                  jsonContent={jsonContent}
                  multipartContent={multipartContent}
                />
                <CodeBlock
                  isFullWidth
                  color="grey"
                  isCopyable
                  text={exampleContentFromSchema}
                />
              </>
            )}
          </TabsPanel>
          <TabsPanel>
            <MediaType
              jsonContent={jsonContent}
              multipartContent={multipartContent}
            />
            <CodeBlock
              isFullWidth
              color="grey"
              isCopyable
              text={contentStringified}
            />
          </TabsPanel>
        </TabsBody>
      </Tabs>
    </Stack>
  );
};
