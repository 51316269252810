import { IconKind } from "@csis.com/components/src/icons/Icon";
import { Guard, isArrayOf } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const StatusValues = {
  NEW: "new",
  IN_PROGRESS: "in-progress",
  PENDING_CUSTOMER: "pending-customer",
  PENDING_CSIS: "pending-csis",
  CONFIRMED: "confirmed",
  CLOSED: "closed",
} as const;

export type Status = (typeof StatusValues)[keyof typeof StatusValues];

export const isStatus = (x: any): x is Status =>
  Object.values(StatusValues).includes(x);

export const isArrayOfStatuses: Guard<Status[]> = isArrayOf(isStatus);

// this is the part we localize
export const StatusTranslationKeys: { [key in Status]: TranslationKey } = {
  [StatusValues.NEW]: "new",
  [StatusValues.IN_PROGRESS]: "in_progress",
  [StatusValues.PENDING_CSIS]: "pending_csis",
  [StatusValues.PENDING_CUSTOMER]: "pending_customer",
  [StatusValues.CONFIRMED]: "confirmed",
  [StatusValues.CLOSED]: "closed",
} as const;

export const StatusIcons: { [key in Status]: IconKind } = {
  [StatusValues.NEW]: "new",
  [StatusValues.IN_PROGRESS]: "in_progress",
  [StatusValues.PENDING_CSIS]: "awaiting_csis_reply",
  [StatusValues.PENDING_CUSTOMER]: "awaiting_your_reply",
  [StatusValues.CONFIRMED]: "confirmed",
  [StatusValues.CLOSED]: "closed",
} as const;
