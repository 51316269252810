import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { RetainerCreateBody } from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { createRetainerAPI } from "../Retainer/api/api";
import { RetainersColumns, columns } from "./Table/columns";
import { fetchRetainersApi } from "./api/api";
import { RetainersResponse } from "./api/types";
import { QueryParams, RetainerPreview } from "./types";

interface StateSlice {
  retainers: RetainerPreview[] | null;
  hasNextPage: boolean;
  isRetainersPending: boolean;
  retainersFetchError: string | null;
  columns: RetainersColumns;

  isRetainerCreating: boolean;
  retainerCreateError: string | null;
  retainerCreateSuccess: boolean;
}

const initialState: StateSlice = {
  retainers: null,
  hasNextPage: false,
  isRetainersPending: false,
  retainersFetchError: null,
  columns: columns,

  isRetainerCreating: false,
  retainerCreateError: null,
  retainerCreateSuccess: false,
};

const retainersSlice = createSlice({
  name: "retainers",
  initialState: initialState,
  reducers: {
    fetchRetainers(_state, _action: PayloadAction<Partial<QueryParams>>) {
      //empty handled by saga
    },
    setRetainersPending(state) {
      state.isRetainersPending = true;
      state.retainersFetchError = null;
      state.retainers = [];
    },
    setFetchRetainersError(state, action: PayloadAction<string>) {
      state.isRetainersPending = false;
      state.retainersFetchError = action.payload;
      state.retainers = [];
    },
    fetchRetainersSuccess(state, action: PayloadAction<RetainerPreview[]>) {
      state.isRetainersPending = false;
      state.retainersFetchError = null;
      state.retainers = action.payload;
    },
    reorderColumns(state, action: PayloadAction<RetainersColumns>) {
      state.columns = action.payload;
    },
    setHasNextPage(state, action: PayloadAction<boolean>) {
      state.hasNextPage = action.payload;
    },
    createRetainer(_state, _action: PayloadAction<RetainerCreateBody>) {
      //empty handled by saga
    },
    setCreateRetainerPending(state) {
      state.isRetainerCreating = true;
      state.retainerCreateError = null;
      state.retainerCreateSuccess = false;
    },
    setCreateRetainerError(state, action: PayloadAction<string>) {
      state.isRetainerCreating = false;
      state.retainerCreateError = action.payload;
      state.retainerCreateSuccess = false;
    },
    setCreateRetainerSuccess(state) {
      state.isRetainerCreating = false;
      state.retainerCreateError = null;
      state.retainerCreateSuccess = true;
    },
    resetCreateRetainerState(state) {
      state.isRetainerCreating = false;
      state.retainerCreateError = null;
      state.retainerCreateSuccess = false;
    },
  },
});

export default retainersSlice.reducer;

export const {
  fetchRetainers,
  setRetainersPending,
  setFetchRetainersError,
  fetchRetainersSuccess,
  reorderColumns,
  setHasNextPage,
  createRetainer,
  setCreateRetainerPending,
  setCreateRetainerError,
  setCreateRetainerSuccess,
  resetCreateRetainerState,
} = retainersSlice.actions;

function* fetchRetainersSaga(action: PayloadAction<Partial<QueryParams>>) {
  yield put(setRetainersPending());
  try {
    const response: AxiosResponse<RetainersResponse> = yield call(
      fetchRetainersApi,
      action.payload,
    );

    yield put(fetchRetainersSuccess(response.data.payload.page));
    yield put(setHasNextPage(response.data.payload.has_next));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchRetainersError(errorMessage));
  }
}

function* createRetainerSaga(action: PayloadAction<RetainerCreateBody>) {
  yield put(setCreateRetainerPending());
  try {
    yield call(createRetainerAPI, action.payload);
    yield put(setCreateRetainerSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setCreateRetainerError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchRetainers.toString(), fetchRetainersSaga);
  yield takeLatest(createRetainer.toString(), createRetainerSaga);
}

export function* retainersSagas() {
  yield all([actionWatcher()]);
}
