import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@csis.com/components";
import BasicDialogButtons from "../../../../components/Form/BasicDialogButtons/BasicDialogButtons";
import { StatusValues } from "../../models/Status";
import { getEmergencyCaseUpdateResult } from "../selectors";
import { resetEmergencyCaseUpdateState, updateEmergencyCase } from "../slice";

const AcceptEmergencyCaseDialog = ({
  onClose,
  onSuccess,
  caseId,
}: {
  onClose: () => void;
  onSuccess: () => void;
  caseId: string;
}) => {
  const dispatch = useDispatch();
  const { emergencyCaseUpdateSuccess, emergencyCaseUpdatePending } =
    useSelector(getEmergencyCaseUpdateResult);

  const handleSubmit = () => {
    dispatch(
      updateEmergencyCase({
        caseId,
        case: {
          emergency_response_case_status: StatusValues.IN_PROGRESS,
        },
      }),
    );
  };

  useEffect(() => {
    if (emergencyCaseUpdateSuccess && onSuccess) {
      onSuccess();
    }
    return () => {
      dispatch(resetEmergencyCaseUpdateState());
    };
  }, [onSuccess, emergencyCaseUpdateSuccess, dispatch]);

  return (
    <Dialog
      name="new-report-dialog"
      size="big"
      isModal
      hasMask={true}
      header={"Accept this case?"}
      onClose={onClose}
      dataTestId="cofirm-dialog"
    >
      <BasicDialogButtons
        onCancel={onClose}
        onSubmit={handleSubmit}
        submitActionText={"Confirm"}
        isSubmitActionPending={emergencyCaseUpdatePending}
      />
    </Dialog>
  );
};
export default AcceptEmergencyCaseDialog;
