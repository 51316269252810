import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { AlerterEntry } from "./Alerter";
import { fetchAlertersApi } from "./api/api";
import { AlertersResponse } from "./api/types";

interface StateSlice {
  alerters: AlerterEntry[];
}
const initialState: StateSlice = {
  alerters: [],
};

const alertersSlice = createSlice({
  name: "alerters",
  initialState: initialState,
  reducers: {
    fetchAlerters() {
      //empty handled by saga
    },
    fetchSuccess(state, action: PayloadAction<AlerterEntry[]>) {
      state.alerters = action.payload;
    },
  },
});

export default alertersSlice.reducer;

export const { fetchAlerters, fetchSuccess } = alertersSlice.actions;

// Async stuff - sagas

function* fetchAlertersSaga() {
  try {
    const response: AxiosResponse<AlertersResponse> =
      yield call(fetchAlertersApi);

    yield put(fetchSuccess(response.data.payload));
  } catch (e) {}
}

function* actionWatcher() {
  yield takeLatest(fetchAlerters.toString(), fetchAlertersSaga);
}

export function* alertsAlertersSagas() {
  yield all([actionWatcher()]);
}
