import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PieChart } from "@csis.com/components";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import { ticketsKeys } from "../../TicketsSearch/constants";
import { StatusTranslationKeys, isStatus } from "../../models/Status";
import {
  getPieLabelGenerator,
  transformTotalDataToPiechartData,
} from "../transformers";
import { getStatusDistributionResult } from "./selectors";

export const StatusPieChart = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslations();
  const history = useHistory();

  const {
    statusDistributions,
    isStatusDistributionPending,
    statusDistributionError,
  } = useSelector(getStatusDistributionResult);

  const statusDistributionsAsPieChart = transformTotalDataToPiechartData(
    statusDistributions,
    getPieLabelGenerator(t, StatusTranslationKeys),
  );

  function handleStatusPieChartClick(chartData: unknown) {
    // @ts-ignore
    const status = chartData?.key;

    if (isStatus(status)) {
      const url = getNewLocationUrl(urls.tickets_search, {
        [ticketsKeys.STATUS]: [status],
        [ticketsKeys.START_DATE]: startDate,
        [ticketsKeys.END_DATE]: endDate,
      });

      history.push(url);
    }
  }

  return (
    <PieChart
      dataKey="amount"
      isLoading={isStatusDistributionPending}
      hasLegend={false}
      data={statusDistributionsAsPieChart}
      error={statusDistributionError}
      onClick={handleStatusPieChartClick}
    />
  );
};
