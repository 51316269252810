import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getSeveritiesDistribution = (state: RootState) =>
  state.alertsStatisticsSeverityReducer.data;

export const getIsSeveritiesDistributionPending = (state: RootState) =>
  state.alertsStatisticsSeverityReducer.isDataPending;

export const getSeveritiesDistributionFetchError = (state: RootState) =>
  state.alertsStatisticsSeverityReducer.dataFetchError;

export const getSeveritiesDistributionResult = createSelector(
  [
    getSeveritiesDistribution,
    getIsSeveritiesDistributionPending,
    getSeveritiesDistributionFetchError,
  ],
  (
    severitiesDistributions,
    isSeveritiesDistributionPending,
    severitiesDistributionError,
  ) => {
    return {
      severitiesDistributions,
      isSeveritiesDistributionPending,
      severitiesDistributionError,
    };
  },
);

export const getSeveritiesDistributionOverTime = (state: RootState) =>
  state.alertsStatisticsSeverityOverTimeReducer.data;

export const getIsSeveritiesDistributionOverTimePending = (state: RootState) =>
  state.alertsStatisticsSeverityOverTimeReducer.isDataPending;

export const getSeveritiesDistributionOverTimeFetchError = (state: RootState) =>
  state.alertsStatisticsSeverityOverTimeReducer.dataFetchError;

export const getSeveritiesDistributionOverTimeResult = createSelector(
  [
    getSeveritiesDistributionOverTime,
    getIsSeveritiesDistributionOverTimePending,
    getSeveritiesDistributionOverTimeFetchError,
  ],
  (
    severitiesDistributionsOverTime,
    isSeveritiesDistributionOverTimePending,
    severitiesDistributionsOverTimeError,
  ) => {
    return {
      severitiesDistributionsOverTime,
      isSeveritiesDistributionOverTimePending,
      severitiesDistributionsOverTimeError,
    };
  },
);
