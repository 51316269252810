import React from "react";
import { Button, Grid, GridItem } from "@csis.com/components";
import { BLUE, GREY, RED } from "@csis.com/components/src/colors";

/*
A very basic dialog buttons component that contains a cancel and create/edit/submit action button.
Used in dialogs where the user can cancel the action or create/edit/submit the form.
*/

interface DialogButtonsProps {
  onCancel: () => void;
  onSubmit: () => void;

  cancelActionText?: string;

  submitActionText: string;
  submitActionDataTestId?: string;
  isSubmitActionPending?: boolean;
  submitActionColor?: GREY | BLUE | RED;

  formId?: string;
}

const BasicDialogButtons: React.FC<DialogButtonsProps> = ({
  onCancel,
  onSubmit,
  cancelActionText,
  submitActionText,
  submitActionDataTestId,
  isSubmitActionPending,
  submitActionColor,
  formId,
}) => {
  return (
    <Grid gutterSize="big">
      <GridItem span={6}>
        <Button
          text={cancelActionText ? cancelActionText : "Cancel"}
          name="cancel-action-dlg-btn"
          color="grey"
          onButtonClick={onCancel}
          isFullWidth
        />
      </GridItem>
      <GridItem span={6}>
        <Button
          text={submitActionText}
          name="submit-action-btn"
          onButtonClick={onSubmit}
          dataTestId={submitActionDataTestId}
          color={submitActionColor}
          formId={formId}
          isLoading={isSubmitActionPending}
          isFullWidth
        />
      </GridItem>
    </Grid>
  );
};

export default BasicDialogButtons;
