import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  IPRangeCreateBody,
  IPRangePreview,
  IPRangeUpdateBody,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { createAsyncDataUpdateSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncDataUpdateSlice";
import {
  deleteIpRangesApi,
  fetchIpRangesApi,
  postIpRangesApi,
  updateIpRangesApi,
} from "./api/api";

const ipRangesSlice = createAsyncArrayFetchSlice<IPRangePreview, undefined>(
  "ipRanges",
);

export const ipRangesReducer = ipRangesSlice.reducer;

export const {
  fetchData: fetchIpRanges,
  setIsPending: setIsIpRangesFetchPending,
  setFetchError: setIpRangesFetchError,
  setFetchSuccess: setFetchIpRangesSuccess,
} = ipRangesSlice.actions;

export type PostPayload = {
  data: IPRangeCreateBody;
  action: "create";
};

export type UpdatePayload = {
  id: string;
  data: IPRangeUpdateBody;
  action: "update";
};

export type DeletePayload = {
  id: string;
  action: "delete";
};

const ipRangesUpdateSlice = createAsyncDataUpdateSlice<
  PostPayload | UpdatePayload | DeletePayload
>("ipRangesUpdate");

export const ipRangesUpdateReducer = ipRangesUpdateSlice.reducer;

export const {
  updateData: modifyIpRanges,
  setIsUpdatePending,
  setIsUpdateError,
  setIsUpdateSuccess,
  resetState,
} = ipRangesUpdateSlice.actions;

// Async stuff - sagas

function* fetchIpRangesSaga() {
  yield put(setIsIpRangesFetchPending());

  try {
    const response: AxiosResponse<any> = yield call(fetchIpRangesApi);
    yield put(setFetchIpRangesSuccess(response.data.payload));
  } catch (e) {
    yield put(setIpRangesFetchError());
  }
}

function* modifyIpRangesSaga(
  action: PayloadAction<PostPayload | UpdatePayload | DeletePayload>,
) {
  yield put(setIsUpdatePending());

  try {
    if (action.payload.action === "create") {
      const data = action.payload.data;
      yield call(postIpRangesApi, data);
    } else if (action.payload.action === "update") {
      const id = action.payload.id;
      const data = action.payload.data;
      yield call(updateIpRangesApi, id, data);
    } else {
      yield call(deleteIpRangesApi, action.payload.id);
    }
    yield put(setIsUpdateSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsUpdateError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchIpRanges.toString(), fetchIpRangesSaga);
  yield takeLatest(modifyIpRanges.toString(), modifyIpRangesSaga);
}

export function* ipRangesSagas() {
  yield all([actionWatcher()]);
}
