import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { DetailResponseVictim } from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { Status } from "../models/Status";
import {
  fetchCredentialsReportApi,
  patchCredentialsReportStatusApi,
  patchCreditCardReportCommentApi,
} from "./api/api";
import { CredentialsReport } from "./types";

interface StateSlice {
  report: CredentialsReport | null;
  isPending: boolean;
  fetchError: string | null;
}
const initialState: StateSlice = {
  report: null,
  isPending: false,
  fetchError: null,
};

const reportSlice = createSlice({
  name: "compromisedDataVictimsInfoReport",
  initialState: initialState,
  reducers: {
    fetchReport(_state, _action: PayloadAction<{ id: string }>) {
      //empty handled by saga
    },
    setReportPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchReportError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchReportSuccess(state, action: PayloadAction<CredentialsReport>) {
      state.isPending = false;
      state.report = action.payload;
      state.fetchError = null;
    },
    updateReportStatus(
      _state,
      _action: PayloadAction<{ id: string; status: Status }>,
    ) {
      //empty handled by saga
    },
    setStatusUpdateError(_state) {},
    updateReportComment(
      _state,
      _action: PayloadAction<{ id: string; customer_comment: string }>,
    ) {
      //empty handled by saga
    },
    setCommentUpdateError(_state) {},
  },
});

export default reportSlice.reducer;

export const {
  fetchReport,
  setReportPending,
  setFetchReportError,
  fetchReportSuccess,

  updateReportStatus,
  setStatusUpdateError,

  updateReportComment,
  setCommentUpdateError,
} = reportSlice.actions;

// Async stuff - sagas

function* fetchReportSaga(action: PayloadAction<{ id: string }>) {
  yield put(setReportPending());
  try {
    const response: AxiosResponse<DetailResponseVictim> = yield call(
      fetchCredentialsReportApi,
      action.payload.id,
    );

    yield put(fetchReportSuccess(response.data.payload as CredentialsReport));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchReportError(errorMessage));
  }
}

function* updateReportStatusSaga(
  action: PayloadAction<{ id: string; status: Status }>,
) {
  // we go the "optimistic way" of updating the fields here
  // we just update the ui immediately (no loaders) and assume success, and we dont say anything
  // we ONLY notify if there was an error in the server update
  try {
    yield call(patchCredentialsReportStatusApi, action.payload);
    yield put(fetchReport({ id: action.payload.id }));
  } catch (e) {
    yield put(setStatusUpdateError());
  }
}

function* updateReportCommentSaga(
  action: PayloadAction<{ id: string; customer_comment: string }>,
) {
  // we go the "optimistic way" of updating the fields here
  // we just update the ui immediately (no loaders) and assume success, and we dont say anything
  // we ONLY notify if there was an error in the server update
  try {
    yield call(patchCreditCardReportCommentApi, action.payload);
    yield put(fetchReport({ id: action.payload.id }));
  } catch (e) {
    yield put(setCommentUpdateError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchReport.toString(), fetchReportSaga);
  yield takeLatest(updateReportStatus.toString(), updateReportStatusSaga);
  yield takeLatest(updateReportComment.toString(), updateReportCommentSaga);
}

export function* compromisedDataVictimsInfoReportSagas() {
  yield all([actionWatcher()]);
}
