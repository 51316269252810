import { RowInterface, TableCellFormatter } from "../../atoms/Table/types";

// return an object with the formatter assigned to the columns
// this returns {column1: formatter, column2: formatter, ... }
// only for columns inclused in graphColumns
export function getFormattersForGraphColumns(
  graphColumns: string[],
  formatter: TableCellFormatter<string>,
) {
  const accumulator: {
    [key: string]: TableCellFormatter<string>;
  } = {};

  return graphColumns.reduce(
    (acc, key) =>
      (
        // eslint-disable-next-line no-sequences
        (acc[key] = formatter), acc
      ),
    accumulator,
  );
}

// this function takes all the rows and returns the max value of every column in graphColumns
export function getMaxValuePerColumn(
  rows: RowInterface[],
  graphColumns: string[],
) {
  const maxValuePerColumn: {
    [key: string]: number;
  } = {};

  rows.forEach((row) => {
    Object.entries(row).forEach((entry) => {
      const [key, value] = entry;
      if (graphColumns.includes(key) && typeof value === "number") {
        if (!maxValuePerColumn[key]) {
          maxValuePerColumn[key] = value;
        } else {
          const currentValue = maxValuePerColumn[key];
          if (currentValue && currentValue < value) {
            maxValuePerColumn[key] = value;
          }
        }
      }
    });
  });

  return maxValuePerColumn;
}
