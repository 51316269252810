import React, { useRef, useState } from "react";

// this is a custom hook that can be used for components that want to track
// when a click occured outside of them (like a Dropdown,IconMenu etc)

export const useClickOutside = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  React.RefObject<HTMLDivElement>,
  React.RefObject<HTMLDivElement>,
] => {
  const ref = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      isComponentVisible
    ) {
      // if modal is open, and the click is inside the modal, then dont close it
      if (modalRef.current && modalRef.current.contains(event.target as Node)) {
        return;
      }
      setIsComponentVisible(false);
    }
  };

  const handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  return [isComponentVisible, setIsComponentVisible, ref, modalRef];
};
