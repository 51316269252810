import React, { MouseEvent, ReactElement, useState } from "react";
import {
  Button,
  GenericFormError,
  HighlightSubString,
  Icon,
  Input,
  Skeleton,
  Stack,
} from "@csis.com/components";
import { checkIfTextContainsSubstring } from "../../HighlightSubString/utils/utils";
import ListMenuBody from "../../ListMenu/ListMenuBody";
import ListMenuHeader from "../../ListMenu/ListMenuHeader";
import ListMenuItem from "../../ListMenu/ListMenuItem";
import { DropdownOption } from "../Dropdown";

function getLabelText(text: string, searchString: string) {
  return <HighlightSubString text={text} searchString={searchString} />;
}

export interface SingleSelectListInterface<T> {
  headerTitle: string;
  options: DropdownOption<T>[];
  value?: T;
  isSearchable?: boolean;
  dataTestId?: string;
  onOptionSelected: (value: T, e?: MouseEvent) => void;
  internalDropdown?: ReactElement;
  isLoading?: boolean;
  error?: string | null;
  onRetry?: () => void;
}

const SingleSelectList = <T,>({
  options,
  value,
  headerTitle,
  onOptionSelected,
  isSearchable,
  dataTestId,
  internalDropdown,
  isLoading,
  error,
  onRetry,
}: SingleSelectListInterface<T>) => {
  const [searchString, setSearchString] = useState("");

  const renderListBody = () => {
    if (error) {
      return (
        <div className="dropdown__list__information-content">
          <Stack isVertical align="center" gutterSize="huge">
            <GenericFormError errorText={error} />
            {onRetry && (
              <Button
                name="single-select-list-retry-btn"
                text="retry"
                type="text"
                onButtonClick={onRetry}
              />
            )}
          </Stack>
        </div>
      );
    } else if (isLoading) {
      return (
        <div className="dropdown__list__information-content">
          <Stack isVertical align="stretch" gutterSize="big">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Stack>
        </div>
      );
    } else {
      return (
        <>
          {options
            .filter((option) => {
              const { isVisible = true } = option;
              return (
                checkIfTextContainsSubstring(
                  String(option.label),
                  searchString,
                ) && isVisible
              );
            })
            .map((option) => {
              return (
                <ListMenuItem
                  onClick={(e) => onOptionSelected(option.value, e)}
                  isSelected={option.value === value}
                  isDisabled={option.isDisabled}
                  tooltipText={option.tooltipText}
                  isIdented={option.isIdented}
                  key={String(option.value)}
                  dataTestId={dataTestId + "list_menu_item_" + option.value}
                >
                  {option.icon && <Icon size="large" kind={option.icon} />}
                  {getLabelText(String(option.label), searchString)}
                </ListMenuItem>
              );
            })}
        </>
      );
    }
  };

  return (
    <>
      <ListMenuHeader>{headerTitle}</ListMenuHeader>
      {isSearchable && (
        <div className="dropdown__list__search">
          <Input
            name="dropdown-search"
            size="small"
            value={searchString}
            onChange={setSearchString}
            isFullWidth
            icon="global_search"
            placeholder="Search..."
            autoFocus
          />
        </div>
      )}
      {internalDropdown && (
        <div className="dropdown__list__internal-dropdown">
          {React.cloneElement(internalDropdown, {
            shouldRenderAsDescendant: true,
          })}
        </div>
      )}
      <ListMenuBody>
        {!isLoading && !error && options.length === 0 ? (
          <div className="dropdown__no-options">No entries found</div>
        ) : (
          renderListBody()
        )}
      </ListMenuBody>
    </>
  );
};

export default SingleSelectList;
