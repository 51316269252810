import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Input, Stack } from "@csis.com/components";
import BasicDialogButtons from "../../../../components/Form/BasicDialogButtons/BasicDialogButtons";
import { StatusValues } from "../../models/Status";
import { getEmergencyCaseUpdateResult } from "../selectors";
import { resetEmergencyCaseUpdateState, updateEmergencyCase } from "../slice";

const DeclineEmergencyCaseDialog = ({
  onClose,
  onSuccess,
  caseId,
  contactName,
}: {
  onClose: () => void;
  onSuccess: () => void;
  caseId: string;
  contactName: string;
}) => {
  const [comment, setComment] = useState<string>("");
  const dispatch = useDispatch();
  const { emergencyCaseUpdateSuccess, emergencyCaseUpdatePending } =
    useSelector(getEmergencyCaseUpdateResult);

  const handleSubmit = () => {
    dispatch(
      updateEmergencyCase({
        caseId: caseId,
        case: {
          emergency_response_case_status: StatusValues.DECLINED,
          decision_comment: comment,
        },
      }),
    );
  };

  useEffect(() => {
    if (emergencyCaseUpdateSuccess && onSuccess) {
      onSuccess();
    }
    return () => {
      dispatch(resetEmergencyCaseUpdateState());
    };
  }, [onSuccess, emergencyCaseUpdateSuccess, dispatch]);

  return (
    <Dialog
      name="new-report-dialog"
      size="big"
      isModal
      hasMask={true}
      header={"Decline this case?"}
      onClose={onClose}
      dataTestId="cofirm-dialog"
    >
      <Stack gutterSize="large" align="stretch" isVertical>
        <div>
          Dear {contactName}, Thank you for reaching out to CSIS Security Group
          A/S for your emergency response needs. We appreciate your trust in our
          expertise and services. We regret to inform you that, after careful
          assessment, we are unable to take on your particular case at this
          time. The reason being {comment || "<COMMENT>"}. We value your
          understanding on this matter and hope that we can serve you in the
          future under different circumstances
        </div>
        <Input
          name="dropdown-search"
          size="small"
          value={comment}
          onChange={setComment}
          isFullWidth
          hasShadow={false}
          icon="global_search"
          placeholder="Comment"
          autoFocus
          type="textarea"
        />

        <BasicDialogButtons
          onCancel={onClose}
          onSubmit={handleSubmit}
          submitActionText={"Confirm"}
          isSubmitActionPending={emergencyCaseUpdatePending}
        />
      </Stack>
    </Dialog>
  );
};
export default DeclineEmergencyCaseDialog;
