import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  OnRowClick,
  RowInterface,
} from "@csis.com/components/src/atoms/Table/types";
import { getSelectedOrgId } from "@csis.com/tip/src/pages/Profile/Security/selectors";
import { navigateOrOpenNewTab } from "@csis.com/tip/src/utils/navigateOrOpenNewTab";
import { isString } from "../../../../models/helpers";

const useNavigateOnRowClick = <ROW extends RowInterface>(
  idKey: string,
  urlTemplate: (id: string) => string,
) => {
  const history = useHistory();
  const selectedOrgId = useSelector(getSelectedOrgId);

  const handleRowClick: OnRowClick<ROW> = useCallback(
    (row, isCtrlKeyPressed) => {
      const rowId = row[idKey];
      if (rowId && isString(rowId)) {
        navigateOrOpenNewTab(
          urlTemplate(rowId),
          history,
          isCtrlKeyPressed,
          selectedOrgId,
        );
      }
    },
    [history, idKey, selectedOrgId, urlTemplate],
  );

  return { handleRowClick };
};

export default useNavigateOnRowClick;
