import { axiosCsisApi } from "@csis.com/tip/src/App";
import { UserGroupUpdate } from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchUserGroupsApi() {
  return axiosCsisApi.getUserGroupsApi10OrganizationUserGroupGet();
}

export async function postUserGroupApi(data: UserGroupUpdate) {
  return axiosCsisApi.createUserGroupApi10OrganizationUserGroupPost(data);
}

export async function updateUserGroupApi(id: string, data: UserGroupUpdate) {
  return axiosCsisApi.updateUserGroupApi10OrganizationUserGroupExternalIdPut(
    id,
    data,
  );
}

export async function deleteUserGroupApi(external_id: string) {
  return axiosCsisApi.deleteUserGroupApi10OrganizationUserGroupExternalIdDelete(
    external_id,
  );
}
