import classNames from "classnames";
import {
  Cell,
  Legend,
  Pie,
  PieChart as PieChartRechart,
  ResponsiveContainer,
  Sector,
} from "recharts";
import React, { ReactElement, ReactNode, useCallback, useState } from "react";
import Skeleton from "../../atoms/Skeleton/Skeleton";
import ChartError from "../ChartError/ChartError";
import NoData from "../NoData/NoData";
import { DEFAULT_COLORS } from "../constants";
import { ChartColor } from "../types";
import { getPieTextSize } from "./utils";

type ColorKeys = { [key: string]: ChartColor };

function getColor(
  key: string,
  index: number,
  colorKeys?: ColorKeys,
): ChartColor {
  if (colorKeys && colorKeys[key]) {
    // @ts-expect-error
    return colorKeys[key];
  }
  // if no colorKeys is specified for the key, we return the mod of the array length
  // so we will return a different color per key and if the keys exceed the DEFAULT_COLORS
  // we will just recycle them
  return DEFAULT_COLORS[index % DEFAULT_COLORS.length] || DEFAULT_COLORS[0];
}

function getText(name: string, cx: number, cy: number, radius: number) {
  if (name.indexOf(" ") >= 0) {
    const wordsInString = name.split(" ");

    // if more than 3 words we return the first 3
    // for responsive purposes
    return (
      <text
        className="csis-pie-chart__center-key"
        x={cx}
        y={cy}
        textAnchor="middle"
        fontSize={"14px"}
      >
        <tspan x={cx} dy="-30">
          {wordsInString[0]}
        </tspan>
        <tspan x={cx} dy="16">
          {wordsInString[1]}
        </tspan>
        <tspan x={cx} dy="16">
          {wordsInString[2]}
        </tspan>
      </text>
    );
  }
  return (
    <text
      className="csis-pie-chart__center-key"
      x={cx}
      y={cy}
      dy="-25"
      textAnchor="middle"
      fontSize={getPieTextSize(name, radius)}
    >
      {name}
    </text>
  );
}

type Formatter = (value: string, entry: any) => ReactNode;

const renderCustomLegend: Formatter = (
  value: string,
  entry: {
    payload?: {
      className: string;
    };
  },
) => {
  return (
    <span className={"csis-pie-chart__legend"}>
      <div
        className={
          entry?.payload?.className
            ? "csis-pie-chart__legend__icon csis-pie-chart__legend__icon--" +
              entry.payload.className
            : "csis-pie-chart__legend__icon"
        }
      />
      {value}
    </span>
  );
};

const renderActiveShape: (props: any) => ReactElement<SVGElement> = (
  props: any,
) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    className,
    name,
    value,
  } = props;

  return (
    <g>
      {getText(name, cx, cy, props.outerRadius)}

      <text
        className="csis-pie-chart__center-value"
        x={cx}
        y={cy}
        dy={37}
        textAnchor="middle"
      >
        {value}
      </text>
      <Sector
        cx={cx}
        className={className}
        cy={cy}
        innerRadius={innerRadius - 6}
        outerRadius={outerRadius + 6}
        cornerRadius={3}
        startAngle={startAngle}
        endAngle={endAngle}
      />
    </g>
  );
};

export type PieChartData = { key: string; name: string; amount: number }[];

export interface PieChartInterface {
  data: PieChartData;
  colorKeys?: ColorKeys;
  error?: string | null;
  dataKey: string;
  isAnimationActive?: boolean;
  hasLegend?: boolean;
  isLoading?: boolean;
  onClick?: (chartElement: unknown) => void;
}

const PieChart: React.FC<PieChartInterface> = ({
  data,
  colorKeys,
  dataKey,
  error,
  isAnimationActive = true,
  hasLegend = true,
  isLoading,
  onClick,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_: unknown, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  const handleClick = useCallback(
    (chartElement: unknown) => {
      if (onClick) {
        onClick(chartElement);
      }
    },
    [onClick],
  );

  if (isLoading) {
    return (
      <ResponsiveContainer>
        <Skeleton type="chart" />
      </ResponsiveContainer>
    );
  }
  if (error) {
    return <ChartError error={error} />;
  }

  const classes = classNames("csis-pie-chart", {
    "csis-pie-chart--clickable": Boolean(onClick),
  });

  return data && data.length ? (
    <ResponsiveContainer>
      <PieChartRechart className={classes} data={data}>
        <Pie
          data={data}
          dataKey={dataKey}
          innerRadius={"71%"}
          outerRadius={"82%"}
          blendStroke
          cornerRadius={3}
          paddingAngle={2}
          isAnimationActive={isAnimationActive}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          onMouseOver={onPieEnter}
          onClick={handleClick}
        >
          {data.map((element, index) => (
            <Cell
              key={element.key}
              name={element.name ? element.name : element.key}
              className={getColor(element.key, index, colorKeys)}
            />
          ))}
        </Pie>
        {hasLegend && (
          <Legend
            iconType="circle"
            iconSize={0}
            verticalAlign="bottom"
            formatter={renderCustomLegend}
          />
        )}
      </PieChartRechart>
    </ResponsiveContainer>
  ) : (
    <NoData />
  );
};

export default PieChart;
