import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { profileKeys } from "@csis.com/tip/src/pages/Profile/Security/constants";
import {
  getIsProfilePending,
  getProfile,
  getSelectedOrgId,
  getUserSubscribedProductsNew,
} from "@csis.com/tip/src/pages/Profile/Security/selectors";
import urls from "@csis.com/tip/src/routes/urls";
import { ORG_ID_PARAM_KEY } from "../../MainApp";
import { setSelectedOrgId } from "../../pages/Profile/Security/slice";
import { useUpdateAndReplaceQueryParams } from "../../utils/updateAndReplaceQueryParams";
import { getSelectedOnboardingStep } from "../OnboardingHelp/selectors";
import Sidebar from "../shared/Sidebar/Sidebar";
import { MenuItems } from "../shared/Sidebar/types";

export const SidebarWrapper: React.FC<{
  menuItems: MenuItems;
  route?: string;
}> = React.memo(({ menuItems, route = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAdmin = useSelector(getProfile)?.[profileKeys.IS_SUPPOSEDLY_ADMIN];
  const userSubscribedProductsNew = useSelector(getUserSubscribedProductsNew);
  const isFetchSubscribedProductsPending = useSelector(getIsProfilePending);

  const organizations = useSelector(getProfile)?.[profileKeys.ORGS];

  const defaultSelectedOrg = organizations?.[0]?.external_id;
  const selectedOrgId = useSelector(getSelectedOrgId) || defaultSelectedOrg;
  const updateAndReplaceQueryParams = useUpdateAndReplaceQueryParams();

  const selectedOnboardingStep = useSelector(getSelectedOnboardingStep);

  const handleSelectedOrgChange = useCallback(
    (organizationId: string) => {
      dispatch(setSelectedOrgId({ organizationId }));

      // navigate to the products page with the new org id
      history.push({
        pathname: urls.products,
        search: `${ORG_ID_PARAM_KEY}=${selectedOrgId}`,
      });

      // also update the url params so we avoid an extra calculation in the re-render
      updateAndReplaceQueryParams(ORG_ID_PARAM_KEY, organizationId);
    },
    [dispatch, history, selectedOrgId, updateAndReplaceQueryParams],
  );

  return (
    <Sidebar
      menuItems={menuItems}
      route={route}
      isAdmin={isAdmin}
      isMenusPending={isFetchSubscribedProductsPending}
      enabledMenusNew={userSubscribedProductsNew}
      selectedOnboardingStep={selectedOnboardingStep}
      organizations={organizations}
      selectedOrgId={selectedOrgId}
      onSelectedOrgChange={handleSelectedOrgChange}
      homeUrl={urls.products}
    />
  );
});
