import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import {
  deleteRetainerAttachmentAPI,
  fetchAttachmentApi,
  fetchRetainerByIdAPI,
  updateRetainerAPI,
  updateRetainerAttachmentsAPI,
} from "./api/api";
import { RetainerResponse, RetainerUpdateBody } from "./api/types";
import { Retainer, RetainerAttachment } from "./types";

interface StateSlice {
  retainer: Retainer | null;
  isRetainerPending: boolean;
  retainerFetchError: string | null;

  isRetainerUpdating: boolean;
  retainerUpdateError: string | null;
  retainerUpdateSuccess: boolean;

  isRetainerAttachmentUpdating: boolean;
  retainerAttachmentUpdateError: string | null;
  retainerAttachmentUpdateSuccess: boolean;

  isRetainerAttachmentDeleting: boolean;
  retainerAttachmentDeleteError: string | null;
  retainerAttachmentDeleteSuccess: boolean;
}

const initialState: StateSlice = {
  retainer: null,
  isRetainerPending: false,
  retainerFetchError: null,

  isRetainerUpdating: false,
  retainerUpdateError: null,
  retainerUpdateSuccess: false,

  isRetainerAttachmentUpdating: false,
  retainerAttachmentUpdateError: null,
  retainerAttachmentUpdateSuccess: false,

  isRetainerAttachmentDeleting: false,
  retainerAttachmentDeleteError: null,
  retainerAttachmentDeleteSuccess: false,
};

const retainerSlice = createSlice({
  name: "retainer",
  initialState: initialState,
  reducers: {
    fetchRetainerById(_state, _action: PayloadAction<string>) {
      //empty handled by saga
    },
    setRetainerPending(state) {
      state.isRetainerPending = true;
      state.retainerFetchError = null;
    },
    setFetchRetainerError(state, action: PayloadAction<string>) {
      state.isRetainerPending = false;
      state.retainerFetchError = action.payload;
      state.retainer = null;
    },
    fetchRetainerSuccess(state, action: PayloadAction<Retainer>) {
      state.isRetainerPending = false;
      state.retainerFetchError = null;
      state.retainer = action.payload;
    },
    updateRetainer(
      _state,
      _action: PayloadAction<{
        retainerId: string;
        retainer: RetainerUpdateBody;
      }>,
    ) {
      //empty handled by saga
    },
    setUpdateRetainerPending(state) {
      state.isRetainerUpdating = true;
      state.retainerUpdateError = null;
      state.retainerUpdateSuccess = false;
    },
    setUpdateRetainerError(state, action: PayloadAction<string>) {
      state.isRetainerUpdating = false;
      state.retainerUpdateError = action.payload;
      state.retainerUpdateSuccess = false;
    },
    setUpdateRetainerSuccess(state, action: PayloadAction<Retainer>) {
      state.isRetainerUpdating = false;
      state.retainerUpdateError = null;
      state.retainerUpdateSuccess = true;
      state.retainer = { ...state.retainer, ...action.payload };
    },
    resetUpdateRetainerState(state) {
      state.isRetainerUpdating = false;
      state.retainerUpdateError = null;
      state.retainerUpdateSuccess = false;
    },
    deleteRetainerAttachmentById(
      _state,
      _action: PayloadAction<{ retainerId: string; attachmentId: string }>,
    ) {
      //empty handled by saga
    },
    updateRetainerAttachmentsById(
      _state,
      _action: PayloadAction<{ retainerId: string; formData: File[] }>,
    ) {
      //empty handled by saga
    },
    setUpdateRetainerAttachmentsPending(state) {
      state.isRetainerAttachmentUpdating = true;
      state.retainerAttachmentUpdateError = null;
      state.retainerAttachmentUpdateSuccess = false;
    },
    setUpdateRetainerAttachmentsError(state, action: PayloadAction<string>) {
      state.isRetainerAttachmentUpdating = false;
      state.retainerAttachmentUpdateError = action.payload;
      state.retainerAttachmentUpdateSuccess = false;
    },
    setUpdateRetainerAttachmentsSuccess(
      state,
      action: PayloadAction<RetainerAttachment[]>,
    ) {
      state.isRetainerAttachmentUpdating = false;
      state.retainerAttachmentUpdateError = null;
      state.retainerAttachmentUpdateSuccess = true;
      state.retainer = {
        ...state.retainer,
        attachments: [
          ...(state.retainer?.attachments || []),
          ...action.payload,
        ],
      } as Retainer;
    },
    resetUpdateRetainerAttachmentState(state) {
      state.isRetainerAttachmentUpdating = false;
      state.retainerAttachmentUpdateError = null;
      state.retainerAttachmentUpdateSuccess = false;
    },
    setDeleteRetainerAttachmentPending(state) {
      state.isRetainerAttachmentDeleting = true;
      state.retainerAttachmentDeleteError = null;
      state.retainerAttachmentDeleteSuccess = false;
    },
    setDeleteRetainerAttachmentError(state, action: PayloadAction<string>) {
      state.isRetainerAttachmentDeleting = false;
      state.retainerAttachmentDeleteError = action.payload;
      state.retainerAttachmentDeleteSuccess = false;
    },
    setDeleteRetainerAttachmentSuccess(state, action: PayloadAction<string>) {
      state.isRetainerAttachmentDeleting = false;
      state.retainerAttachmentDeleteError = null;
      state.retainerAttachmentDeleteSuccess = true;
      state.retainer = {
        ...state.retainer,
        attachments: state.retainer?.attachments.length
          ? state.retainer?.attachments.filter(
              (item) => item.external_id !== action.payload,
            )
          : [],
      } as Retainer;
    },
    resetDeleteRetainerAttachmentState(state) {
      state.isRetainerAttachmentDeleting = false;
      state.retainerAttachmentDeleteError = null;
      state.retainerAttachmentDeleteSuccess = false;
    },
    downloadAttachment(
      _state,
      _action: PayloadAction<{
        retainerId: string;
        attachmentId: string;
        filename: string;
      }>,
    ) {
      //empty handled by saga
    },
  },
});

export const {
  fetchRetainerById,
  setRetainerPending,
  setFetchRetainerError,
  fetchRetainerSuccess,
  updateRetainer,
  setUpdateRetainerPending,
  setUpdateRetainerError,
  setUpdateRetainerSuccess,
  resetUpdateRetainerState,
  deleteRetainerAttachmentById,
  setDeleteRetainerAttachmentPending,
  setDeleteRetainerAttachmentError,
  setDeleteRetainerAttachmentSuccess,
  resetDeleteRetainerAttachmentState,
  downloadAttachment,
  updateRetainerAttachmentsById,
  setUpdateRetainerAttachmentsPending,
  setUpdateRetainerAttachmentsError,
  setUpdateRetainerAttachmentsSuccess,
  resetUpdateRetainerAttachmentState,
} = retainerSlice.actions;

export default retainerSlice.reducer;

function* fetchRetainerByIdSaga(action: PayloadAction<string>) {
  yield put(setRetainerPending());
  try {
    const response: AxiosResponse<RetainerResponse> = yield call(
      fetchRetainerByIdAPI,
      action.payload,
    );
    yield put(fetchRetainerSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchRetainerError(errorMessage));
  }
}

function* updateRetainerSaga(
  action: PayloadAction<{ retainerId: string; retainer: RetainerUpdateBody }>,
) {
  yield put(setUpdateRetainerPending());
  try {
    const response: AxiosResponse<RetainerResponse> = yield call(
      updateRetainerAPI,
      action.payload.retainerId,
      action.payload.retainer,
    );
    yield put(setUpdateRetainerSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setUpdateRetainerError(errorMessage));
  }
}

function* deleteRetainerAttachmentSaga(
  action: PayloadAction<{ retainerId: string; attachmentId: string }>,
) {
  yield put(setDeleteRetainerAttachmentPending());
  try {
    yield call(
      deleteRetainerAttachmentAPI,
      action.payload.retainerId,
      action.payload.attachmentId,
    );
    yield put(setDeleteRetainerAttachmentSuccess(action.payload.attachmentId));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setDeleteRetainerAttachmentError(errorMessage));
  }
}

function* downloadAttachmentSaga(
  action: PayloadAction<{
    retainerId: string;
    attachmentId: string;
    filename: string;
  }>,
) {
  try {
    const response: AxiosResponse<Blob> = yield call(
      fetchAttachmentApi,
      action.payload.retainerId,
      action.payload.attachmentId,
    );

    const blob = response.data;
    downloadBlobForUser(blob, action.payload.filename);
  } catch (e) {}
}

function* updateRetainerAttachmentSaga(
  action: PayloadAction<{ retainerId: string; formData: File[] }>,
) {
  yield put(setUpdateRetainerAttachmentsPending());
  try {
    const response: AxiosResponse<any> = yield call(
      updateRetainerAttachmentsAPI,
      action.payload.retainerId,
      action.payload.formData,
    );
    yield put(setUpdateRetainerAttachmentsSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setUpdateRetainerAttachmentsError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchRetainerById.toString(), fetchRetainerByIdSaga);
  yield takeLatest(updateRetainer.toString(), updateRetainerSaga);
  yield takeLatest(
    deleteRetainerAttachmentById.toString(),
    deleteRetainerAttachmentSaga,
  );
  yield takeLatest(downloadAttachment.toString(), downloadAttachmentSaga);
  yield takeLatest(
    updateRetainerAttachmentsById.toString(),
    updateRetainerAttachmentSaga,
  );
}

export function* retainerSagas() {
  yield all([actionWatcher()]);
}
