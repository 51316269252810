import { axiosCsisApi } from "@csis.com/tip/src/App";
import { QueryParams } from "../../types";

export async function fetchStatusStatisticsApi(params: Partial<QueryParams>) {
  return axiosCsisApi.getVictimStatsStateApi20CompromisedDataCredentialReportStatisticsStatusGet(
    {
      ...params,
    },
  );
}
