export function getPositionOfMouseAsIndex(
  containerHeight: number,
  totalItems: number,
  posY: number,
) {
  // first we get the height of item inside the container
  const itemHeight = containerHeight / totalItems;

  // then we infer the position of the mouse relative to the elements
  return Math.floor(posY / itemHeight);
}
