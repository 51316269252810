import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { EmergencyCaseAttachment } from "../EmergencyCase/types";
import { postEmergencyCaseApi } from "./api/api";
import { NewEmergancyCase } from "./types";

interface StateSlice {
  isPending: boolean;
  postError: string | null;
  postSuccess: boolean;
  createdCaseId: string | null;

  uploadedAttachments: EmergencyCaseAttachment[];
  isAttachmentUploadPending: boolean;
  attachmentUploadError: string | null;
  attachmentUploadSuccess: boolean;
}

const initialState: StateSlice = {
  isPending: false,
  postError: null,
  postSuccess: false,
  createdCaseId: null,

  uploadedAttachments: [],
  isAttachmentUploadPending: false,
  attachmentUploadError: null,
  attachmentUploadSuccess: false,
};

const newEmergencyCaseSlice = createSlice({
  name: "newEmergencyCase",
  initialState: initialState,
  reducers: {
    createEmergencyCase(
      _state,
      _action: PayloadAction<{ newEmergencyCase: NewEmergancyCase }>,
    ) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.postError = null;
      state.postSuccess = false;
    },
    setPostError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.postError = action.payload;
    },
    setPostSuccess(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.postSuccess = true;
      state.createdCaseId = action.payload;
    },
    resetState(state) {
      state.isPending = false;
      state.postError = null;
      state.postSuccess = false;
      state.createdCaseId = null;

      state.uploadedAttachments = [];
      state.attachmentUploadSuccess = false;
      state.isAttachmentUploadPending = false;
      state.attachmentUploadError = null;
    },

    setIsAttachmentPending(state) {
      state.isAttachmentUploadPending = true;
      state.attachmentUploadSuccess = false;
      state.attachmentUploadError = null;
    },
    setAttachmentPostError(state, action: PayloadAction<string>) {
      state.isAttachmentUploadPending = false;
      state.attachmentUploadSuccess = false;
      state.attachmentUploadError = action.payload;
    },
    setAttachmentPostSuccess(
      state,
      action: PayloadAction<EmergencyCaseAttachment[]>,
    ) {
      state.uploadedAttachments = [
        ...state.uploadedAttachments,
        ...action.payload,
      ];
      state.isAttachmentUploadPending = false;
      state.attachmentUploadSuccess = true;
      state.attachmentUploadError = null;
    },
    removeAttachmentById(state, action: PayloadAction<string>) {
      const id = action.payload;
      state.uploadedAttachments = state.uploadedAttachments.filter(
        (attachment) => attachment.external_id !== id,
      );
    },
    removeAllAttachments(state) {
      // used to clear the attachments after successful upload
      state.uploadedAttachments = [];
    },
  },
});

export default newEmergencyCaseSlice.reducer;

export const {
  createEmergencyCase,
  setPending,
  setPostError,
  setPostSuccess,
  resetState,
  setIsAttachmentPending,
  setAttachmentPostError,
  setAttachmentPostSuccess,
  removeAttachmentById,
  removeAllAttachments,
} = newEmergencyCaseSlice.actions;

function* postEmergencyCaseSaga(
  action: PayloadAction<{ newEmergencyCase: NewEmergancyCase }>,
) {
  try {
    yield put(setPending());
    const response: AxiosResponse = yield call(
      postEmergencyCaseApi,
      action.payload.newEmergencyCase,
    );
    yield put(setPostSuccess(response?.data?.payload.external_id));
  } catch (error) {
    yield put(setPostError(handleRequestError(error)));
  }
}

function* actionWatcher() {
  yield takeLatest(createEmergencyCase.toString(), postEmergencyCaseSaga);
}

export function* newEmergencyCaseSaga() {
  yield all([actionWatcher()]);
}
