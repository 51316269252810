import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { NotificationDigestPreferenceSimpleSetListResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { CredentialsSetting, CredentialsSettings } from "../types";
import {
  fetchCredentialsSettingsApi,
  patchCredentialsSettingsApi,
} from "./api/api";

interface StateSlice {
  credentialsSettings: CredentialsSettings | null;
  isPending: boolean;
  fetchError: string | null;

  isPatchPending: boolean;
}
const initialState: StateSlice = {
  credentialsSettings: null,
  isPending: false,
  fetchError: null,

  isPatchPending: false,
};

const credentialsSettingsSlice = createSlice({
  name: "credentialsSettings",
  initialState: initialState,
  reducers: {
    fetchCredentialsSettings() {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<CredentialsSettings>) {
      state.isPending = false;
      state.credentialsSettings = action.payload;
      state.fetchError = null;
    },

    patchCredentialsSettings(
      _state,
      _action: PayloadAction<CredentialsSetting>,
    ) {
      //empty handled by saga
    },
    setIsPatchPending(state, action: PayloadAction<boolean>) {
      state.isPatchPending = action.payload;
    },
  },
});

export default credentialsSettingsSlice.reducer;

export const {
  fetchCredentialsSettings,
  setPending,
  setFetchError,
  fetchSuccess,

  patchCredentialsSettings,
  setIsPatchPending,
} = credentialsSettingsSlice.actions;

// Async stuff - sagas

function* fetchCredentialsSettingsSaga() {
  yield put(setPending());
  try {
    const response: AxiosResponse<NotificationDigestPreferenceSimpleSetListResponse> =
      yield call(fetchCredentialsSettingsApi);

    yield put(fetchSuccess(response.data.payload as CredentialsSettings));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* patchCredentialsSettingsSaga(
  action: PayloadAction<CredentialsSetting>,
) {
  try {
    yield put(setIsPatchPending(true));
    yield call(patchCredentialsSettingsApi, action.payload);

    // refetch the profile after a successful update
    yield put(fetchCredentialsSettings());
    yield put(setIsPatchPending(false));
  } catch (e) {
    yield put(setIsPatchPending(false));
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchCredentialsSettings.toString(),
    fetchCredentialsSettingsSaga,
  );
  yield takeLatest(
    patchCredentialsSettings.toString(),
    patchCredentialsSettingsSaga,
  );
}

export function* credentialsSettingsSagas() {
  yield all([actionWatcher()]);
}
