export const ticketKeys = {
  ID: "id",
  DISPLAY_ID: "display_id",
  TITLE: "title",
  CREATOR: "creator",
  OWNERS: "owners",
  STATUS: "status",
  SEVERITY: "severity",
  TYPE: "type",
  CIRK_REF: "cirk_reference",
  CUSTOMER_REF: "customer_reference",
  CREATED: "created",
  FLAGS: "flags",
  PARENT: "parent",
  CHILDREN: "children",
  LAST_UPDATED: "updated",
  DESCRIPTION: "description",
  PARTICIPANTS: "participants",
  ATTACHMENTS: "attachments",
  ATTACHED_ALERTS: "alerts",
  ATTACHED_VICTIM_REPORTS: "victim_reports",
  ATTACHED_PAYMENT_CARD_REPORTS: "credit_card_reports",
  USER_FEEDBACK: "user_feedback",
} as const;

export const ticketCommentKeys = {
  CREATED: "created",
  CREATOR: "creator",
  COMMENT: "comment",
  IS_CREATOR: "user_is_creator",
  ATTACHMENTS: "attachments",
} as const;

export const ticketAttachmentKeys = {
  ID: "id",
  FILE_NAME: "filename",
  FILE_SIZE: "filesize",
  CREATED: "created",
  CREATOR: "creator",
  IS_CREATOR: "user_is_creator",
} as const;

export const ticketTimelineKeys = {
  ...ticketCommentKeys,
  ...ticketAttachmentKeys,
} as const;
