import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  GetBusinessUnitsApi10AdminBusinessUnitGetParams,
  GetBusinessUnitsWithRetainersByAdminApi10AdminRetainerGetParams,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";

export async function fetchBusinessUnitsApi(
  queryParams: GetBusinessUnitsApi10AdminBusinessUnitGetParams,
) {
  return axiosCsisApi.getBusinessUnitsApi10AdminBusinessUnitGet(
    queryParams,
    STRINGIFY_QUERY_PARAMS,
  );
}

export async function fetchBusinessUnitsWithRetainersApi(
  queryParams: GetBusinessUnitsWithRetainersByAdminApi10AdminRetainerGetParams,
) {
  return axiosCsisApi.getBusinessUnitsWithRetainersByAdminApi10AdminRetainerGet(
    queryParams,
    STRINGIFY_QUERY_PARAMS,
  );
}
