import { auditLogKeys } from "../constants";
import { QueryParams } from "../types";

// mapper for api requests to transform start_date and end_date to appropriate query params
export function mapAuditLogStartAndEndDateToCorrectType(
  queryParams: Partial<QueryParams>,
) {
  return Object.assign({}, queryParams, {
    [auditLogKeys.EVENT_AFTER]: queryParams[auditLogKeys.START_DATE],
    [auditLogKeys.EVENT_BEFORE]: queryParams[auditLogKeys.END_DATE],
  });
}
