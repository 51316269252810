import classNames from "classnames";
import React from "react";
import DropdownHeader from "@csis.com/components/src/atoms/Dropdown/DropdownHeader";
import { useClickOutside } from "@csis.com/components/src/atoms/helpers/useClickOutside";
import { SMALL } from "../../sizes";
import { renderModal } from "../ListMenu/renderModal";
import DatePickerList from "./DatePickerList";
import { DEFAULT_OPTIONS } from "./constants";
import { DatePickerOption, DateRange } from "./types";
import * as utils from "./utils";

// a DatePicker can either be a simple datepicker where a Date is selected
// or a range DatePicker where the user can select from a list of predefined dates like yesterday,last week etc
// or a custom DateRange (which is a [Date,Date] tuple)
// see stories for all use cases
export interface DatePickerInterface {
  selectedDate?: Date;
  selectedRange?: DateRange;
  canSelectRange?: boolean;
  isFullWidth?: boolean;
  type?: "text" | "shadow";
  size?: SMALL;
  options?: DatePickerOption[];
  maxDate?: Date | null;
  headerTitle?: string;
  onSelect: (value: DateRange | Date | undefined) => void;
  opensFromRight?: boolean;
  opensFromTop?: boolean;
  dataTestId?: string;
  name: string;
  formatDate?: (date?: Date | null) => string;
}

const DatePicker: React.FC<DatePickerInterface> = ({
  selectedDate,
  selectedRange,
  onSelect,
  canSelectRange,
  isFullWidth,
  headerTitle = canSelectRange ? "Select range" : "Select date",
  size,
  options = DEFAULT_OPTIONS,
  type,
  maxDate = new Date(),
  opensFromRight,
  opensFromTop,
  dataTestId,
  formatDate,
  name,
}) => {
  const [isComponentVisible, setIsComponentVisible, ref, modalRef] =
    useClickOutside();

  const classes = classNames("datepicker", {
    "datepicker--small": size === "small",
    "datepicker--shadow": type === "shadow",
    "datepicker--full-width": isFullWidth,
  });

  const handleDateSelect = (value: DateRange | Date | undefined) => {
    onSelect(value);
    setIsComponentVisible(false);
  };

  // when the dropdown is focused we can open/close it with space
  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === " " && setIsComponentVisible) {
        event.stopPropagation();
        event.preventDefault();
        if (!isComponentVisible) {
          setIsComponentVisible(true);
        }
      }
    },
    [isComponentVisible, setIsComponentVisible],
  );
  const selectedTimePeriod = utils.getTimePeriodFromRange(selectedRange);

  return (
    <div
      ref={ref}
      className={classes}
      data-test-id={dataTestId}
      tabIndex={0}
      onKeyPress={handleKeyPress}
      aria-expanded={isComponentVisible}
      role={"button"}
    >
      <DropdownHeader
        setIsComponentVisible={setIsComponentVisible}
        isFullWidth={isFullWidth}
        isComponentVisible={isComponentVisible}
        options={options}
        headerTitle={utils.getHeaderTitle(
          headerTitle,
          selectedDate,
          selectedRange,
        )}
        value={utils.getHeaderValue(
          canSelectRange,
          selectedTimePeriod,
          options,
        )}
      />
      <div>
        {isComponentVisible &&
          renderModal(
            <DatePickerList
              value={selectedTimePeriod}
              onDateSelected={handleDateSelect}
              options={options}
              range={selectedRange}
              date={selectedDate}
              maxDate={maxDate}
              canSelectRange={canSelectRange}
              opensFromRight={opensFromRight}
              opensFromTop={opensFromTop}
              dataTestId={dataTestId + "-list"}
              parentName={name}
              formatDate={formatDate}
            />,
            ref,
            modalRef,
            opensFromRight,
            isFullWidth,
          )}
      </div>
    </div>
  );
};

export default DatePicker;
