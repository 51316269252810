import { Guard, isArrayOf } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const ticketTypeValues = {
  MALWARE: "malware",
  MOBILE_MALWARE: "malware.mobile-malware",
  PC_MALWARE: "malware.pc-malware",
  PHISHING: "phishing",
  CEO_FRAUD: "phishing.ceo-fraud",
  SMISHING: "phishing.smishing",
  WEBSITE: "phishing.website",
  MISC: "miscellaneous",
  APPLICATION: "miscellaneous.application",
  DROPDATA: "miscellaneous.dropdata",
  NETWORK_BEHAVIOUR: "miscellaneous.network-behavior",
  AUTHENTICATION: "miscellaneous.authentication",
  NON_CLASSIFIED: "miscellaneous.non-classified",
  POLICY: "miscellaneous.policy",
  SUSPICIOUS_ACTIVITY: "miscellaneous.suspicious-activity",
  SOCIAL_ENGINEERING: "social-engineering",
  PHYSICAL: "social-engineering.physical",
  VISHING: "social-engineering.vishing",
  SUPPORT: "support",
  ACCOUNT: "support.account",
  CIRK: "support.cirk",
  DOMAIN_RE_EVAL: "support.domain-re-evaluation",
  IR: "support.incident-response",
  OTHER: "support.other",
  SEC_DNS: "support.secure-dns",
} as const;

export type TicketType =
  (typeof ticketTypeValues)[keyof typeof ticketTypeValues];

export const isTicketType = (x: any): x is TicketType => {
  return Object.values(ticketTypeValues).includes(x);
};

export const isArrayOfTicketTypes: Guard<TicketType[]> =
  isArrayOf(isTicketType);

// this is the part we localize
export const ticketTypeTranslationKeys: {
  [key in TicketType]: TranslationKey;
} = {
  [ticketTypeValues.MALWARE]: "malware",
  [ticketTypeValues.MOBILE_MALWARE]: "mobile_malware",
  [ticketTypeValues.PC_MALWARE]: "pc_malware",
  [ticketTypeValues.PHISHING]: "phishing",
  [ticketTypeValues.CEO_FRAUD]: "ceo_fraud",
  [ticketTypeValues.SMISHING]: "smishing",
  [ticketTypeValues.WEBSITE]: "website",
  [ticketTypeValues.MISC]: "miscellaneous",
  [ticketTypeValues.APPLICATION]: "application",
  [ticketTypeValues.DROPDATA]: "dropdata",
  [ticketTypeValues.NETWORK_BEHAVIOUR]: "network_behaviour",
  [ticketTypeValues.AUTHENTICATION]: "authentication",
  [ticketTypeValues.NON_CLASSIFIED]: "non_classified",
  [ticketTypeValues.POLICY]: "policy",
  [ticketTypeValues.SUSPICIOUS_ACTIVITY]: "suspicious_activity",
  [ticketTypeValues.SOCIAL_ENGINEERING]: "social_engineering",
  [ticketTypeValues.PHYSICAL]: "physical",
  [ticketTypeValues.VISHING]: "vishing",
  [ticketTypeValues.SUPPORT]: "support",
  [ticketTypeValues.ACCOUNT]: "account",
  [ticketTypeValues.CIRK]: "cirk",
  [ticketTypeValues.DOMAIN_RE_EVAL]: "domain_reevaluation",
  [ticketTypeValues.IR]: "incident_response",
  [ticketTypeValues.OTHER]: "other",
  [ticketTypeValues.SEC_DNS]: "sec_dns",
} as const;
