import { axiosCsisApi } from "@csis.com/tip/src/App";
import { STRINGIFY_QUERY_PARAMS } from "@csis.com/tip/src/api/utils";
import { QueryParams } from "../types";

export async function fetchRetainersApi(queryParams: Partial<QueryParams>) {
  return axiosCsisApi.getBusinessUnitsWithRetainersByAdminApi10AdminRetainerGet(
    queryParams,
    STRINGIFY_QUERY_PARAMS,
  );
}
