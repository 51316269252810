import { axiosCsisApi } from "@csis.com/tip/src/App";
import { UpdateUserBody } from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchUsersApi() {
  return axiosCsisApi.getUsersApi10OrganizationUserGet();
}

export async function postUserApi(data: UpdateUserBody) {
  return axiosCsisApi.createUserApi10OrganizationUserPost(data);
}

export async function updateUserApi(external_id: string, data: UpdateUserBody) {
  return axiosCsisApi.updateUserApi10OrganizationUserExternalIdPut(
    external_id,
    data,
  );
}

export async function deleteUserApi(externalId: string) {
  return axiosCsisApi.deleteUserApi10OrganizationUserExternalIdDelete(
    externalId,
  );
}

export async function unDeleteUserApi(externalId: string) {
  return axiosCsisApi.undeleteUserApi10OrganizationUserExternalIdUndeletePatch(
    externalId,
  );
}
