import classNames from "classnames";
import React from "react";
import Icon from "../../icons/Icon";

export interface PillInterface {
  onClick?: () => void;
  dataTestId?: string;
  size?: "small";
  text: string;
}

const Pill = ({ text, size, onClick, dataTestId }: PillInterface) => {
  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && onClick) {
        event.stopPropagation();
        event.preventDefault();
        onClick();
      }
    },
    [onClick],
  );

  const classes = classNames("pill", {
    "pill--small": size === "small",
  });

  return (
    <div
      tabIndex={0}
      className={classes}
      onClick={onClick}
      onKeyPress={handleKeyPress}
      data-test-id={dataTestId}
      title={text}
    >
      {text}
      <Icon kind="close" size="big" />
    </div>
  );
};

export default React.memo(Pill);
