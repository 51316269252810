import React from "react";
import { isNullOrUndefined } from "../../../utils/utils";

// The Ghost element, is a placeholder where it can be just empty space
// or a silhouette of the element we are dragging
// it shows the user where the element they are dragging are going to drop
export function GhostElement({ height }: { height?: number }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: height,
      }}
    />
  );
}

// this function returns a Ghost elements of false
export function getGhostElement(
  elementBeingDragged: {
    index: number;
    height: number;
  } | null,
  positionOfDraggedElement: number | null,
  position: "before" | "after",
) {
  const elementBeingDraggedIndex = elementBeingDragged?.index;

  if (
    !isNullOrUndefined(elementBeingDraggedIndex) &&
    !isNullOrUndefined(positionOfDraggedElement)
  ) {
    if (position === "before") {
      return (
        elementBeingDraggedIndex >= positionOfDraggedElement && (
          <GhostElement height={elementBeingDragged?.height} />
        )
      );
    } else {
      return (
        elementBeingDraggedIndex < positionOfDraggedElement && (
          <GhostElement height={elementBeingDragged?.height} />
        )
      );
    }
  }
  return false;
}
