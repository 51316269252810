import { useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, LineChart, Stack } from "@csis.com/components";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import {
  SeverityChartColors,
  SeverityTranslationKeys,
  SeverityValues,
} from "../../models/Severity";
import { transformHistogramDataToLinechartData } from "../transformers";
import { getSeveritiesDistributionOverTimeResult } from "./selectors";

export const SeverityLineChart = () => {
  const { t } = useTranslations();

  const [selectedSeverities, setSelectedSeverities] = useState(
    Object.values(SeverityValues).map((severity) => {
      return severity;
    }),
  );

  const {
    severitiesDistributionsOverTime,
    isSeveritiesDistributionOverTimePending,
    severitiesDistributionsOverTimeError,
  } = useSelector(getSeveritiesDistributionOverTimeResult);

  // need to define what an "empty" value corresponds to in chart data
  // see more in transformHistogramDataToLinechartData
  const defaultUndefinedValues: { [key: string]: number } = {};
  Object.values(SeverityValues).forEach((severity) => {
    defaultUndefinedValues[severity] = 0;
  });
  const severitiesDistributionsOverTimeAsLineChart =
    transformHistogramDataToLinechartData(
      severitiesDistributionsOverTime,
      defaultUndefinedValues,
    );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {severitiesDistributionsOverTime &&
        severitiesDistributionsOverTime.length > 0 && (
          <Stack isVertical align="end">
            <Dropdown
              name={"severity"}
              headerTitle={"Severities"}
              onSelect={(values) => {
                setSelectedSeverities(values);
              }}
              isMultiSelect={true}
              type="text"
              size="small"
              opensFromRight
              options={Object.values(SeverityValues).map((severity) => ({
                value: severity,
                label: t(SeverityTranslationKeys[severity]),
              }))}
              values={selectedSeverities}
            />
          </Stack>
        )}
      <div style={{ width: "100%", height: "90%" }}>
        <LineChart
          xAxisKey="name"
          yAxisKeys={selectedSeverities.map((severity) => ({
            key: severity,
            name: t(SeverityTranslationKeys[severity]),
            color: SeverityChartColors[severity],
          }))}
          data={severitiesDistributionsOverTimeAsLineChart}
          isLoading={isSeveritiesDistributionOverTimePending}
          error={severitiesDistributionsOverTimeError}
        />
      </div>
    </div>
  );
};
