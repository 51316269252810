/* eslint-disable sonarjs/cognitive-complexity */
import { OpenAPIV3 } from "openapi-types";
import { isSchemaObject } from "./types";

// Function to transform the Request schema
export const transformRequestSchema = (schema: OpenAPIV3.SchemaObject) => {
  if (!schema || !schema.properties) return {};

  const transformedRequest: Record<string, any> = {};

  Object.entries(schema.properties).forEach(([key, property]) => {
    const propertySchema = property as OpenAPIV3.SchemaObject;

    if (propertySchema && isSchemaObject(propertySchema)) {
      // Use transformPrimitive to handle all types and formats
      transformedRequest[key] = transformPrimitive(propertySchema);
    }
  });

  return transformedRequest;
};

// Function to transform the schema object
export const transformResponseSchema = (
  schema: OpenAPIV3.SchemaObject,
): any => {
  if (!schema || !schema.properties) return {};

  const transformed: Record<string, any> = {};

  // Handle 'success' property as a special case
  if (
    schema.properties["success"] &&
    isSchemaObject(schema.properties["success"]) &&
    schema.properties["success"].default !== undefined
  ) {
    transformed.success = schema.properties["success"].default;
  }

  // Handle 'payload' or any other nested object or array
  if (
    schema.properties["payload"] &&
    isSchemaObject(schema.properties["payload"])
  ) {
    transformed.payload = transformNestedSchema(schema.properties["payload"]);
  }

  if (
    schema.properties["detail"] &&
    isSchemaObject(schema.properties["detail"])
  ) {
    transformed.detail = transformNestedSchema(schema.properties["detail"]);
  }

  return transformed;
};

export const transformNestedSchema = (schema: OpenAPIV3.SchemaObject): any => {
  if (schema.type === "array" && isSchemaObject(schema.items)) {
    // Transform array items
    return [transformNestedSchema(schema.items as OpenAPIV3.SchemaObject)];
  }

  if (schema.type === "object" && schema.properties) {
    const transformedObject: any = {};

    Object.entries(schema.properties).forEach(([key, property]) => {
      const propertySchema = property as OpenAPIV3.SchemaObject;

      if (
        propertySchema.type === "array" &&
        isSchemaObject(propertySchema.items)
      ) {
        // Handle arrays, with support for `anyOf`
        transformedObject[key] = propertySchema.items.anyOf
          ? propertySchema.items.anyOf.map((item) =>
              transformNestedSchema(item as OpenAPIV3.SchemaObject),
            )
          : [transformNestedSchema(propertySchema.items)];
      } else if (propertySchema.type === "object") {
        // Handle nested objects
        transformedObject[key] = transformNestedSchema(propertySchema);
      } else {
        // Handle primitives (boolean, string, number, integer)
        transformedObject[key] = transformPrimitive(propertySchema);
      }
    });

    return transformedObject;
  }

  // Handle top-level primitives
  return transformPrimitive(schema);
};

const transformPrimitive = (schema: OpenAPIV3.SchemaObject): any => {
  switch (schema.format) {
    case "uuid":
      return "4ee4e94d-1f7f-4f23-83ef-ef321cac9436";
    case "date-time":
      return "2024-10-27T09:00:17.242Z";
    case "email":
      return "user@example.com";
    default:
      if (
        schema.type &&
        ["boolean", "string", "number", "integer"].includes(schema.type)
      ) {
        return schema.type;
      }
      if (schema.type === "array") {
        return [transformNestedSchema(schema.items as OpenAPIV3.SchemaObject)];
      }
      return "unknown"; // Default case for unsupported types
  }
};
