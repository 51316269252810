import { FC } from "react";
import Icon from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";
import AccordionItemBody from "../Accordion/AccordionItemBody";
import AccordionItemHeader from "../Accordion/AccordionItemHeader";
import Divider from "../Divider/Divider";
import ProgressBar from "../ProgressBar/ProgressBar";
import { Question } from "./Question";
import { QuestionnaireData } from "./types";
import {
  calculatePercentageOfAnsweredQuestions,
  getTotalAnsweredQuestions,
} from "./utils";

export interface QuestionnaireInterface {
  questionnaire: QuestionnaireData;
  onAnswerChange: (
    newAnswer: string | string[],
    qId: string,
    sectionId?: string,
  ) => void;
  shouldShowProgressBar?: boolean;
}

const Questionnaire: FC<QuestionnaireInterface> = ({
  questionnaire,
  onAnswerChange,
  shouldShowProgressBar = true,
}) => {
  return (
    <div className="questionnaire__container">
      <Stack isVertical gutterSize="big" isExpanded align="center">
        {shouldShowProgressBar && (
          <div className="questionnaire__progressbar__container">
            <ProgressBar
              completed={calculatePercentageOfAnsweredQuestions(questionnaire)}
            />
          </div>
        )}
        <div className="questionnaire__questions__container">
          {questionnaire.sections && (
            <Accordion isTransparent headerGutterSize="big" isFullWidth>
              {Object.entries(questionnaire.sections).map(
                ([sectionId, section]) => (
                  <AccordionItem key={section.sectionTitle}>
                    <AccordionItemHeader
                      sideContent={
                        <Stack align="center">
                          {getTotalAnsweredQuestions(section.questions) ===
                            section.questions.length && (
                            <Icon kind="check" size="small" />
                          )}
                          <div>
                            {getTotalAnsweredQuestions(section.questions)}/
                            {section.questions.length}
                          </div>
                        </Stack>
                      }
                    >
                      {section.sectionTitle}
                    </AccordionItemHeader>
                    <AccordionItemBody>
                      <Stack isVertical gutterSize="large">
                        <Stack isVertical gutterSize="large" align="stretch">
                          {section.questions.map((question) => (
                            <Question
                              question={question}
                              onAnswerChange={onAnswerChange}
                              key={question.id}
                              sectionId={sectionId}
                            />
                          ))}
                        </Stack>
                        <Divider />
                        <div />
                      </Stack>
                    </AccordionItemBody>
                  </AccordionItem>
                ),
              )}
            </Accordion>
          )}
          {questionnaire.questions && (
            <Stack isVertical gutterSize="large" align="stretch">
              {questionnaire.questions.map((question) => (
                <Question
                  question={question}
                  onAnswerChange={onAnswerChange}
                  key={question.id}
                />
              ))}
            </Stack>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default Questionnaire;
