import { axiosCsisApi } from "@csis.com/tip/src/App";
import { TicketsSettings } from "../../types";

export async function fetchTicketsSettingsApi() {
  return axiosCsisApi.getTicketNotificationPreferencesApi10ProfilePreferenceNotificationTicketGet();
}

export async function patchTicketsSettingsApi(settings: TicketsSettings) {
  return axiosCsisApi.patchTicketNotificationPreferenceApi10ProfilePreferenceNotificationTicketPatch(
    // @ts-ignore can be null - api type is wrong
    settings,
  );
}
