import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getRetainer = (state: RootState) => state.retainerReducer.retainer;
export const getIsRetainerPending = (state: RootState) =>
  state.retainerReducer.isRetainerPending;
export const getRetainerFetchError = (state: RootState) =>
  state.retainerReducer.retainerFetchError;

export const getRetainerResult = createSelector(
  [getRetainer, getIsRetainerPending, getRetainerFetchError],
  (retainer, isRetainerPending, retainerFetchError) => {
    return { retainer, isRetainerPending, retainerFetchError };
  },
);

export const getUpdateRetainerSuccess = (state: RootState) =>
  state.retainerReducer.retainerUpdateSuccess;
export const getIsUpdateRetainerPending = (state: RootState) =>
  state.retainerReducer.isRetainerUpdating;
export const getUpdateRetainerError = (state: RootState) =>
  state.retainerReducer.retainerUpdateError;

export const getUpdateRetainerResult = createSelector(
  [
    getUpdateRetainerSuccess,
    getIsUpdateRetainerPending,
    getUpdateRetainerError,
  ],
  (retainerUpdateSuccess, isRetainerUpdating, retainerUpdateError) => {
    return {
      retainerUpdateSuccess,
      isRetainerUpdating,
      retainerUpdateError,
    };
  },
);

export const getUpdateRetainerAttachmentSuccess = (state: RootState) =>
  state.retainerReducer.retainerAttachmentUpdateSuccess;
export const getIsUpdateRetainerAttachmentPending = (state: RootState) =>
  state.retainerReducer.isRetainerAttachmentUpdating;
export const getUpdateRetainerAttachmentError = (state: RootState) =>
  state.retainerReducer.retainerAttachmentUpdateError;
export const getUpdateRetainerAttachmentResult = createSelector(
  [
    getUpdateRetainerAttachmentSuccess,
    getIsUpdateRetainerAttachmentPending,
    getUpdateRetainerAttachmentError,
  ],
  (
    retainerAttachmentUpdateSuccess,
    isRetainerAttachmentUpdating,
    retainerAttachmentUpdateError,
  ) => {
    return {
      retainerAttachmentUpdateSuccess,
      isRetainerAttachmentUpdating,
      retainerAttachmentUpdateError,
    };
  },
);

export const getDeleteRetainerAttachmentSuccess = (state: RootState) =>
  state.retainerReducer.retainerAttachmentDeleteSuccess;
export const getIsDeleteRetainerAttachmentPending = (state: RootState) =>
  state.retainerReducer.isRetainerAttachmentDeleting;
export const getDeleteRetainerAttachmentError = (state: RootState) =>
  state.retainerReducer.retainerAttachmentDeleteError;
export const getDeleteRetainerAttachmentResult = createSelector(
  [
    getDeleteRetainerAttachmentSuccess,
    getIsDeleteRetainerAttachmentPending,
    getDeleteRetainerAttachmentError,
  ],
  (
    retainerAttachmentDeleteSuccess,
    isRetainerAttachmentDeleting,
    retainerAttachmentDeleteError,
  ) => {
    return {
      retainerAttachmentDeleteSuccess,
      isRetainerAttachmentDeleting,
      retainerAttachmentDeleteError,
    };
  },
);
