export function getRotation(value: number, maxValue: number) {
  if (value < 0 || maxValue === 0) return { transform: `rotate(0deg)` };
  if (value > maxValue) return { transform: `rotate(180deg)` };
  const rotationalIncrement = 180 / maxValue;
  const progressInRotation = value * rotationalIncrement;
  return {
    transform: `rotate(${progressInRotation}deg)`,
  };
}

export function getRelativeValue(
  value: number,
  maxValue: number,
  fieldDescriptor: "percentage" | "fraction",
) {
  if (maxValue === 0) return 0;
  if (fieldDescriptor === "percentage") {
    const result = (100 / maxValue) * value;
    return result % 1 === 0 ? result : Number(result.toFixed(2));
  }
  return value;
}

export function getColorBasedOnValues(value: number, maxValue: number) {
  if (value > maxValue / 1.25 && value < maxValue) return "orange";
  if (value >= maxValue) return "red";
  return "csis";
}

// returns the font size based on the size of the gauge
// and the total characters
export function getRelativeFontSize(size: "small" | "normal") {
  if (size === "normal") {
    return "f_huge";
  } else {
    return "f_large";
  }
}
