// Function to calculate and return the position for the modal
export const calculateModalPosition = (
  parentRef: React.RefObject<HTMLDivElement>,
) => {
  if (!parentRef.current) return { top: 0, left: 0, width: 0 };

  const rect = parentRef.current.getBoundingClientRect();
  return {
    top: rect.bottom + window.scrollY, // Position below the header or trigger element
    left: rect.left + window.scrollX,
    width: rect.width,
  };
};
