import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  DetailResponseCsrfToken,
  EmergencyResponseCaseAttachmentPreview,
  EmergencyResponseCaseAttachmentsPreviewDetailResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import {
  deleteEmergencyCaseAttachmentByIdAPI,
  fetchCsrfTokenApi,
  getEmergencyCaseAttachmentsAPI,
  getEmergencyCaseInfoAPI,
  setEmergencyCaseQuestionaryDataAPI,
  updateEmergencyCaseAttachmentsAPI,
} from "./api";
import {
  EmergencyCaseInfo,
  EmergencyCaseInfoResponse,
  EmergencyCaseQuestionaryUpdateBody,
} from "./types";

interface StateSlice {
  submissionCode: string;

  emergencyCaseInfo: EmergencyCaseInfo | null;
  isEmergencyCaseInfoSuccess: boolean;
  isEmergencyCaseInfoPending: boolean;
  emergencyCaseInfoError: string | null;

  isEmergencyCaseQuestionaryDataSuccess: boolean;
  isEmergencyCaseQuestionaryDataPending: boolean;
  emergencyCaseQuestionaryDataError: string | null;

  uploadedAttachments: EmergencyResponseCaseAttachmentPreview[];

  isEmergencyCaseAttachmentUpdating: boolean;
  emergencyCaseAttachmentUpdateError: string | null;
  emergencyCaseAttachmentUpdateSuccess: boolean;

  isEmergencyCaseAttachmentDeleting: boolean;
  emergencyCaseAttachmentDeleteError: string | null;
  emergencyCaseAttachmentDeleteSuccess: boolean;

  getEmergencyCaseAttachmentsPending: boolean;
  getEmergencyCaseAttachmentsError: string | null;
  getEmergencyCaseAttachmentsSuccess: boolean;
}
const initialState: StateSlice = {
  submissionCode: "",
  emergencyCaseInfo: null,
  isEmergencyCaseInfoSuccess: false,
  isEmergencyCaseInfoPending: false,
  emergencyCaseInfoError: null,

  isEmergencyCaseQuestionaryDataSuccess: false,
  isEmergencyCaseQuestionaryDataPending: false,
  emergencyCaseQuestionaryDataError: null,

  uploadedAttachments: [],

  isEmergencyCaseAttachmentUpdating: false,
  emergencyCaseAttachmentUpdateError: null,
  emergencyCaseAttachmentUpdateSuccess: false,

  isEmergencyCaseAttachmentDeleting: false,
  emergencyCaseAttachmentDeleteError: null,
  emergencyCaseAttachmentDeleteSuccess: false,

  getEmergencyCaseAttachmentsPending: false,
  getEmergencyCaseAttachmentsError: null,
  getEmergencyCaseAttachmentsSuccess: false,
};

const emergencyCaseConfirmationSlice = createSlice({
  name: "emergencyCaseConfirmation",
  initialState: initialState,
  reducers: {
    getEmergencyCaseInfo(
      state,
      action: PayloadAction<{
        caseId: string;
        code: string;
      }>,
    ) {
      state.submissionCode = action.payload.code;
    },
    setEmergencyCaseInfoPending(state) {
      state.isEmergencyCaseInfoPending = true;
      state.emergencyCaseInfoError = null;
    },
    setEmergencyCaseInfoSuccess(
      state,
      action: PayloadAction<EmergencyCaseInfo>,
    ) {
      state.isEmergencyCaseInfoPending = false;
      state.isEmergencyCaseInfoSuccess = true;
      state.emergencyCaseInfoError = null;
      state.emergencyCaseInfo = action.payload;
    },
    setEmergencyCaseInfoError(state, action: PayloadAction<string>) {
      state.isEmergencyCaseInfoPending = false;
      state.isEmergencyCaseInfoSuccess = false;
      state.emergencyCaseInfoError = action.payload;
    },
    setQuestionaryData(
      _state,
      _action: PayloadAction<{
        caseId: string;
        code: string;
        data: EmergencyCaseQuestionaryUpdateBody;
      }>,
    ) {
      //empty handled by saga
    },
    setQuestionaryDataPending(state) {
      state.isEmergencyCaseQuestionaryDataPending = true;
      state.emergencyCaseQuestionaryDataError = null;
    },
    setQuestionaryDataSuccess(state) {
      state.isEmergencyCaseQuestionaryDataPending = false;
      state.isEmergencyCaseQuestionaryDataSuccess = true;
      state.emergencyCaseQuestionaryDataError = null;
    },
    setQuestionaryDataError(state, action: PayloadAction<string>) {
      state.isEmergencyCaseQuestionaryDataPending = false;
      state.isEmergencyCaseQuestionaryDataSuccess = false;
      state.emergencyCaseQuestionaryDataError = action.payload;
    },
    deleteEmergencyCaseAttachmentById(
      _state,
      _action: PayloadAction<{
        caseId: string;
        attachmentId: string;
        submissionCode: string;
      }>,
    ) {
      //empty handled by saga
    },
    setDeleteEmergencyCaseAttachmentPending(state) {
      state.isEmergencyCaseAttachmentDeleting = true;
      state.emergencyCaseAttachmentDeleteError = null;
      state.emergencyCaseAttachmentDeleteSuccess = false;
    },
    setDeleteEmergencyCaseAttachmentError(
      state,
      action: PayloadAction<string>,
    ) {
      state.isEmergencyCaseAttachmentDeleting = false;
      state.emergencyCaseAttachmentDeleteError = action.payload;
    },
    setDeleteEmergencyCaseAttachmentSuccess(
      state,
      action: PayloadAction<string>,
    ) {
      state.isEmergencyCaseAttachmentDeleting = false;
      state.emergencyCaseAttachmentDeleteError = null;
      state.emergencyCaseAttachmentDeleteSuccess = true;
      state.uploadedAttachments = state.uploadedAttachments.filter(
        (item) => item.external_id !== action.payload,
      );
    },
    resetDeleteEmergencyCaseAttachmentState(state) {
      state.isEmergencyCaseAttachmentDeleting = false;
      state.emergencyCaseAttachmentDeleteError = null;
      state.emergencyCaseAttachmentDeleteSuccess = false;
    },
    updateEmergencyCaseAttachments(
      _state,
      _action: PayloadAction<{ caseId: string; files: File[] }>,
    ) {
      //empty handled by saga
    },
    setUpdateEmergencyCaseAttachmentsPending(state) {
      state.isEmergencyCaseAttachmentUpdating = true;
      state.emergencyCaseAttachmentUpdateError = null;
      state.emergencyCaseAttachmentUpdateSuccess = false;
    },
    setUpdateEmergencyCaseAttachmentsError(
      state,
      action: PayloadAction<string>,
    ) {
      state.isEmergencyCaseAttachmentUpdating = false;
      state.emergencyCaseAttachmentUpdateError = action.payload;
      state.emergencyCaseAttachmentUpdateSuccess = false;
    },
    setUpdateEmergencyCaseAttachmentsSuccess(
      state,
      action: PayloadAction<EmergencyResponseCaseAttachmentPreview[]>,
    ) {
      state.isEmergencyCaseAttachmentUpdating = false;
      state.emergencyCaseAttachmentUpdateError = null;
      state.emergencyCaseAttachmentUpdateSuccess = true;
      state.uploadedAttachments = [
        ...state.uploadedAttachments,
        ...action.payload,
      ];
    },
    resetUpdateEmergencyCaseAttachmentsState(state) {
      state.isEmergencyCaseAttachmentUpdating = false;
      state.emergencyCaseAttachmentUpdateError = null;
      state.emergencyCaseAttachmentUpdateSuccess = false;
    },
    downloadAttachment(
      _state,
      _action: PayloadAction<{
        emergencyCaseId: string;
        attachmentId: string;
        filename: string;
      }>,
    ) {
      //empty handled by saga
    },
    getEmergencyCaseAttachments(
      _state,
      _action: PayloadAction<{ caseId: string; submissionCode: string }>,
    ) {
      //empty handled by saga
    },
    getEmergencyCaseAttachmentsPending(state) {
      state.getEmergencyCaseAttachmentsPending = true;
      state.getEmergencyCaseAttachmentsError = null;
      state.getEmergencyCaseAttachmentsSuccess = false;
    },
    getEmergencyCaseAttachmentsSuccess(
      state,
      action: PayloadAction<EmergencyResponseCaseAttachmentPreview[]>,
    ) {
      state.getEmergencyCaseAttachmentsPending = false;
      state.getEmergencyCaseAttachmentsSuccess = true;
      state.uploadedAttachments = action.payload;
    },
    getEmergencyCaseAttachmentsError(state, action: PayloadAction<string>) {
      state.getEmergencyCaseAttachmentsPending = false;
      state.getEmergencyCaseAttachmentsError = action.payload;
      state.getEmergencyCaseAttachmentsSuccess = false;
    },
  },
});

export default emergencyCaseConfirmationSlice.reducer;

export const {
  getEmergencyCaseInfo,
  setEmergencyCaseInfoPending,
  setEmergencyCaseInfoSuccess,
  setEmergencyCaseInfoError,
  setQuestionaryData,
  setQuestionaryDataPending,
  setQuestionaryDataSuccess,
  setQuestionaryDataError,
  deleteEmergencyCaseAttachmentById,
  setDeleteEmergencyCaseAttachmentPending,
  setDeleteEmergencyCaseAttachmentError,
  setDeleteEmergencyCaseAttachmentSuccess,
  resetDeleteEmergencyCaseAttachmentState,
  updateEmergencyCaseAttachments,
  setUpdateEmergencyCaseAttachmentsPending,
  setUpdateEmergencyCaseAttachmentsError,
  setUpdateEmergencyCaseAttachmentsSuccess,
  resetUpdateEmergencyCaseAttachmentsState,
  downloadAttachment,
  getEmergencyCaseAttachments,
  getEmergencyCaseAttachmentsPending,
  getEmergencyCaseAttachmentsSuccess,
  getEmergencyCaseAttachmentsError,
} = emergencyCaseConfirmationSlice.actions;

function* getEmergencyCaseInfoSaga(
  action: PayloadAction<{
    caseId: string;
    code: string;
  }>,
) {
  try {
    yield put(setEmergencyCaseInfoPending());
    const response: AxiosResponse<EmergencyCaseInfoResponse> = yield call(
      getEmergencyCaseInfoAPI,
      action.payload.caseId,
      action.payload.code,
    );
    yield put(setEmergencyCaseInfoSuccess(response.data.payload));
  } catch (error) {
    const errorMessage = handleRequestError(error);
    yield put(setEmergencyCaseInfoError(errorMessage));
  }
}

function* setQuestionaryDataSaga(
  action: PayloadAction<{
    caseId: string;
    code: string;
    data: EmergencyCaseQuestionaryUpdateBody;
  }>,
) {
  try {
    yield put(setQuestionaryDataPending());

    const tokenResponse: AxiosResponse<DetailResponseCsrfToken> =
      yield call(fetchCsrfTokenApi);
    const csrfToken = tokenResponse.data.payload.csrf_token;

    yield call(
      setEmergencyCaseQuestionaryDataAPI,
      action.payload.caseId,
      action.payload.code,
      action.payload.data,
      csrfToken,
    );
    yield put(setQuestionaryDataSuccess());
    yield put(
      getEmergencyCaseInfo({
        caseId: action.payload.caseId,
        code: action.payload.code,
      }),
    );
  } catch (error) {
    const errorMessage = handleRequestError(error);
    yield put(setQuestionaryDataError(errorMessage));
  }
}

function* updateEmergencyCaseAttachmentSaga(
  action: PayloadAction<{
    caseId: string;
    submissionCode: string;
    files: File[];
  }>,
) {
  yield put(setUpdateEmergencyCaseAttachmentsPending());
  try {
    const response: AxiosResponse<any> = yield call(
      updateEmergencyCaseAttachmentsAPI,
      action.payload.caseId,
      action.payload.submissionCode,
      action.payload.files,
    );
    yield put(setUpdateEmergencyCaseAttachmentsSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setUpdateEmergencyCaseAttachmentsError(errorMessage));
  }
}

function* getEmergencyCaseAttachmentsSaga(
  action: PayloadAction<{ caseId: string; submissionCode: string }>,
) {
  yield put(getEmergencyCaseAttachmentsPending());
  try {
    const tokenResponse: AxiosResponse<DetailResponseCsrfToken> =
      yield call(fetchCsrfTokenApi);
    const csrfToken = tokenResponse.data.payload.csrf_token;

    const response: AxiosResponse<EmergencyResponseCaseAttachmentsPreviewDetailResponse> =
      yield call(
        getEmergencyCaseAttachmentsAPI,
        action.payload.caseId,
        action.payload.submissionCode,
        csrfToken,
      );
    yield put(getEmergencyCaseAttachmentsSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(getEmergencyCaseAttachmentsError(errorMessage));
  }
}

function* deleteEmergencyCaseAttachmentSaga(
  action: PayloadAction<{
    caseId: string;
    submissionCode: string;
    attachmentId: string;
  }>,
) {
  yield put(setDeleteEmergencyCaseAttachmentPending());
  try {
    // before the request, get the csrf token so it's also included in the request
    const tokenResponse: AxiosResponse<DetailResponseCsrfToken> =
      yield call(fetchCsrfTokenApi);
    const csrfToken = tokenResponse.data.payload.csrf_token;

    yield call(
      deleteEmergencyCaseAttachmentByIdAPI,
      action.payload.caseId,
      action.payload.attachmentId,
      action.payload.submissionCode,
      csrfToken,
    );
    yield put(
      setDeleteEmergencyCaseAttachmentSuccess(action.payload.attachmentId),
    );
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setDeleteEmergencyCaseAttachmentError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(getEmergencyCaseInfo.toString(), getEmergencyCaseInfoSaga);
  yield takeLatest(setQuestionaryData.toString(), setQuestionaryDataSaga);
  yield takeLatest(
    deleteEmergencyCaseAttachmentById.toString(),
    deleteEmergencyCaseAttachmentSaga,
  );
  yield takeLatest(
    updateEmergencyCaseAttachments.toString(),
    updateEmergencyCaseAttachmentSaga,
  );

  yield takeLatest(
    getEmergencyCaseAttachments.toString(),
    getEmergencyCaseAttachmentsSaga,
  );
}

export function* EmergencyCaseConfirmationSagas() {
  yield all([actionWatcher()]);
}
