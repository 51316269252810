import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface StateSlice<T> {
  data: T | null;
  isDataPending: boolean;
  dataFetchError: string | null;
  hasNextPage: boolean;
}

export function createAsyncObjectFetchSlice<T>(name: string) {
  const initialState: StateSlice<T> = {
    data: null,
    isDataPending: false,
    dataFetchError: null,
    hasNextPage: false,
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      fetchData(state, _action: PayloadAction<string>) {
        //empty handled by saga
        state.data = null;
      },
      setIsPending(state) {
        state.isDataPending = true;
        state.dataFetchError = null;
      },
      setFetchError(state, action: PayloadAction<string | undefined>) {
        state.isDataPending = false;
        state.dataFetchError = action.payload || "Something went wrong";
      },
      setFetchSuccess(state, action: PayloadAction<T>) {
        state.isDataPending = false;
        state.data = action.payload as Draft<T>;
        state.dataFetchError = null;
      },
    },
  });
}
