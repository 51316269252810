import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Dropdown,
  Form,
  FormLabel,
  GenericFormError,
  Icon,
  InlineFormError,
  Input,
  InputFile,
  Stack,
} from "@csis.com/components";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { TicketCreateBody } from "../../../api/openapi/data-contracts";
import BasicDialogButtons from "../../../components/Form/BasicDialogButtons/BasicDialogButtons";
import { Optional } from "../../../models/helpers";
import { ticketsKeys } from "../TicketsSearch/constants";
import { SeverityTranslationKeys, SeverityValues } from "../models/Severity";
import {
  ticketTypeTranslationKeys,
  ticketTypeValues,
} from "../models/TicketType";
import {
  getCreateTicketResult,
  getUploadedAttachmentsResult,
} from "./selector";
import {
  createTicket,
  postAttachment,
  removeAttachmentById,
  resetState,
} from "./slice";

type FormError = Partial<{
  [ticketsKeys.SEVERITY]: string;
  [ticketsKeys.TYPE]: string;
}>;

export const NewTicketDialog = ({
  defaultTitle,
  attachedAlertId,
  attachedPaymentCardReportId,
  attachedVictimReportId,
  setNewTicketDialogShown,
  onSuccess,
}: {
  defaultTitle?: string;
  attachedAlertId?: string;
  attachedPaymentCardReportId?: string;
  attachedVictimReportId?: string;
  onSuccess?: () => void;
  setNewTicketDialogShown: (val: boolean) => void;
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();

  const { isPending, postError, postSuccess } = useSelector(
    getCreateTicketResult,
  );

  const {
    uploadedAttachments,
    isAttachmentUploadPending,
    attachmentUploadError,
  } = useSelector(getUploadedAttachmentsResult);

  type TicketCreateBodyDraft = Optional<
    TicketCreateBody,
    "title" | "description" | "type"
  >;

  // this is just the state that we track any changes
  const [newTicketDraft, setNewTicketDraft] = useState<TicketCreateBodyDraft>({
    [ticketsKeys.TITLE]: defaultTitle,
    [ticketsKeys.SEVERITY]: SeverityValues.NA,
    [ticketsKeys.TICKET_ATTACHMENTS]: [],
    [ticketsKeys.ATTACHED_ALERT]: attachedAlertId,
    [ticketsKeys.ATTACHED_VICTIMS_REPORT]: attachedVictimReportId,
    [ticketsKeys.ATTACHED_PAYMENT_CARD_REPORT]: attachedPaymentCardReportId,
  });

  const [formErrors, setFormErrors] = useState<FormError>({});

  const handleChangeTicketField = (newValue: unknown, fieldKey: string) => {
    setNewTicketDraft((prevState) => ({
      ...prevState,
      [fieldKey]: newValue,
    }));
  };

  const handleFileUpload = async (files: FileList) => {
    dispatch(postAttachment(Array.from(files)));
  };

  const handleFileRemove = (id: string) => {
    dispatch(removeAttachmentById(id));
  };

  const checkForFormClientSideErrors = () => {
    let hasFormErrors = false;
    const newErrors: FormError = {};

    if (newTicketDraft[ticketsKeys.TYPE] === undefined) {
      hasFormErrors = true;
      newErrors[ticketsKeys.TYPE] = "Please specify ticket type";
    }

    setFormErrors(newErrors);
    return hasFormErrors;
  };

  const handleCreateTicket = () => {
    if (!checkForFormClientSideErrors()) {
      dispatch(createTicket({ newTicket: newTicketDraft as TicketCreateBody }));
    }
  };

  useEffect(() => {
    if (postSuccess && onSuccess) {
      onSuccess();
    }
  }, [onSuccess, postSuccess]);

  useEffect(() => {
    // runs when component unmounts
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  return (
    <Dialog
      name="new-ticket-dialog"
      header={"Add new ticket"}
      onClose={() => setNewTicketDialogShown(false)}
      dataTestId="new-ticket-dlg"
      isModal
    >
      <Form id="new-ticket-form" onFormSubmit={handleCreateTicket}>
        <Stack isVertical gutterSize="large" align="stretch">
          <Stack isVertical>
            <FormLabel text="Title" htmlFor={ticketsKeys.TITLE} />
            <Input
              name={ticketsKeys.TITLE}
              type="text"
              isFullWidth
              placeholder={t("ticket_title")}
              value={newTicketDraft.title}
              onChange={handleChangeTicketField}
              dataTestId="new-ticket-title-input"
            />
          </Stack>
          <Stack isVertical>
            <FormLabel text="Type" htmlFor={ticketsKeys.TYPE} />
            <Dropdown
              name={ticketsKeys.TYPE}
              headerTitle={"Select ticket type"}
              value={newTicketDraft?.type}
              isFullWidth
              type="shadow"
              options={Object.values(ticketTypeValues).map((ticketType) => ({
                value: ticketType,
                label: t(ticketTypeTranslationKeys[ticketType]),
                isIdented: ticketType.includes("."),
              }))}
              onSelect={handleChangeTicketField}
              isUppercase={false}
              dataTestId="new-ticket-type-dropdown"
            />
            <InlineFormError errorText={formErrors?.[ticketsKeys.TYPE]} />
          </Stack>
          <Stack isVertical>
            <FormLabel text="Severity" htmlFor={ticketsKeys.SEVERITY} />
            <Dropdown
              name={ticketsKeys.SEVERITY}
              headerTitle={"Select ticket severity"}
              value={newTicketDraft?.severity}
              isFullWidth
              type="shadow"
              options={Object.values(SeverityValues).map((severity) => ({
                value: severity,
                label: t(SeverityTranslationKeys[severity]),
              }))}
              onSelect={handleChangeTicketField}
              isUppercase={false}
              dataTestId="new-ticket-severity-dropdown"
            />
            <InlineFormError errorText={formErrors?.[ticketsKeys.SEVERITY]} />
          </Stack>
          <Stack isVertical>
            <FormLabel text="Description" htmlFor={ticketsKeys.DESCRIPTION} />
            <Input
              type="textarea"
              isFullWidth
              name={ticketsKeys.DESCRIPTION}
              placeholder={t("description_3dots")}
              isOptional
              value={newTicketDraft.description}
              onChange={handleChangeTicketField}
              dataTestId="new-ticket-desc-input"
            />
          </Stack>
          <Stack isVertical>
            <Stack align="center">
              <FormLabel
                text="Internal Reference"
                htmlFor={ticketsKeys.CUSTOMER_REF}
              />
              <Icon
                kind="help_filled"
                size="big"
                color="secondary"
                tooltipText="This field can store a reference ID for this case in another ticketing system"
              />
            </Stack>
            <Input
              name={ticketsKeys.CUSTOMER_REF}
              placeholder={t("internal_reference")}
              isOptional
              isFullWidth
              value={newTicketDraft.customer_reference}
              onChange={handleChangeTicketField}
              dataTestId="new-ticket-customer-reference-input"
            />
          </Stack>
          <Stack isVertical>
            <FormLabel
              text="Attachments (optional)"
              htmlFor="new ticket file"
            />
            <InputFile
              name="new ticket file"
              onFilesUpload={handleFileUpload}
              maxFileSize={8}
              files={uploadedAttachments}
              onFileRemove={handleFileRemove}
              errorText={attachmentUploadError}
              isPending={isAttachmentUploadPending}
            />
          </Stack>

          {postError && <GenericFormError errorText={postError} />}

          <BasicDialogButtons
            onCancel={() => setNewTicketDialogShown(false)}
            onSubmit={() => {}}
            formId={"new-ticket-form"}
            submitActionText={"Add ticket"}
            submitActionDataTestId="new-ticket-dlg-add-btn"
            isSubmitActionPending={isPending}
          />
        </Stack>
      </Form>
    </Dialog>
  );
};
