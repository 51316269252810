import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { DetailResponseCsrfToken } from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { fetchCsrfTokenApi, resetPasswordPostApi } from "./api";

interface StateSlice {
  isSuccess: boolean;
  isPasswordResetPending: boolean;
  passwordResetError: string | null;
}
const initialState: StateSlice = {
  isSuccess: false,
  isPasswordResetPending: false,
  passwordResetError: null,
};

const passwordResetSlice = createSlice({
  name: "passwordReset",
  initialState: initialState,
  reducers: {
    postPasswordReset(
      _state,
      _action: PayloadAction<{
        newPassword: string;
        resetPasswordToken?: string;
      }>,
    ) {
      //empty handled by saga
    },
    setPasswordResetPending(state) {
      state.isPasswordResetPending = true;
      state.passwordResetError = null;
    },
    setPasswordResetSuccess(state) {
      state.isPasswordResetPending = false;
      state.isSuccess = true;
      state.passwordResetError = null;
    },
    setPasswordResetError(state, action: PayloadAction<string>) {
      state.isPasswordResetPending = false;
      state.isSuccess = false;
      state.passwordResetError = action.payload;
    },
  },
});

export default passwordResetSlice.reducer;

export const {
  postPasswordReset,
  setPasswordResetPending,
  setPasswordResetSuccess,
  setPasswordResetError,
} = passwordResetSlice.actions;

// Async stuff - sagas
export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

function* postPasswordResetSaga(
  action: PayloadAction<{
    newPassword: string;
    resetPasswordToken?: string;
  }>,
) {
  yield put(setPasswordResetPending());

  try {
    // before the request, get the csrf token so it's also included in the request
    const response: AxiosResponse<DetailResponseCsrfToken> =
      yield call(fetchCsrfTokenApi);
    const csrfToken = response.data.payload.csrf_token;

    yield call(resetPasswordPostApi, action.payload, csrfToken);

    yield put(setPasswordResetSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setPasswordResetError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(postPasswordReset.toString(), postPasswordResetSaga);
}

export function* passwordResetSagas() {
  yield all([actionWatcher()]);
}
