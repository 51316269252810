import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Carousel, Table } from "@csis.com/components";
import { OnRowClick } from "@csis.com/components/src/atoms/Table/types";
import urlTemplates from "@csis.com/tip/src/routes/urlTemplates";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import { getSelectedOrgId } from "../../../Profile/Security/selectors";
import { CardsColumns } from "../../CompromisedData/CreditCards/Search/Table/columns";
import {
  creditCardCell,
  nameCell,
  sourceCell,
} from "../../CompromisedData/CreditCards/Search/Table/tableCellFormatters";
import { ccKeys } from "../../CompromisedData/CreditCards/Search/constants";
import { getCompromisedDataResult } from "../../CompromisedData/CreditCards/Search/selectors";
import { fetchCompromisedData } from "../../CompromisedData/CreditCards/Search/slice";
import { CompromisedDataRow } from "../../CompromisedData/CreditCards/Search/types";
import { StatusPieChart } from "../../CompromisedData/CreditCards/Statistics/Status/StatusPieChart";
import { getStatusDistributionResult } from "../../CompromisedData/CreditCards/Statistics/Status/selectors";
import { fetchStatusDistribution } from "../../CompromisedData/CreditCards/Statistics/Status/slice";
import { StatusValues } from "../../CompromisedData/CreditCards/models/Status";
import ProductCard from "./ProductCard/ProductCard";
import { ELEMENTS_PER_TABLE, REQUEST_DELAY_MS } from "./constants";

const columns: CardsColumns = [
  { key: ccKeys.CARD_NUMBER, name: "card_number" },
  { key: ccKeys.NAME, name: "name" },
  { key: ccKeys.SOURCE, name: "source" },
];

const slidesInfo = [
  {
    subtitle: "Status Distributions",
    viewAllUrl: urls.products_compromiseddata_cc_statistics,
  },
  {
    subtitle: "Latest Payment Cards",
    viewAllUrl: urls.products_compromiseddata_cc_search,
  },
];

export default function CardsProductCard({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedOrgId = useSelector(getSelectedOrgId);

  const [requestDelay, setRequestDelay] = useState<number>(REQUEST_DELAY_MS);
  const { t } = useTranslations();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  function handleCarouselChange(newSlideIndex: number) {
    setCurrentSlideIndex(newSlideIndex);
  }

  useEffect(() => {
    dispatch(
      fetchStatusDistribution({
        [ccKeys.SUBMITTED_AFTER]: startDate,
        [ccKeys.SUBMITTED_BEFORE]: endDate,
      }),
    );
    const timer = setTimeout(() => {
      dispatch(
        fetchCompromisedData({
          [ccKeys.START_DATE]: startDate,
          [ccKeys.END_DATE]: endDate,
        }),
      );
      setRequestDelay(0);
    }, requestDelay);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDate, startDate, selectedOrgId]);

  const {
    compromisedData,
    isCompromisedDataPending,
    compromisedDataFetchError,
  } = useSelector(getCompromisedDataResult);

  const handleRowClick: OnRowClick<CompromisedDataRow> = (row) => {
    history.push(urlTemplates._products_compromiseddata_cc_report_id(row.id));
  };

  const formatters = useMemo(
    () => ({
      [ccKeys.CARD_NUMBER]: creditCardCell(),
      [ccKeys.NAME]: nameCell(),
      [ccKeys.SOURCE]: sourceCell(),
    }),
    [],
  );

  const { statusDistributions, isStatusDistributionPending } = useSelector(
    getStatusDistributionResult,
  );

  const totalNewReports =
    statusDistributions?.find((element) => element.name === StatusValues.NEW)
      ?.count || 0;

  const newReportsUrl = getNewLocationUrl(
    urls.products_compromiseddata_cc_search,
    {
      [ccKeys.STATUS]: [StatusValues.NEW],
      [ccKeys.START_DATE]: startDate,
      [ccKeys.END_DATE]: endDate,
    },
  );

  return (
    <ProductCard dataTestId="cards-product-card">
      <ProductCard.Header
        title={t("compromised_cards")}
        subTitle={slidesInfo[currentSlideIndex]?.subtitle}
        viewAllUrl={slidesInfo[currentSlideIndex]?.viewAllUrl}
      />
      <ProductCard.Carousel>
        <Carousel
          onChange={handleCarouselChange}
          name="cards-carousel"
          hasAutoplay
          isLoading={isStatusDistributionPending}
          items={[
            <StatusPieChart startDate={startDate} endDate={endDate} />,
            <Table
              name={"compdata-productcard-table"}
              columns={columns}
              rows={compromisedData?.slice(0, ELEMENTS_PER_TABLE)}
              isLoading={isCompromisedDataPending}
              error={compromisedDataFetchError}
              ghostRows={ELEMENTS_PER_TABLE}
              isFullWidth
              hasUnlimitedCellWidth
              size="small"
              columnNameTransform={t}
              onRowClick={handleRowClick}
              formatters={formatters}
            />,
          ]}
        />
      </ProductCard.Carousel>
      <ProductCard.Footer
        text={`${totalNewReports} ${t("new_reports")}`}
        url={newReportsUrl}
      />
    </ProductCard>
  );
}
