import urlTemplates from "./urlTemplates";

// all valid platform Urls should be defined here
// use camel_case for slashes
const urls = {
  resetpassword: "/reset-password",
  resetpassword_email: "/reset-password-email",

  // ** Products **
  products: "/products",

  // ** Global Search **
  globalsearch: "/global-search",

  // ** Emergency Response Retainer **
  products_emergencyresponse: "/products/emergency-response",
  products_emergencyresponse_retainer: "/products/emergency-response/retainer",

  // ** Threat Insights **
  products_threatinsights: "/products/threat-insights",
  products_threatinsights_article: "/products/threat-insights/article",
  products_threatinsights_article_id:
    urlTemplates._products_threatinsights_article_id(":id"),

  // ** Phishing **
  products_phishing: "/products/anti-phishing",
  products_phishing_urls: "/products/anti-phishing/urls",
  products_phishing_urls_url: "/products/anti-phishing/urls/url",
  products_phishing_urls_url_id:
    urlTemplates._products_phishing_urls_url_id(":id"),
  products_phishing_statistics: "/products/anti-phishing/statistics",
  products_phishing_usage: "/products/anti-phishing/usage",

  // ** Compromised data **
  products_compromiseddata_cc: "/products/payment-cards-reports",
  products_compromiseddata_cc_search: "/products/payment-cards-reports/search",
  products_compromiseddata_cc_statistics:
    "/products/payment-cards-reports/statistics",
  products_compromiseddata_cc_report: "/products/payment-cards-reports/report",
  products_compromiseddata_cc_report_id:
    urlTemplates._products_compromiseddata_cc_report_id(":id"),

  products_compromiseddata_victims: "/products/credential-reports",
  products_compromiseddata_victims_search:
    "/products/credential-reports/search",
  products_compromiseddata_victims_statistics:
    "/products/credential-reports/statistics",
  products_compromiseddata_victims_report:
    "/products/credential-reports/report",
  products_compromiseddata_victims_report_id:
    urlTemplates._products_compromiseddata_victims_report_id(":id"),

  // ** Investigations **
  products_investigations: "/products/investigations",
  products_investigations_search: "/products/investigations/search",
  products_investigations_threatcloud: "/products/investigations/threat-cloud",
  products_investigations_statistics: "/products/investigations/statistics",

  // ** Alerts **
  alerts: "/alerts",
  alerts_statistics: "/alerts/statistics",
  alerts_alert: "/alerts/alert",
  alerts_alert_id: urlTemplates._alerts_alert_id(":id"),
  alerts_search: "/alerts/search",

  // ** Tickets **
  tickets: "/tickets",
  tickets_search: "/tickets/search",
  tickets_ticket: "/tickets/ticket",
  tickets_ticket_id: urlTemplates._tickets_ticket_id(":id"),
  tickets_statistics: "/tickets/statistics",

  // ** Reports **
  reports: "/reports",

  // ** Retainers **
  ir_retainers_search: "/ir/retainers/search",
  ir_retainers_retainer_id: urlTemplates._ir_retainers_retainer_id(":id"),

  // ** Emergency Case Confirmation **
  emergency_case_confirmation_id:
    urlTemplates._emergency_case_confirmation_id(":id"),

  // ** Emergency Cases **
  ir_cases: "/ir/cases",
  ir_cases_search: "/ir/cases/search",
  ir_cases_case_id: urlTemplates._ir_case_id(":id"),

  // ** Help support **
  helpandsupport: "/help-and-support",
  helpandsupport_helpcenter: "/help-and-support/help-center",
  helpandsupport_portalmanual: "/help-and-support/portal-manual",
  helpandsupport_apidocumentation: "/help-and-support/api-documentation",
  helpandsupport_mdr: "/help-and-support/mdr",

  // ** Settings **
  settings_account: "/settings/account",
  settings_account_general: "/settings/account/general",
  settings_account_notifications: "/settings/account/notifications",

  // ** Organizations **
  organizations_customers_search: "/organizations/customers/search",
  organizations_customers_dashboard: "/organizations/customers/dashboard",
  organizations_customers_users: "/organizations/customers/users",

  organizations_administration_id:
    urlTemplates._organizations_administration_id(":id"),
  organizations_administration_id_auditlog:
    urlTemplates._organizations_administration_id_auditlog(":id"),
  organizations_administration_id_users:
    urlTemplates._organizations_administration_id_users(":id"),
  organizations_administration_id_access:
    urlTemplates._organizations_administration_id_access(":id"),
  organizations_administration_id_services:
    urlTemplates._organizations_administration_id_services(":id"),
  organizations_administration_id_health:
    urlTemplates._organizations_administration_id_health(":id"),

  // ** Administation **
  administration: "/administration",
  administration_auditlog: "/administration/auditlog",
  administration_users: "/administration/users",
  administration_access: "/administration/access",

  logout: "/logout",

  // ** MDR **
  // Onboarding
  onboarding_mdfecustomer: "/onboarding/mdfe-customer",
  onboarding_mdfecustomer_id: urlTemplates._onboarding_mdfecustomer_id(":id"),

  // ** Tiger team **
  // Onboarding Questionnaire
  onboarding_tigerteam: "/onboarding/tt",
} as const;

// extract the urls as a union type so it returns "/products" | "/products/efp" ...
export type AllowedTipUrl = (typeof urls)[keyof typeof urls];

export default urls;
