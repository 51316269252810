import { getCountryNameFromCountryCode } from "./getCountryNameFromCountryCode";

export const countries = [
  //dk, uk, us first
  { country_code: "DK" },
  { country_code: "US" },
  { country_code: "GB" },

  { country_code: "AD" },
  { country_code: "AE" },
  { country_code: "AF" },
  { country_code: "AI" },
  { country_code: "AP" },
  { country_code: "AR" },
  { country_code: "AT" },
  { country_code: "AU" },
  { country_code: "AZ" },
  { country_code: "BA" },
  { country_code: "BD" },
  { country_code: "BE" },
  { country_code: "BG" },
  { country_code: "BO" },
  { country_code: "BR" },
  { country_code: "BT" },
  { country_code: "BW" },
  { country_code: "BY" },
  { country_code: "BZ" },
  { country_code: "CA" },
  { country_code: "CH" },
  { country_code: "CL" },
  { country_code: "CN" },
  { country_code: "CO" },
  { country_code: "CR" },
  { country_code: "CY" },
  { country_code: "CZ" },
  { country_code: "DE" },

  { country_code: "DO" },
  { country_code: "EC" },
  { country_code: "EE" },
  { country_code: "EG" },
  { country_code: "ES" },
  { country_code: "EU" },
  { country_code: "FI" },
  { country_code: "FR" },

  { country_code: "GE" },
  { country_code: "GG" },
  { country_code: "GM" },
  { country_code: "GR" },
  { country_code: "HK" },
  { country_code: "HN" },
  { country_code: "HR" },
  { country_code: "HU" },
  { country_code: "ID" },
  { country_code: "IE" },
  { country_code: "IL" },
  { country_code: "IN" },
  { country_code: "IQ" },
  { country_code: "IR" },
  { country_code: "IS" },
  { country_code: "IT" },
  { country_code: "JM" },
  { country_code: "JP" },
  { country_code: "KE" },
  { country_code: "KH" },
  { country_code: "KR" },
  { country_code: "KZ" },
  { country_code: "LA" },
  { country_code: "LC" },
  { country_code: "LT" },
  { country_code: "LU" },
  { country_code: "LV" },
  { country_code: "MD" },
  { country_code: "MM" },
  { country_code: "MU" },
  { country_code: "MX" },
  { country_code: "MY" },
  { country_code: "NE" },
  { country_code: "NG" },
  { country_code: "NL" },
  { country_code: "NO" },
  { country_code: "NP" },
  { country_code: "NZ" },
  { country_code: "PA" },
  { country_code: "PE" },
  { country_code: "PH" },
  { country_code: "PK" },
  { country_code: "PL" },
  { country_code: "PR" },
  { country_code: "PT" },
  { country_code: "PY" },
  { country_code: "QA" },
  { country_code: "RO" },
  { country_code: "RS" },
  { country_code: "RU" },
  { country_code: "RW" },
  { country_code: "SA" },
  { country_code: "SC" },
  { country_code: "SE" },
  { country_code: "SG" },
  { country_code: "SI" },
  { country_code: "SK" },
  { country_code: "SN" },
  { country_code: "SZ" },
  { country_code: "TH" },
  { country_code: "TM" },
  { country_code: "TN" },
  { country_code: "TR" },
  { country_code: "TW" },
  { country_code: "UA" },
  { country_code: "UG" },

  { country_code: "UY" },
  { country_code: "UZ" },
  { country_code: "VA" },
  { country_code: "VE" },
  { country_code: "VG" },
  { country_code: "VN" },
  { country_code: "WS" },
  { country_code: "YE" },
  { country_code: "ZA" },
  { country_code: "ZW" },
];

// Do not use the emojis if possible as they have issues in windows!
// this function should be removed once we can pass react elements(svg) as options to the Dropdown options
// Use getFlagSpanIcon or getFlagSpan below
export function getFlagEmoji(
  countryCode: string,
  countryFlagData: { [key: string]: string } = {
    AD: "🇦🇩",
    AE: "🇦🇪",
    AF: "🇦🇫",
    AG: "🇦🇬",
    AI: "🇦🇮",
    AL: "🇦🇱",
    AM: "🇦🇲",
    AO: "🇦🇴",
    AQ: "🇦🇶",
    AR: "🇦🇷",
    AS: "🇦🇸",
    AT: "🇦🇹",
    AU: "🇦🇺",
    AW: "🇦🇼",
    AX: "🇦🇽",
    AZ: "🇦🇿",
    BA: "🇧🇦",
    BB: "🇧🇧",
    BD: "🇧🇩",
    BE: "🇧🇪",
    BF: "🇧🇫",
    BG: "🇧🇬",
    BH: "🇧🇭",
    BI: "🇧🇮",
    BJ: "🇧🇯",
    BL: "🇧🇱",
    BM: "🇧🇲",
    BN: "🇧🇳",
    BO: "🇧🇴",
    BQ: "🇧🇶",
    BR: "🇧🇷",
    BS: "🇧🇸",
    BT: "🇧🇹",
    BV: "🇧🇻",
    BW: "🇧🇼",
    BY: "🇧🇾",
    BZ: "🇧🇿",
    CA: "🇨🇦",
    CC: "🇨🇨",
    CD: "🇨🇩",
    CF: "🇨🇫",
    CG: "🇨🇬",
    CH: "🇨🇭",
    CI: "🇨🇮",
    CK: "🇨🇰",
    CL: "🇨🇱",
    CM: "🇨🇲",
    CN: "🇨🇳",
    CO: "🇨🇴",
    CR: "🇨🇷",
    CU: "🇨🇺",
    CV: "🇨🇻",
    CW: "🇨🇼",
    CX: "🇨🇽",
    CY: "🇨🇾",
    CZ: "🇨🇿",
    DE: "🇩🇪",
    DJ: "🇩🇯",
    DK: "🇩🇰",
    DM: "🇩🇲",
    DO: "🇩🇴",
    DZ: "🇩🇿",
    EC: "🇪🇨",
    EE: "🇪🇪",
    EG: "🇪🇬",
    EH: "🇪🇭",
    ER: "🇪🇷",
    ES: "🇪🇸",
    ET: "🇪🇹",
    EU: "🇪🇺",
    FI: "🇫🇮",
    FJ: "🇫🇯",
    FK: "🇫🇰",
    FM: "🇫🇲",
    FO: "🇫🇴",
    FR: "🇫🇷",
    GA: "🇬🇦",
    GB: "🇬🇧",
    GD: "🇬🇩",
    GE: "🇬🇪",
    GF: "🇬🇫",
    GG: "🇬🇬",
    GH: "🇬🇭",
    GI: "🇬🇮",
    GL: "🇬🇱",
    GM: "🇬🇲",
    GN: "🇬🇳",
    GP: "🇬🇵",
    GQ: "🇬🇶",
    GR: "🇬🇷",
    GS: "🇬🇸",
    GT: "🇬🇹",
    GU: "🇬🇺",
    GW: "🇬🇼",
    GY: "🇬🇾",
    HK: "🇭🇰",
    HM: "🇭🇲",
    HN: "🇭🇳",
    HR: "🇭🇷",
    HT: "🇭🇹",
    HU: "🇭🇺",
    ID: "🇮🇩",
    IE: "🇮🇪",
    IL: "🇮🇱",
    IM: "🇮🇲",
    IN: "🇮🇳",
    IO: "🇮🇴",
    IQ: "🇮🇶",
    IR: "🇮🇷",
    IS: "🇮🇸",
    IT: "🇮🇹",
    JE: "🇯🇪",
    JM: "🇯🇲",
    JO: "🇯🇴",
    JP: "🇯🇵",
    KE: "🇰🇪",
    KG: "🇰🇬",
    KH: "🇰🇭",
    KI: "🇰🇮",
    KM: "🇰🇲",
    KN: "🇰🇳",
    KP: "🇰🇵",
    KR: "🇰🇷",
    KW: "🇰🇼",
    KY: "🇰🇾",
    KZ: "🇰🇿",
    LA: "🇱🇦",
    LB: "🇱🇧",
    LC: "🇱🇨",
    LI: "🇱🇮",
    LK: "🇱🇰",
    LR: "🇱🇷",
    LS: "🇱🇸",
    LT: "🇱🇹",
    LU: "🇱🇺",
    LV: "🇱🇻",
    LY: "🇱🇾",
    MA: "🇲🇦",
    MC: "🇲🇨",
    MD: "🇲🇩",
    ME: "🇲🇪",
    MF: "🇲🇫",
    MG: "🇲🇬",
    MH: "🇲🇭",
    MK: "🇲🇰",
    ML: "🇲🇱",
    MM: "🇲🇲",
    MN: "🇲🇳",
    MO: "🇲🇴",
    MP: "🇲🇵",
    MQ: "🇲🇶",
    MR: "🇲🇷",
    MS: "🇲🇸",
    MT: "🇲🇹",
    MU: "🇲🇺",
    MV: "🇲🇻",
    MW: "🇲🇼",
    MX: "🇲🇽",
    MY: "🇲🇾",
    MZ: "🇲🇿",
    NA: "🇳🇦",
    NC: "🇳🇨",
    NE: "🇳🇪",
    NF: "🇳🇫",
    NG: "🇳🇬",
    NI: "🇳🇮",
    NL: "🇳🇱",
    NO: "🇳🇴",
    NP: "🇳🇵",
    NR: "🇳🇷",
    NU: "🇳🇺",
    NZ: "🇳🇿",
    OM: "🇴🇲",
    PA: "🇵🇦",
    PE: "🇵🇪",
    PF: "🇵🇫",
    PG: "🇵🇬",
    PH: "🇵🇭",
    PK: "🇵🇰",
    PL: "🇵🇱",
    PM: "🇵🇲",
    PN: "🇵🇳",
    PR: "🇵🇷",
    PS: "🇵🇸",
    PT: "🇵🇹",
    PW: "🇵🇼",
    PY: "🇵🇾",
    QA: "🇶🇦",
    RE: "🇷🇪",
    RO: "🇷🇴",
    RS: "🇷🇸",
    RU: "🇷🇺",
    RW: "🇷🇼",
    SA: "🇸🇦",
    SB: "🇸🇧",
    SC: "🇸🇨",
    SD: "🇸🇩",
    SE: "🇸🇪",
    SG: "🇸🇬",
    SH: "🇸🇭",
    SI: "🇸🇮",
    SJ: "🇸🇯",
    SK: "🇸🇰",
    SL: "🇸🇱",
    SM: "🇸🇲",
    SN: "🇸🇳",
    SO: "🇸🇴",
    SR: "🇸🇷",
    SS: "🇸🇸",
    ST: "🇸🇹",
    SV: "🇸🇻",
    SX: "🇸🇽",
    SY: "🇸🇾",
    SZ: "🇸🇿",
    TC: "🇹🇨",
    TD: "🇹🇩",
    TF: "🇹🇫",
    TG: "🇹🇬",
    TH: "🇹🇭",
    TJ: "🇹🇯",
    TK: "🇹🇰",
    TL: "🇹🇱",
    TM: "🇹🇲",
    TN: "🇹🇳",
    TO: "🇹🇴",
    TR: "🇹🇷",
    TT: "🇹🇹",
    TV: "🇹🇻",
    TW: "🇹🇼",
    TZ: "🇹🇿",
    UA: "🇺🇦",
    UG: "🇺🇬",
    UM: "🇺🇲",
    US: "🇺🇸",
    UY: "🇺🇾",
    UZ: "🇺🇿",
    VA: "🇻🇦",
    VC: "🇻🇨",
    VE: "🇻🇪",
    VG: "🇻🇬",
    VI: "🇻🇮",
    VN: "🇻🇳",
    VU: "🇻🇺",
    WF: "🇼🇫",
    WS: "🇼🇸",
    XK: "🇽🇰",
    YE: "🇾🇪",
    YT: "🇾🇹",
    ZA: "🇿🇦",
    ZM: "🇿🇲",
  },
) {
  const arr = countryCode.split("-");
  const index = (arr[1] || arr[0])?.toUpperCase();
  if (index !== undefined) {
    return countryFlagData?.[index] ?? countryCode;
  }
  return countryCode;
}

// Returns a span with a flag icon as background
export function getFlagSpanIcon(countryCode: string) {
  const toLowerCaseCountryCode = countryCode.toLowerCase();
  return (
    <span
      title={getCountryNameFromCountryCode(countryCode)}
      className={`fi fi-${toLowerCaseCountryCode}`}
    />
  );
}

// Returns a div with a flag icon + the country code + name of the country as tooltip
export function getFlagIconAndCountryCode(countryCode: string) {
  return (
    <div>
      {getFlagSpanIcon(countryCode)} {countryCode}
    </div>
  );
}
