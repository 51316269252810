import classNames from "classnames";
import React, { useRef } from "react";

export interface DraggableInterface {
  isFullWidth?: boolean;
  isInline?: boolean;
  hideOnDrag?: boolean;
  // onDragStart we return the drag event and the element itself being dragged
  onDragStart?: (
    e: React.DragEvent<HTMLDivElement>,
    element: HTMLDivElement,
  ) => void;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
  children: React.ReactNode;
}

const Draggable: React.FC<DraggableInterface> = ({
  children,
  onDragStart,
  onDragEnd,
  isInline,
  hideOnDrag,
  isFullWidth,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    // we need to set something here for firefox to work
    event.dataTransfer.setData("text/plain", "placeholder");

    if (hideOnDrag) {
      setTimeout(function () {
        const element = event.target as HTMLDivElement;
        // this is hacky but its the only way to
        // hide the element without firing ondragend
        element.style.display = "none";
      }, 1);
    }

    if (onDragStart && ref.current) {
      onDragStart(event, ref.current);
    }
  };

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    if (hideOnDrag) {
      setTimeout(function () {
        const element = event.target as HTMLDivElement;
        // flex = default style
        // if this ever changes here remember to change it in the css file as well
        element.style.display = isInline ? "inline-flex" : "flex";
      }, 1);
    }

    if (onDragEnd) {
      onDragEnd(event);
    }
  };

  const classes = classNames("draggable", {
    "draggable--full-width": isFullWidth,
    "draggable--inline": isInline,
  });

  return (
    <div
      draggable="true"
      className={classes}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default Draggable;
