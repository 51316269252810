import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ActivityListResponse,
  ActivityRead,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { fetchActivitiesApi } from "./api/api";

const activitiesSlice = createAsyncArrayFetchSlice<ActivityRead, undefined>(
  "auditlogActivities",
);

export default activitiesSlice.reducer;

export const {
  fetchData: fetchActivities,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = activitiesSlice.actions;

// Async stuff - sagas

function* fetchActivitiesSaga() {
  yield put(setIsPending());
  try {
    const response: AxiosResponse<ActivityListResponse> =
      yield call(fetchActivitiesApi);

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    yield put(setFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchActivities.toString(), fetchActivitiesSaga);
}

export function* auditlogActivitiesSagas() {
  yield all([actionWatcher()]);
}
