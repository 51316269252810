import { axiosCsisApi } from "@csis.com/tip/src/App";
import { QueryParams } from "../../types";

export async function fetchSourceStatisticsApi(params: Partial<QueryParams>) {
  return axiosCsisApi.getCreditCardSourceStatsApi20CompromisedDataCreditCardReportStatisticsSourceGet(
    {
      ...params,
    },
  );
}
