import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  OrganizationInternalRead,
  PortalsFrontendApiInternalOrganizationModelsOrganizationReadDetailResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { createAsyncObjectFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncObjectFetchSlice";

const customerInfoSlice =
  createAsyncObjectFetchSlice<OrganizationInternalRead>("customerInfo");

export default customerInfoSlice.reducer;

export const {
  fetchData: fetchCustomerInfo,
  setIsPending: setIsCustomerInfoFetchPending,
  setFetchError: setCustomerInfoFetchError,
  setFetchSuccess: setFetchCustomerInfoSuccess,
} = customerInfoSlice.actions;

// Async stuff - sagas

function* fetchCustomerInfoSaga(action: PayloadAction<string>) {
  yield put(setIsCustomerInfoFetchPending());

  try {
    const response: AxiosResponse<PortalsFrontendApiInternalOrganizationModelsOrganizationReadDetailResponse> =
      yield call(
        axiosCsisApi.getOrganizationApi10OrganizationExternalIdGet,
        action.payload,
      );
    yield put(setFetchCustomerInfoSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setCustomerInfoFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchCustomerInfo.toString(), fetchCustomerInfoSaga);
}

export function* customerInfoSagas() {
  yield all([actionWatcher()]);
}
