import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ListResponseBrandPreview } from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { axiosCsisApi } from "../../../../../App";
import { BrandEntry } from "./Brand";

const brandsSlice = createAsyncArrayFetchSlice<BrandEntry, {}>("brands");

export default brandsSlice.reducer;

export const {
  fetchData: fetchBrands,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = brandsSlice.actions;

// Async stuff - sagas

function* fetchBrandsSaga() {
  yield put(setIsPending());
  try {
    const response: AxiosResponse<ListResponseBrandPreview> = yield call(
      axiosCsisApi.getBrandsApi20PhishingBrandGet,
    );

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    yield put(setFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchBrands.toString(), fetchBrandsSaga);
}

export function* phishingBrandsSagas() {
  yield all([actionWatcher()]);
}
