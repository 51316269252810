import { victimsInfoKeys } from "../constants";
import { QueryParams } from "../types";

// mapper for api requests to transform start_date and end_date to appropriate query params
export function mapVictimsInfoStartAndEndDateToCorrectType(
  queryParams: Partial<QueryParams>,
) {
  return Object.assign({}, queryParams, {
    [victimsInfoKeys.SUBMITTED_AFTER]: queryParams[victimsInfoKeys.START_DATE],
    [victimsInfoKeys.SUBMITTED_BEFORE]: queryParams[victimsInfoKeys.END_DATE],
  });
}
