import classNames from "classnames";
import React from "react";
import { Stack } from "../../layout";
import RadioButton from "../RadioButton/RadioButton";

interface Option {
  value: string;
  title: string;
}
export interface RadioButtonGroupInterface {
  name: string;
  options: Option[];
  onChange?: (value: string, name: string) => void;
  value?: string;
  isVertical?: boolean;
}

const RadioButtonGroup: React.FC<RadioButtonGroupInterface> = ({
  name,
  options,
  value,
  onChange,
  isVertical = false,
}) => {
  const classes = classNames("radiobuttongroup");

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value, name);
      }
    },
    [onChange, name],
  );

  return (
    <div id={name} className={classes} role="radiogroup">
      <Stack isVertical={isVertical} gutterSize="big">
        {options.map((option) => (
          <RadioButton
            key={option.value}
            name={name}
            value={option.value}
            title={option.title}
            isSelected={option.value === value}
            onChange={handleChange}
          />
        ))}
      </Stack>
    </div>
  );
};

export default RadioButtonGroup;
