import { axiosCsisApi } from "@csis.com/tip/src/App";
import { EscalationContactUpdateBody } from "@csis.com/tip/src/api/openapi/data-contracts";

export async function fetchEscalationContactsApi() {
  return axiosCsisApi.getEscalationContactsApi10OrganizationEscalationContactGet();
}

export async function postEscalationContactsApi(
  data: EscalationContactUpdateBody,
) {
  return axiosCsisApi.updateEscalationContactsApi10OrganizationEscalationContactPost(
    data,
  );
}
