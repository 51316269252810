import { EmergencyCaseAttachment } from "./types";

export function getEmergencyCaseAttachments(
  files: Array<EmergencyCaseAttachment> | undefined,
) {
  if (!files) return [];
  return files.map((item: EmergencyCaseAttachment) => ({
    filename: item.filename,
    id: item.external_id,
    size: item.content_length,
  }));
}

// returns the time in seconds remaining in the 4 hour deadline between
// ER case submission and acceptance
export function getSecondsToRetainerDeadline(
  submissionTime: string,
  decisionTime: string = new Date().toISOString(),
) {
  const FOUR_HOURS_IN_SECONDS = 4 * 60 * 60;

  const endInMilliseconds = new Date(decisionTime).valueOf();
  const createdTimeInMilliseconds = new Date(submissionTime).valueOf();

  const timeElapsedInSeconds = Math.floor(
    Math.abs(endInMilliseconds - createdTimeInMilliseconds) / 1000,
  );
  return FOUR_HOURS_IN_SECONDS - timeElapsedInSeconds;
}
