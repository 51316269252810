import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ArticleDetailResponse,
  ArticlePreviewPageDetailResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { axiosCsisApi } from "../../../App";
import { InsightsRow } from "../Search/types";
import { Article } from "./types";

interface StateSlice {
  article: Article | null;
  isPending: boolean;
  fetchError: string | null;

  relatedArticles: InsightsRow[];
  isRelatedArticlesPending: boolean;
  relatedArticlesError: string | null;
}
const initialState: StateSlice = {
  article: null,
  isPending: false,
  fetchError: null,

  relatedArticles: [],
  isRelatedArticlesPending: false,
  relatedArticlesError: null,
};

const articleSlice = createSlice({
  name: "article",
  initialState: initialState,
  reducers: {
    fetchArticleById(_, __: PayloadAction<string>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<Article>) {
      state.isPending = false;
      state.article = action.payload;
      state.fetchError = null;
    },

    downloadArticleAttachment(
      _state,
      _action: PayloadAction<{
        articleId: string;
        attachmentId: string;
        filename: string;
      }>,
    ) {
      //empty handled by saga
    },
    setDownloadArticleAttachmentError(_state) {},

    fetchRelatedArticles(_, __: PayloadAction<string>) {
      //empty handled by saga
    },
    setRelatedArticlesPending(state) {
      state.isRelatedArticlesPending = true;
      state.relatedArticlesError = null;
    },
    setRelatedArticlesError(state, action: PayloadAction<string>) {
      state.isRelatedArticlesPending = false;
      state.relatedArticlesError = action.payload;
    },
    setRelatedArticlesSuccess(state, action: PayloadAction<InsightsRow[]>) {
      state.isRelatedArticlesPending = false;
      state.relatedArticlesError = null;
      state.relatedArticles = action.payload;
    },
  },
});

export default articleSlice.reducer;

export const {
  fetchArticleById,
  setPending,
  setFetchError,
  fetchSuccess,

  downloadArticleAttachment,
  setDownloadArticleAttachmentError,

  fetchRelatedArticles,
  setRelatedArticlesPending,
  setRelatedArticlesError,
  setRelatedArticlesSuccess,
} = articleSlice.actions;

// Async stuff - sagas

function* fetchArticleByIdSaga(action: PayloadAction<string>) {
  yield put(setPending());
  try {
    const response: AxiosResponse<ArticleDetailResponse> = yield call(
      axiosCsisApi.getArticleApi20ArticleExternalIdGet,
      action.payload,
    );

    yield put(fetchSuccess(response.data.payload as Article));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* downloadArticleAttachmentSaga(
  action: PayloadAction<{
    articleId: string;
    attachmentId: string;
    filename: string;
  }>,
) {
  try {
    const response: AxiosResponse<Blob> = yield call(
      axiosCsisApi.getAttachmentApi20ArticleExternalIdAttachmentAttachmentExternalIdGet,
      action.payload.attachmentId,
      action.payload.articleId,
      {
        format: "blob",
      },
    );

    const blob = response.data;
    downloadBlobForUser(blob, action.payload.filename);
  } catch (e) {
    yield put(setDownloadArticleAttachmentError());
  }
}

function* fetchRelatedArticlesSaga(action: PayloadAction<string>) {
  yield put(setRelatedArticlesPending());
  try {
    const response: AxiosResponse<ArticlePreviewPageDetailResponse> =
      yield call(
        axiosCsisApi.getRelatedArticlesApi20ArticleExternalIdRelatedGet,
        {
          externalId: action.payload,
          limit: 5,
        },
      );

    yield put(
      setRelatedArticlesSuccess(response.data.payload.page as InsightsRow[]),
    );
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setRelatedArticlesError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchArticleById.toString(), fetchArticleByIdSaga);
  yield takeLatest(
    downloadArticleAttachment.toString(),
    downloadArticleAttachmentSaga,
  );
  yield takeLatest(fetchRelatedArticles.toString(), fetchRelatedArticlesSaga);
}

export function* insightSagas() {
  yield all([actionWatcher()]);
}
