// given a queryParams object with a range (start_date - end_date)
// we add a new "group_by" query param based on the length of the range
// so if a range is small, we group the results in "day"
import { calculateGroupByParamBasedOnDateRange } from "@csis.com/tip/src/components/shared/DatePicker/utils";
import { victimsInfoKeys } from "../../../Search/constants";
import { QueryParams } from "../../types";

// if the range is large we group them by week etc
export function addGroupByParamBasedOnRange(queryParams: Partial<QueryParams>) {
  const groupBy = calculateGroupByParamBasedOnDateRange(
    queryParams[victimsInfoKeys.SUBMITTED_AFTER],
    queryParams[victimsInfoKeys.SUBMITTED_BEFORE],
  );

  return Object.assign({}, queryParams, {
    group_by: groupBy,
  });
}
