import {
  ColumnInterface,
  SelectedRows,
  SortDirection,
} from "@csis.com/components/src/atoms/Table/types";
import { toggleArrayItem } from "@csis.com/components/src/utils/utils";

// given a column and a direction it returns the new sort value:
export function getNewSortValue(
  column: string,
  direction: SortDirection | null,
) {
  let newSortValue: string | undefined;
  if (direction === "asc") {
    newSortValue = "-" + column;
  } else if (direction === "desc") {
    newSortValue = column;
  } else {
    newSortValue = undefined;
  }
  return newSortValue;
}

// either add the rowid to the selected rows
// or remove it
export function addOrRemoveRow(
  selectedRows: SelectedRows,
  rowId: string | number,
) {
  return toggleArrayItem(selectedRows.rows, rowId);
}

export function getDirectionBasedOnSort(sort: string): SortDirection {
  return sort.startsWith("-") ? "asc" : "desc";
}

export function getNewColumnsBasedOnSort<N extends string, T extends string>(
  columns: ColumnInterface<N, T>[],
  sort?: string,
): ColumnInterface<N, T>[] {
  if (!sort) {
    return columns;
  }

  // Handle sorting
  const direction: SortDirection = getDirectionBasedOnSort(sort);

  return columns.map((column) => {
    // if key === sort or key === -sort
    if (column.key === sort || column.key === sort.substring(1)) {
      return { ...column, sort: direction };
    } else {
      // just remove the sort from other columns
      const { sort, ...columnWithoutSort } = column;
      return columnWithoutSort;
    }
  });
}
