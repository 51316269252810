import React from "react";
import { Link } from "react-router-dom";
import { Icon, Skeleton } from "@csis.com/components";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { AccessibleServices } from "../../../api/openapi/data-contracts";
import { SelectedOnboardingStep } from "../../OnboardingHelp/types";
import { MenuItems, SubMenuItem } from "./types";
import { getClassesForMenu, isLevelActive } from "./utils";

const SecondLevelItems = ({
  menuItem,
  route,
  isAdmin,
  isMenusPending,
  enabledMenusNew,
}: {
  menuItem: SubMenuItem;
  route: string;
  isAdmin?: boolean;
  isMenusPending?: boolean;
  enabledMenusNew?: AccessibleServices;
}) => {
  const { t } = useTranslations();

  if (isMenusPending) {
    return (
      <div className="second-level__loader">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }

  const subChildren = menuItem.children;

  return (
    <div className="second-level">
      {subChildren &&
        Object.values(subChildren).map((subChild) => {
          const { isAdminOnlyVisible, label, url, permissionProductKey } =
            subChild;
          const isMenuItemVisible = !isAdminOnlyVisible || isAdmin;

          if (!isMenuItemVisible) return null;
          // first check for the new permissions if they exist

          const menuItemRequiresNewPermission = permissionProductKey;
          const isNewPermissionEnabled =
            permissionProductKey &&
            enabledMenusNew &&
            enabledMenusNew[permissionProductKey];

          if (menuItemRequiresNewPermission && !isNewPermissionEnabled) {
            return null;
          }
          // only "continue" with showing the menu if the new permission is enabled (true)
          // in cases where the org has empty enabledMenusNew the menu will not be shown by default
          // empty permissions == no access

          const classes = getClassesForMenu(
            route,
            url,
            2,
            "second-level__menuitem",
          );

          return (
            <React.Fragment key={label}>
              {/* @ts-ignore */}
              <Link to={url}>
                <div title={t(label)} className={classes}>
                  {t(label)}
                  {isAdminOnlyVisible && (
                    <div
                      className="second-level__menuitem__beta-indicator"
                      title="Page only visible internally"
                    >
                      INTERNAL
                    </div>
                  )}
                </div>
              </Link>
              {isLevelActive(route, url, 2) && (
                <ThirdLevelItems
                  secondaryMenuItem={subChild}
                  route={route}
                  isAdmin={isAdmin}
                />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

const ThirdLevelItems = ({
  secondaryMenuItem,
  route,
  isAdmin,
}: {
  secondaryMenuItem: SubMenuItem;
  route: string;
  isAdmin?: boolean;
}) => {
  const { t } = useTranslations();
  const subChildren = secondaryMenuItem.children;

  return (
    <div className="third-level">
      {subChildren &&
        Object.values(subChildren).map((subChild) =>
          !subChild.isAdminOnlyVisible || isAdmin ? (
            <React.Fragment key={subChild.label}>
              {/* @ts-ignore */}
              <Link to={subChild.url}>
                <div
                  title={t(subChild.label)}
                  className={getClassesForMenu(
                    route,
                    subChild.url,
                    3,
                    "third-level__menuitem",
                  )}
                >
                  {t(subChild.label)}
                </div>
              </Link>
              {isLevelActive(route, subChild.url, 3) && (
                <FourthLevelItems tetriaryMenuItem={subChild} route={route} />
              )}
            </React.Fragment>
          ) : null,
        )}
    </div>
  );
};

const FourthLevelItems = ({
  tetriaryMenuItem,
  route,
}: {
  tetriaryMenuItem: SubMenuItem;
  route: string;
}) => {
  const { t } = useTranslations();

  const subChildren = tetriaryMenuItem.children;

  return (
    <div className="fourth-level">
      {subChildren &&
        Object.values(subChildren).map((subChild) => {
          const { label, url } = subChild;

          return (
            // @ts-ignore
            <Link to={url} key={label}>
              <div
                title={t(label)}
                className={getClassesForMenu(
                  route,
                  url,
                  4,
                  "fourth-level__menuitem",
                )}
              >
                {t(label)}
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export interface SidebarMenuInterface {
  menuItems: MenuItems;
  route: string;
  isAdmin?: boolean;
  isMenusPending?: boolean;
  enabledMenusNew?: AccessibleServices;
  selectedOnboardingStep?: SelectedOnboardingStep;
}

const SidebarMenu = ({
  menuItems,
  route,
  isAdmin,
  isMenusPending,
  enabledMenusNew,
  selectedOnboardingStep,
}: SidebarMenuInterface) => {
  const { t } = useTranslations();

  return (
    <nav aria-label="primary sidebar" className="sidebar__menu">
      {Object.values(menuItems).map((menuItem) => {
        const {
          isAdminOnlyVisible,
          label,
          url,
          icon,
          isNew,
          permissionProductKey,
        } = menuItem;

        // Admin check
        if (isAdminOnlyVisible && !isAdmin) return null;

        // Permission check
        const menuItemRequiresNewPermission = permissionProductKey;

        const isNewPermissionEnabled =
          permissionProductKey &&
          enabledMenusNew &&
          enabledMenusNew[permissionProductKey];

        if (menuItemRequiresNewPermission && !isNewPermissionEnabled) {
          return null;
        }

        const isItemHighlightedForOnboarding = !!(
          menuItem.onboardingId &&
          selectedOnboardingStep === menuItem.onboardingId
        );

        const classes = getClassesForMenu(
          route,
          url,
          1,
          "menuitem",
          isItemHighlightedForOnboarding,
        );

        return (
          <React.Fragment key={label}>
            {/* @ts-ignore */}
            <Link to={url}>
              <div className={classes}>
                <div className="menuitem__icon">
                  <Icon size="large" kind={icon} />
                </div>
                <div className="menuitem__label" title={t(label)}>
                  {t(label)}
                </div>
                {isAdminOnlyVisible && (
                  <div
                    className="menuitem__beta-indicator"
                    title="Page only visible internally"
                  >
                    INTERNAL
                  </div>
                )}
                {isNew && (
                  <div
                    className="second-level__menuitem__beta-indicator"
                    title="This page is new"
                  >
                    NEW
                  </div>
                )}
              </div>
            </Link>
            {isLevelActive(route, url, 1) && (
              <SecondLevelItems
                menuItem={menuItem}
                route={route}
                isAdmin={isAdmin}
                isMenusPending={isMenusPending}
                enabledMenusNew={enabledMenusNew}
              />
            )}
          </React.Fragment>
        );
      })}
    </nav>
  );
};

export default SidebarMenu;
