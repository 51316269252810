import { isString } from "@csis.com/tip/src/models/helpers";
import { FieldError } from "./openapi/data-contracts";

export function removeDuplicateErrors(errors: (FieldError | string)[]) {
  return errors.filter(
    // value, index, array
    (v, i, a) =>
      isString(v) ||
      (!isString(v) &&
        a.findIndex((v2) => !isString(v2) && v2.field === v.field) === i),
  );
}
