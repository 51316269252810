import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel, Table } from "@csis.com/components";
import { TableCellFormatter } from "@csis.com/components/src/atoms/Table/types";
import { truncateText } from "@csis.com/components/src/utils/utils";
import { isString } from "@csis.com/tip/src/models/helpers";
import { SeverityBarChart } from "@csis.com/tip/src/pages/Tickets/Statistics/Severity/SeverityBarChart";
import { SeverityLineChart } from "@csis.com/tip/src/pages/Tickets/Statistics/Severity/SeverityLineChart";
import { SeverityPieChart } from "@csis.com/tip/src/pages/Tickets/Statistics/Severity/SeverityPieChart";
import { fetchSeveritiesDistributionOverTime } from "@csis.com/tip/src/pages/Tickets/Statistics/Severity/slice";
import { StatusPieChart } from "@csis.com/tip/src/pages/Tickets/Statistics/Status/StatusPieChart";
import { getStatusDistributionResult } from "@csis.com/tip/src/pages/Tickets/Statistics/Status/selectors";
import { TicketsColumns } from "@csis.com/tip/src/pages/Tickets/TicketsSearch/Table/columns";
import {
  severityCell,
  statusCell,
} from "@csis.com/tip/src/pages/Tickets/TicketsSearch/Table/tableCellFormatters";
import { ticketsKeys } from "@csis.com/tip/src/pages/Tickets/TicketsSearch/constants";
import { getTicketsResult } from "@csis.com/tip/src/pages/Tickets/TicketsSearch/selectors";
import { fetchTickets } from "@csis.com/tip/src/pages/Tickets/TicketsSearch/slice";
import { QueryParams } from "@csis.com/tip/src/pages/Tickets/TicketsSearch/types";
import { StatusValues } from "@csis.com/tip/src/pages/Tickets/models/Status";
import urlTemplates from "@csis.com/tip/src/routes/urlTemplates";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { getNewLocationUrl } from "@csis.com/tip/src/utils/updateLocationWithParams";
import useNavigateOnRowClick from "../../../../components/shared/DataTableContainer/hooks/useNavigateOnRowClick";
import { getSelectedOrgId } from "../../../Profile/Security/selectors";
import ProductCard from "./ProductCard/ProductCard";
import { ELEMENTS_PER_TABLE, REQUEST_DELAY_MS } from "./constants";

const columns: TicketsColumns = [
  { key: ticketsKeys.TITLE, name: "title" },
  { key: ticketsKeys.SEVERITY, name: "severity" },
  { key: ticketsKeys.STATUS, name: "status", isCenterAligned: true },
];

const slidesInfo = [
  { subtitle: "Status Distributions", viewAllUrl: urls.tickets_statistics },
  { subtitle: "Severities Over Time", viewAllUrl: urls.tickets_statistics },
  {
    subtitle: "Severities Trends",
    viewAllUrl: urls.tickets_statistics,
  },
  { subtitle: "Severities Distributions", viewAllUrl: urls.tickets_statistics },
  { subtitle: "Latest Tickets", viewAllUrl: urls.tickets_search },
];

export default function TicketsProductCard({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) {
  const dispatch = useDispatch();
  const selectedOrgId = useSelector(getSelectedOrgId);

  const [requestDelay, setRequestDelay] = useState<number>(REQUEST_DELAY_MS);
  const { t } = useTranslations();

  // ** Effects  **
  useEffect(() => {
    const queryParams: Partial<QueryParams> = {
      [ticketsKeys.START_DATE]: startDate,
      [ticketsKeys.END_DATE]: endDate,
    };

    const timer = setTimeout(() => {
      dispatch(fetchTickets(queryParams));
      dispatch(fetchSeveritiesDistributionOverTime(queryParams));
      setRequestDelay(0);
      // dispatch(fetchStatusDistribution(queryParams));   <-- it is requested by TicketsOverviewCard.tsx
      // dispatch(fetchSeveritiesDistribution(queryParams));   <-- it is requested by TicketsOverviewCard.tsx
    }, requestDelay);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDate, startDate, selectedOrgId]);

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  function handleCarouselChange(newSlideIndex: number) {
    setCurrentSlideIndex(newSlideIndex);
  }

  const { statusDistributions, isStatusDistributionPending } = useSelector(
    getStatusDistributionResult,
  );

  const { tickets, isTicketsPending, ticketsFetchError } =
    useSelector(getTicketsResult);

  const { handleRowClick } = useNavigateOnRowClick(
    ticketsKeys.TICKET_ID,
    urlTemplates._tickets_ticket_id,
  );

  const formatters = useMemo(
    () => ({
      [ticketsKeys.TITLE]: titleCell(),
      [ticketsKeys.SEVERITY]: severityCell(t),
      [ticketsKeys.STATUS]: statusCell(t),
    }),
    [t],
  );

  function titleCell(): TableCellFormatter<string> {
    return (row, column) => {
      const title = row[column];
      if (isString(title)) {
        const truncatedTitle = truncateText({
          text: title,
          maxChars: 40,
          truncateAtTheEnd: true,
        });
        return <span title={title}>{truncatedTitle}</span>;
      }
      return <>{title}</>;
    };
  }

  const totalTicketsAwaitingReview =
    statusDistributions?.find(
      (element) => element.name === StatusValues.PENDING_CUSTOMER,
    )?.count || 0;

  const ticketsAwaitingReviewUrl = getNewLocationUrl(urls.tickets_search, {
    [ticketsKeys.STATUS]: [StatusValues.PENDING_CUSTOMER],
    [ticketsKeys.START_DATE]: startDate,
    [ticketsKeys.END_DATE]: endDate,
  });

  return (
    <ProductCard dataTestId="tickets-product-card">
      <ProductCard.Header
        title={t("tickets")}
        subTitle={slidesInfo[currentSlideIndex]?.subtitle}
        viewAllUrl={slidesInfo[currentSlideIndex]?.viewAllUrl}
      />

      <ProductCard.Carousel>
        <Carousel
          onChange={handleCarouselChange}
          name="tickets-carousel"
          hasAutoplay
          isLoading={isStatusDistributionPending}
          items={[
            <StatusPieChart />,
            <SeverityBarChart />,
            <SeverityLineChart />,
            <SeverityPieChart startDate={startDate} endDate={endDate} />,
            <Table
              name={"tickets-card-table"}
              columns={columns}
              rows={tickets?.slice(0, ELEMENTS_PER_TABLE)}
              isLoading={isTicketsPending}
              error={ticketsFetchError}
              ghostRows={ELEMENTS_PER_TABLE}
              columnNameTransform={t}
              isFullWidth
              size="small"
              onRowClick={handleRowClick}
              formatters={formatters}
              dataTestId={"tickets-card-table"}
            />,
          ]}
        />
      </ProductCard.Carousel>
      <ProductCard.Footer
        text={`${totalTicketsAwaitingReview} ${t("tickets_awaiting_you")}`}
        url={ticketsAwaitingReviewUrl}
      />
    </ProductCard>
  );
}
