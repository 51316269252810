import React, { useMemo } from "react";
import { Divider, Stack } from "@csis.com/components";
import { DateRange } from "@csis.com/components/src/atoms/DatePicker/types";
import { isValidRange } from "@csis.com/components/src/atoms/DatePicker/utils";
import DatePickerTIP from "@csis.com/tip/src/components/shared/DatePicker/DatePicker";
import NewTicketButton from "@csis.com/tip/src/pages/Tickets/NewTicketDialog/NewTicketButton";
import { getRangeFromTwoDates } from "@csis.com/tip/src/utils/dateCalculations";

function TopBar({
  startDate,
  endDate,
  onDateRangeSelected,
  setNewTicketDialogShown,
}: {
  startDate?: string;
  endDate?: string;
  setNewTicketDialogShown: React.Dispatch<React.SetStateAction<boolean>>;
  onDateRangeSelected: (value: Date | DateRange | undefined) => void;
}) {
  const selectedRange = useMemo(
    () => getRangeFromTwoDates(startDate, endDate),
    [endDate, startDate],
  );

  return (
    <Stack justify="space-between" align="center">
      <Stack gutterSize="big" align="center">
        <div className="f_big f_secondary f_semibold">Overview</div>
        <Divider />
        <DatePickerTIP
          name="dashboard-overview-datepicker"
          canSelectRange
          size="small"
          onSelect={onDateRangeSelected}
          selectedRange={
            isValidRange(selectedRange) ? selectedRange : undefined
          }
        />
      </Stack>
      <NewTicketButton onClick={() => setNewTicketDialogShown(true)} />
    </Stack>
  );
}

export default TopBar;
