import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  addGroupByParamBasedOnRange,
  mapAlertsStartAndEndDateToCorrectType,
} from "@csis.com/tip/src/pages/Alerts/AlertsSearch/api/utils";
import { QueryParams } from "../../types";

export async function fetchStatusStatisticsApi(
  queryParams: Partial<QueryParams>,
) {
  const params = mapAlertsStartAndEndDateToCorrectType(queryParams);

  return axiosCsisApi.getStatisticsStatusApi20AlertStatisticsStatusGet(params);
}

export async function fetchStatusStatisticsHistogramApi(
  queryParams: Partial<QueryParams>,
) {
  const params = addGroupByParamBasedOnRange(
    mapAlertsStartAndEndDateToCorrectType(queryParams),
  );

  return axiosCsisApi.getStatisticsStatusHistogramApi20AlertStatisticsStatusHistogramGet(
    params,
  );
}
