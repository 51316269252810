import { HighlightSubString, Icon, SearchableCell } from "@csis.com/components";
import { checkIfTextContainsSubstring } from "@csis.com/components/src/atoms/HighlightSubString/utils/utils";
import { TableCellFormatter } from "@csis.com/components/src/atoms/Table/types";
import { isString } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";
import { isSource } from "../../models/Source";
import {
  StatusIcons,
  StatusTranslationKeys,
  isStatus,
} from "../../models/Status";
import { isVariant } from "../../models/Variant";
import { victimsInfoKeys } from "../constants";
import { CompDataKey, FilterKey } from "../types";

export function statusCell(
  t: (key: TranslationKey, args?: string[]) => string,
): TableCellFormatter<CompDataKey> {
  return (row, column) => {
    const statusValue = row[column];
    if (isStatus(statusValue)) {
      return (
        <Icon
          kind={StatusIcons[statusValue]}
          color="secondary"
          size="large"
          tooltipText={t(StatusTranslationKeys[statusValue])}
          isInline
        />
      );
    }
    return <>{statusValue}</>;
  };
}

export function sourceCell(): TableCellFormatter<CompDataKey> {
  return (row, column) => {
    const source = row[column];
    if (isSource(source)) {
      return source.name;
    }
    return "-";
  };
}

export const sourceCellWithHandler: (
  handleClick: (newValues: unknown, name: FilterKey) => void,
) => TableCellFormatter<CompDataKey> = (handleClick) => {
  return (row, column) => {
    const source = row[column];
    if (isSource(source)) {
      return (
        <SearchableCell
          key={"searchable_cell-" + source.name}
          onClick={() => {
            handleClick([source.name], victimsInfoKeys.SOURCE);
          }}
        >
          {source.name}
        </SearchableCell>
      );
    }
    return "";
  };
};

export const variantCellWithHandler: (
  handleClick: (newValues: unknown, name: FilterKey) => void,
) => TableCellFormatter<CompDataKey> = (handleClick) => {
  return (row, column) => {
    const variant = row[column];
    if (!variant) {
      return "";
    }
    if (isVariant(variant)) {
      return (
        <SearchableCell
          key={"searchable_cell-" + variant.name}
          onClick={() => {
            handleClick([variant.name], victimsInfoKeys.VARIANT);
          }}
        >
          {variant.name}
        </SearchableCell>
      );
    }
    return "";
  };
};

// if there is a search string, highlight it in the id row
export function idCell(searchString?: string): TableCellFormatter<CompDataKey> {
  return (row, column) => {
    const id = row[column];

    if (
      isString(id) &&
      searchString &&
      checkIfTextContainsSubstring(id, searchString)
    ) {
      return <HighlightSubString text={id} searchString={searchString} />;
    }

    return <>{id}</>;
  };
}

// if there is a search string, highlight it in the id row
export function emailCell(
  searchString?: string,
): TableCellFormatter<CompDataKey> {
  return (row, column) => {
    const email = row[column];

    if (!email) {
      return "-";
    }
    if (
      isString(email) &&
      searchString &&
      checkIfTextContainsSubstring(email, searchString)
    ) {
      return <HighlightSubString text={email} searchString={searchString} />;
    }

    return <>{email}</>;
  };
}

// if there is a search string, highlight it in the id row
export function usernameCell(
  searchString?: string,
): TableCellFormatter<CompDataKey> {
  return (row, column) => {
    const username = row[column];

    if (
      isString(username) &&
      searchString &&
      checkIfTextContainsSubstring(username, searchString)
    ) {
      return <HighlightSubString text={username} searchString={searchString} />;
    }

    return <>{username}</>;
  };
}
