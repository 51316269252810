import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  TicketCommentPreviewListResponse,
  TicketFeedbackCreateBody,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { getUserPreferences } from "@csis.com/tip/src/userPreferences/selectors";
import { updateUserPreferences } from "@csis.com/tip/src/userPreferences/slice";
import { UserPreferences } from "@csis.com/tip/src/userPreferences/types";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { getUploadedAttachments } from "../NewTicketDialog/selector";
import { removeAllAttachments } from "../NewTicketDialog/slice";
import { Flag, FlagName } from "../models/Flag/Flag";
import { Severity } from "../models/Severity";
import { Status } from "../models/Status";
import {
  fetchCommentsForTicketApi,
  fetchTicketApi,
  fetchTicketAttachmentApi,
  patchTicketCustomerReference,
  patchTicketFlagsApi,
  patchTicketSeverityApi,
  patchTicketStatusApi,
  postCommentByTicketIdApi,
  postFeedbackByTicketIdApi,
} from "./api/api";
import { TicketResponse } from "./api/types";
import { ticketCommentKeys } from "./constants";
import {
  NewComment,
  SortPreference,
  Ticket,
  TicketAttachment,
  TicketComment,
} from "./types";

interface StateSlice {
  ticket: Ticket | null;
  isPending: boolean;
  fetchError: string | null;

  comments: TicketComment[] | null;
  isCommentsPending: boolean;
  commentsFetchError: string | null;

  postCommentSuccess: boolean;
  isPostCommentPending: boolean;
  postCommentError: string | null;

  postFeedbackSuccess: boolean;
  isPostFeedbackPending: boolean;
  postFeedbackError: string | null;
}
const initialState: StateSlice = {
  ticket: null,
  isPending: false,
  fetchError: null,

  comments: null,
  isCommentsPending: false,
  commentsFetchError: null,

  postCommentSuccess: false,
  isPostCommentPending: false,
  postCommentError: null,

  postFeedbackSuccess: false,
  isPostFeedbackPending: false,
  postFeedbackError: null,
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState: initialState,
  reducers: {
    updateTicketStatus(
      _state,
      _action: PayloadAction<{ ticketId: string; status: Status }>,
    ) {
      //empty handled by saga
    },
    updateTicketSeverity(
      _state,
      _action: PayloadAction<{ ticketId: string; severity: Severity }>,
    ) {},
    setTicketStatusUpdateError(_state) {},
    setTicketSeverityUpdateError(_state) {},

    updateTicketCustomerReference(
      _state,
      _action: PayloadAction<{ ticketId: string; customerReference: string }>,
    ) {},
    setTicketCustomerReferenceUpdateError(_state) {},

    updateTicketFlags(
      _state,
      _action: PayloadAction<{ ticketId: string; flags: Flag["name"][] }>,
    ) {},
    setTicketFlagUpdateError(_state) {},

    fetchTicketById(_state, _action: PayloadAction<{ id: string }>) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
    },
    fetchSuccess(state, action: PayloadAction<Ticket>) {
      state.isPending = false;
      state.ticket = action.payload;
      state.fetchError = null;
    },

    fetchTicketCommentsById(_state, _action: PayloadAction<{ id: string }>) {
      //empty handled by saga
    },
    setFetchTicketCommentsPending(state) {
      state.isCommentsPending = true;
      state.commentsFetchError = null;
    },
    setFetchTicketCommentsError(state, action: PayloadAction<string>) {
      state.isCommentsPending = false;
      state.commentsFetchError = action.payload;
    },
    setFetchTicketCommentsSuccess(
      state,
      action: PayloadAction<TicketComment[]>,
    ) {
      state.isCommentsPending = false;
      state.comments = action.payload;
      state.commentsFetchError = null;
    },

    changeSort(_state, _action: PayloadAction<SortPreference>) {
      //empty handled by saga
    },

    postComment(
      _state,
      _action: PayloadAction<{ newComment: NewComment; ticketId: string }>,
    ) {
      //empty handled by saga
    },
    setPostCommentPending(state) {
      state.isPostCommentPending = true;
      state.postCommentError = null;
      state.postCommentSuccess = false;
    },
    setPostCommentError(state, action: PayloadAction<string>) {
      state.isPostCommentPending = false;
      state.postCommentSuccess = false;
      state.postCommentError = action.payload;
    },
    setPostCommentSuccess(state) {
      state.isPostCommentPending = false;
      state.postCommentSuccess = true;
      state.postCommentError = null;
    },

    downloadTicketAttachment(
      _state,
      _action: PayloadAction<{
        ticketId: string;
        attachmentId: string;
        filename: string;
      }>,
    ) {
      //empty handled by saga
    },
    setDownloadTicketAttachmentError(_state) {},

    postFeedback(
      _state,
      _action: PayloadAction<{
        feedback: TicketFeedbackCreateBody;
        ticketId: string;
        feedbackId?: string;
      }>,
    ) {
      //empty handled by saga
    },
    setPostFeedbackPending(state) {
      state.isPostFeedbackPending = true;
      state.postFeedbackError = null;
      state.postFeedbackSuccess = false;
    },
    setPostFeedbackError(state, action: PayloadAction<string>) {
      state.isPostFeedbackPending = false;
      state.postFeedbackSuccess = false;
      state.postFeedbackError = action.payload;
    },
    setPostFeedbackSuccess(state) {
      state.isPostFeedbackPending = false;
      state.postFeedbackSuccess = true;
      state.postFeedbackError = null;
    },
    resetPostFeedbackState(state) {
      state.isPostFeedbackPending = false;
      state.postFeedbackSuccess = false;
      state.postFeedbackError = null;
    },
  },
});

export default ticketSlice.reducer;

export const {
  fetchTicketById,
  setPending,
  setFetchError,
  fetchSuccess,

  updateTicketStatus,
  setTicketStatusUpdateError,

  updateTicketSeverity,
  setTicketSeverityUpdateError,

  updateTicketCustomerReference,
  setTicketCustomerReferenceUpdateError,

  updateTicketFlags,
  setTicketFlagUpdateError,

  fetchTicketCommentsById,
  setFetchTicketCommentsPending,
  setFetchTicketCommentsError,
  setFetchTicketCommentsSuccess,

  changeSort,

  postComment,
  setPostCommentPending,
  setPostCommentError,
  setPostCommentSuccess,

  downloadTicketAttachment,
  setDownloadTicketAttachmentError,

  postFeedback,
  setPostFeedbackPending,
  setPostFeedbackError,
  setPostFeedbackSuccess,
  resetPostFeedbackState,
} = ticketSlice.actions;

// Async stuff - sagas

function* updateTicketStatusSaga(
  action: PayloadAction<{ ticketId: string; status: Status }>,
) {
  // we go the "optimistic way" of updating the fields here
  // we just update the ui immediately (no loaders) and assume success, and we dont say anything
  // we ONLY notify if there was an error in the server update
  try {
    yield call(patchTicketStatusApi, action.payload);
    yield put(fetchTicketById({ id: action.payload.ticketId }));
  } catch (e) {
    yield put(setTicketStatusUpdateError());
  }
}

function* updateTicketSeveritySaga(
  action: PayloadAction<{ ticketId: string; severity: Severity }>,
) {
  try {
    yield call(patchTicketSeverityApi, action.payload);
    yield put(fetchTicketById({ id: action.payload.ticketId }));
  } catch (e) {
    yield put(setTicketSeverityUpdateError());
  }
}

function* updateTicketCustomerReferenceSaga(
  action: PayloadAction<{ ticketId: string; customerReference: string }>,
) {
  try {
    yield call(patchTicketCustomerReference, action.payload);
    yield put(fetchTicketById({ id: action.payload.ticketId }));
  } catch (e) {
    yield put(setTicketCustomerReferenceUpdateError());
  }
}

function* updateTicketFlagsSaga(
  action: PayloadAction<{ ticketId: string; flags: FlagName[] }>,
) {
  try {
    yield call(patchTicketFlagsApi, action.payload);
    yield put(fetchTicketById({ id: action.payload.ticketId }));
  } catch (e) {
    yield put(setTicketFlagUpdateError());
  }
}

function* fetchTicketByIdSaga(action: PayloadAction<{ id: string }>) {
  yield put(setPending());
  try {
    const response: AxiosResponse<TicketResponse> = yield call(
      fetchTicketApi,
      action.payload.id,
    );

    yield put(fetchSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* fetchTicketCommentsByIdSaga(action: PayloadAction<{ id: string }>) {
  yield put(setFetchTicketCommentsPending());
  try {
    const response: AxiosResponse<TicketCommentPreviewListResponse> =
      yield call(fetchCommentsForTicketApi, action.payload.id);

    yield put(setFetchTicketCommentsSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchTicketCommentsError(errorMessage));
  }
}

function* postCommentSaga(
  action: PayloadAction<{ newComment: NewComment; ticketId: string }>,
) {
  yield put(setPostCommentPending());

  const newComment = action.payload.newComment;
  const ticketId = action.payload.ticketId;

  const attachments: TicketAttachment[] = yield select(getUploadedAttachments);

  newComment[ticketCommentKeys.ATTACHMENTS] = attachments.map((at) => {
    return at.id;
  });

  try {
    yield call(postCommentByTicketIdApi, newComment, ticketId);

    yield put(fetchTicketCommentsById({ id: ticketId }));
    yield put(setPostCommentSuccess());
    yield put(removeAllAttachments());
    yield put(fetchTicketById({ id: ticketId }));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setPostCommentError(errorMessage));
  }
}

function* postFeedbackSaga(
  action: PayloadAction<{
    feedback: TicketFeedbackCreateBody;
    ticketId: string;
    feedbackId?: string;
  }>,
) {
  yield put(setPostFeedbackPending());

  const feedback = action.payload.feedback;
  const ticketId = action.payload.ticketId;

  try {
    yield call(postFeedbackByTicketIdApi, feedback, ticketId);

    yield put(setPostFeedbackSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setPostFeedbackError(errorMessage));
  }
}

function* downloadTicketAttachmentSaga(
  action: PayloadAction<{
    ticketId: string;
    attachmentId: string;
    filename: string;
  }>,
) {
  try {
    const response: AxiosResponse<Blob> = yield call(
      fetchTicketAttachmentApi,
      action.payload.ticketId,
      action.payload.attachmentId,
    );

    const blob = response.data;
    downloadBlobForUser(blob, action.payload.filename);
  } catch (e) {
    yield put(setDownloadTicketAttachmentError());
  }
}

function* reactToSortUpdateAndUpdateUserPreferencesSaga(
  action: PayloadAction<SortPreference>,
) {
  // the user updated the sort so, we "update"
  // the prefs server side on the background
  const newSort = action.payload;
  const userPrefs: UserPreferences = yield select(getUserPreferences);

  if (userPrefs) {
    const newPrefs = { ...userPrefs };
    newPrefs.ticket = { ...newPrefs.ticket, ...newSort };

    yield put(updateUserPreferences(newPrefs));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchTicketById.toString(), fetchTicketByIdSaga);
  yield takeLatest(updateTicketStatus.toString(), updateTicketStatusSaga);
  yield takeLatest(updateTicketSeverity.toString(), updateTicketSeveritySaga);

  yield takeLatest(
    updateTicketCustomerReference.toString(),
    updateTicketCustomerReferenceSaga,
  );
  yield takeLatest(updateTicketFlags.toString(), updateTicketFlagsSaga);
  yield takeLatest(
    fetchTicketCommentsById.toString(),
    fetchTicketCommentsByIdSaga,
  );
  yield takeLatest(postComment.toString(), postCommentSaga);
  yield takeLatest(
    downloadTicketAttachment.toString(),
    downloadTicketAttachmentSaga,
  );
  yield takeLatest(
    changeSort.toString(),
    reactToSortUpdateAndUpdateUserPreferencesSaga,
  );
  yield takeLatest(postFeedback.toString(), postFeedbackSaga);
}

export function* ticketSagas() {
  yield all([actionWatcher()]);
}
