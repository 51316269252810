import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { downloadBlobForUser } from "@csis.com/tip/src/utils/downloadBlob";
import { exportInvestigationCsvApi, fetchInvestigationApi } from "./api/api";
import { InvestigationsResponse } from "./api/types";
import { investigationsKeys } from "./constants";
import { Investigation, QueryParams } from "./types";

interface StateSlice {
  investigations: Investigation[] | null;
  isPending: boolean;
  fetchError: string | null;

  isCsvExportPending: boolean;
}
const initialState: StateSlice = {
  investigations: null,
  isPending: false,
  fetchError: null,

  isCsvExportPending: false,
};

const investigationsSlice = createSlice({
  name: "investigations",
  initialState: initialState,
  reducers: {
    fetchInvestigations(
      _state,
      _action: PayloadAction<{
        queryParams: QueryParams;
        onlyExternallyVisible?: boolean;
      }>,
    ) {
      //empty handled by saga
    },
    setPending(state) {
      state.isPending = true;
      state.fetchError = null;
      state.investigations = null;
    },
    setFetchError(state, action: PayloadAction<string>) {
      state.isPending = false;
      state.fetchError = action.payload;
      state.investigations = null;
    },
    fetchSuccess(state, action: PayloadAction<Investigation[]>) {
      state.isPending = false;
      state.investigations = action.payload;
      state.fetchError = null;
    },

    exportInvestigationsAsCsv(
      _state,
      _action: PayloadAction<{
        queryParams: QueryParams;
        onlyExternallyVisible?: boolean;
      }>,
    ) {
      //empty handled by saga
    },
    setCsvExportPending(state) {
      state.isCsvExportPending = true;
    },
    setCsvExportComplete(state) {
      state.isCsvExportPending = false;
    },
  },
});

export default investigationsSlice.reducer;

export const {
  fetchInvestigations,
  setPending,
  setFetchError,
  fetchSuccess,

  exportInvestigationsAsCsv,
  setCsvExportPending,
  setCsvExportComplete,
} = investigationsSlice.actions;

// Async stuff - sagas
function* fetchInvestigationsSaga(
  action: PayloadAction<{
    queryParams: QueryParams;
    onlyExternallyVisible?: boolean;
  }>,
) {
  yield put(setPending());
  if (!action.payload.queryParams[investigationsKeys.ENTITY_TYPE]) {
    yield put(setFetchError("Please specify entity type"));
  } else {
    try {
      let response: AxiosResponse<InvestigationsResponse> = yield call(
        fetchInvestigationApi,
        action.payload.queryParams,
        action.payload.onlyExternallyVisible,
      );

      // here we do this "special" case for when a response is an empty result
      // in some cases (at the time only URLs), if the backend doesnt know about an entity value
      // it will return "empty" but at the same time it "creates" the entry with basic information
      // this at the moment takes around 3 seconds, so for better UX we can "hold" the loading state for 3-5 seconds
      // then refetch and hopefull the response will not be empty again

      if (response.data.payload.some((res) => res.maps.length === 0)) {
        yield delay(4000);
        response = yield call(
          fetchInvestigationApi,
          action.payload.queryParams,
          action.payload.onlyExternallyVisible,
        );
      }

      yield put(fetchSuccess(response.data.payload));
    } catch (e) {
      const errorMessage = handleRequestError(e);
      yield put(setFetchError(errorMessage));
    }
  }
}

function* exportAlertsCsvSaga(
  action: PayloadAction<{
    queryParams: QueryParams;
    onlyExternallyVisible?: boolean;
  }>,
) {
  yield put(setCsvExportPending());

  try {
    const response: AxiosResponse<Blob> = yield call(
      exportInvestigationCsvApi,
      action.payload.queryParams,
      action.payload.onlyExternallyVisible,
    );

    const blob = response.data;
    downloadBlobForUser(blob, "investigation.csv");
    yield put(setCsvExportComplete());
  } catch (e) {
    yield put(setCsvExportComplete());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchInvestigations.toString(), fetchInvestigationsSaga);
  yield takeLatest(exportInvestigationsAsCsv.toString(), exportAlertsCsvSaga);
}

export function* investigationsSagas() {
  yield all([actionWatcher()]);
}
