// TO FIX once this is resolved https://github.com/facebook/create-react-app/issues/11770
// eslint-disable-next-line import/no-webpack-loader-syntax
import LogoWhite from "!file-loader!../../../img/logo_white.svg";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  FormLabel,
  Form as GenericForm,
  GenericFormError,
  Icon,
  Input,
  Stack,
} from "@csis.com/components";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { CsisFooter } from "../CsisFooter";
import {
  getIsPasswordResetEmailPending,
  getIsPasswordResetEmailSuccess,
  getPasswordResetEmailError,
} from "./selectors";
import { postPasswordResetEmail } from "./slice";

const Logo = () => (
  <div role="banner" className="passwordreset-page__logo">
    <img src={LogoWhite} alt="CSIS Security Group Logo" />
  </div>
);

const Title = () => {
  const { t } = useTranslations();
  return (
    <div className="passwordreset-page__title">
      <h1 className="f_light">{t("forgot_password_for_your")}</h1>
      <h1>
        {t("threat_intelligence_portal")} <span className="f_light">{"?"}</span>
      </h1>
    </div>
  );
};
export interface FormInterface {
  formDetails: FormDetails;
  handleInputChange: (value: string, name: string) => void;
  onPasswordResetEmail: () => void;
}

const Form: React.FC<FormInterface> = ({
  formDetails,
  handleInputChange,
  onPasswordResetEmail,
}) => {
  const isPasswordResetEmailSuccess = useSelector(
    getIsPasswordResetEmailSuccess,
  );
  const isPasswordResetEmailPending = useSelector(
    getIsPasswordResetEmailPending,
  );
  const passwordResetEmailError = useSelector(getPasswordResetEmailError);
  const { t } = useTranslations();

  return (
    <div className="passwordreset-page__form">
      <Card hasShadow={false} color="alt">
        {isPasswordResetEmailSuccess ? (
          <Stack isVertical gutterSize="huge">
            <Stack align="center">
              <Icon kind="check" color="csis" />
              <div className="f_csis f_semibold">
                You will soon receive instructions on how to reset your password
                if there is an account associated with the submitted email
                address.
              </div>
            </Stack>
          </Stack>
        ) : (
          <GenericForm
            id={"password-reset-email-form"}
            onFormSubmit={onPasswordResetEmail}
          >
            <Stack isVertical gutterSize="large" align="stretch">
              <FormLabel text={t("reset_password")} htmlFor={"email"} />
              <Input
                dataTestId="email-input"
                size="large"
                name="email"
                icon="account"
                placeholder={t("email")}
                type="email"
                value={formDetails.email}
                isFullWidth
                onChange={handleInputChange}
                tabIndex={0}
              />
              <div>
                Enter your email address and we will send you a link to reset
                your password.
              </div>
              {passwordResetEmailError && (
                <GenericFormError errorText={passwordResetEmailError} />
              )}
              <Button
                formId={"password-reset-email-form"}
                dataTestId="reset-password-button"
                isLoading={isPasswordResetEmailPending}
                onButtonClick={() => null}
                name="reset-password-btn"
                text={t("reset_password")}
              />
            </Stack>
          </GenericForm>
        )}
      </Card>
    </div>
  );
};

export interface FormDetails {
  email: string;
}

const PasswordResetEmail = () => {
  const dispatch = useDispatch();

  const [formDetails, setFormDetails] = useState<FormDetails>({
    email: "",
  });

  const handleInputChange = useCallback((value: string, name: string) => {
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handlePasswordResetEmail = useCallback(() => {
    dispatch(
      postPasswordResetEmail({
        login: formDetails.email,
      }),
    );
  }, [dispatch, formDetails]);

  return (
    <div className="passwordreset-page">
      <Stack isVertical isExpanded gutterSize="gigantic">
        <Logo />
        <Title />
        <Form
          formDetails={formDetails}
          handleInputChange={handleInputChange}
          onPasswordResetEmail={handlePasswordResetEmail}
        />
      </Stack>
      <CsisFooter />
    </div>
  );
};

export default PasswordResetEmail;
