export const emergencyCaseTypes = {
  it: "it",
  ot: "ot",
} as const;

export const emergencyCaseTypeLabels = {
  [emergencyCaseTypes.it]: "IT",
  [emergencyCaseTypes.ot]: "OT",
} as const;

export const StatusValues = {
  NEW: "new",
  PENDING_CSIS: "pending-csis",
  IN_PROGRESS: "in-progress",
  CLOSED: "closed",
  DECLINED: "declined",
  EXPIRED: "expired",
} as const;

export type Status = (typeof StatusValues)[keyof typeof StatusValues];

export const StatusLabels: { [key in Status]: string } = {
  [StatusValues.NEW]: "New",
  [StatusValues.IN_PROGRESS]: "In Progress",
  [StatusValues.PENDING_CSIS]: "Pending CSIS",
  [StatusValues.CLOSED]: "Closed",
  [StatusValues.DECLINED]: "Declined",
  [StatusValues.EXPIRED]: "Expired",
} as const;

export const questionnaireKeys = {
  discovery_details: "discovery_details",
  affected_stuff: "affected_stuff",
  done_already: "done_already",
  csis_engagement: "csis_engagement",
  description_brief: "description_brief",
} as const;
