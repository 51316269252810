import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  StatisticsCountListResponse,
  StatisticsHistogramCountListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { QueryParams, StatusHistogramEntry, StatusTotalEntry } from "../types";
import {
  fetchStatusStatisticsApi,
  fetchStatusStatisticsHistogramApi,
} from "./api/api";

const alertsStatisticsStatusSlice = createAsyncArrayFetchSlice<
  StatusTotalEntry,
  QueryParams
>("alertsStatisticsStatus");

export const alertsStatisticsStatusReducer =
  alertsStatisticsStatusSlice.reducer;

export const {
  fetchData: fetchStatusDistribution,
  setIsPending: setIsStatusDistributionPending,
  setFetchError: setStatusDistributionFetchError,
  setFetchSuccess: setFetchStatusDistributionSuccess,
} = alertsStatisticsStatusSlice.actions;

const alertsStatisticsStatusOverTimeSlice = createAsyncArrayFetchSlice<
  StatusHistogramEntry,
  QueryParams
>("alertsStatisticsStatusOverTime");

export const alertsStatisticsStatusOverTimeReducer =
  alertsStatisticsStatusOverTimeSlice.reducer;

export const {
  fetchData: fetchStatusDistributionOverTime,
  setIsPending: setIsStatusDistributionOverTimePending,
  setFetchError: setStatusDistributionOverTimeFetchError,
  setFetchSuccess: setFetchStatusDistributionOverTimeSuccess,
} = alertsStatisticsStatusOverTimeSlice.actions;

// Async stuff - sagas

function* fetchStatusDistributionSaga(
  action: PayloadAction<Partial<QueryParams>>,
) {
  yield put(setIsStatusDistributionPending());

  try {
    const response: AxiosResponse<StatisticsCountListResponse> = yield call(
      fetchStatusStatisticsApi,
      action.payload,
    );

    yield put(setFetchStatusDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setStatusDistributionFetchError());
  }
}

function* fetchStatusDistributionOverTimeSaga(
  action: PayloadAction<Partial<QueryParams>>,
) {
  yield put(setIsStatusDistributionOverTimePending());

  try {
    const response: AxiosResponse<StatisticsHistogramCountListResponse> =
      yield call(fetchStatusStatisticsHistogramApi, action.payload);

    yield put(setFetchStatusDistributionOverTimeSuccess(response.data.payload));
  } catch (e) {
    yield put(setStatusDistributionOverTimeFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchStatusDistribution.toString(),
    fetchStatusDistributionSaga,
  );
  yield takeLatest(
    fetchStatusDistributionOverTime.toString(),
    fetchStatusDistributionOverTimeSaga,
  );
}

export function* alertsStatisticsStatusSagas() {
  yield all([actionWatcher()]);
}
