import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { StatisticsCountListResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { QueryParams, SourceTotalEntry } from "../types";
import { fetchSourceStatisticsApi } from "./api/api";

const cardsStatisticsSlice = createAsyncArrayFetchSlice<
  SourceTotalEntry,
  QueryParams
>("cardsStatisticsSource");

export default cardsStatisticsSlice.reducer;

export const {
  fetchData: fetchSourceDistribution,
  setIsPending: setIsSourceDistributionPending,
  setFetchError: setSourceDistributionFetchError,
  setFetchSuccess: setFetchSourceDistributionSuccess,
} = cardsStatisticsSlice.actions;

// Async stuff - sagas

function* fetchSourceDistributionSaga(
  action: PayloadAction<Partial<QueryParams>>,
) {
  yield put(setIsSourceDistributionPending());

  try {
    const response: AxiosResponse<StatisticsCountListResponse> = yield call(
      fetchSourceStatisticsApi,
      action.payload,
    );

    yield put(setFetchSourceDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setSourceDistributionFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchSourceDistribution.toString(),
    fetchSourceDistributionSaga,
  );
}

export function* cardsStatisticsSourceSagas() {
  yield all([actionWatcher()]);
}
