import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  StatisticsCountListResponse,
  StatisticsHistogramCountListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import {
  QueryParams,
  SeveritiesHistogramEntry,
  SeveritiesTotalEntry,
} from "../types";
import {
  fetchSeveritiesStatisticsApi,
  fetchSeveritiesStatisticsHistogramApi,
} from "./api/api";

const ticketsStatisticsSeveritiesSlice = createAsyncArrayFetchSlice<
  SeveritiesTotalEntry,
  QueryParams
>("ticketsStatisticsSeverity");

export const ticketsStatisticsSeverityReducer =
  ticketsStatisticsSeveritiesSlice.reducer;

export const {
  fetchData: fetchSeveritiesDistribution,
  setIsPending: setIsSeveritiesDistributionPending,
  setFetchError: setSeveritiesDistributionFetchError,
  setFetchSuccess: setFetchSeveritiesDistributionSuccess,
} = ticketsStatisticsSeveritiesSlice.actions;

const ticketsStatisticsOverTimeSlice = createAsyncArrayFetchSlice<
  SeveritiesHistogramEntry,
  QueryParams
>("ticketsStatisticsSeverityOverTime");

export const ticketsStatisticsSeverityOverTimeReducer =
  ticketsStatisticsOverTimeSlice.reducer;

export const {
  fetchData: fetchSeveritiesDistributionOverTime,
  setIsPending: setIsSeveritiesDistributionOverTimePending,
  setFetchError: setSeveritiesDistributionOverTimeFetchError,
  setFetchSuccess: setFetchSeveritiesDistributionOverTimeSuccess,
} = ticketsStatisticsOverTimeSlice.actions;

// Async stuff - sagas

function* fetchSeveritiesDistributionSaga(
  action: PayloadAction<Partial<QueryParams>>,
) {
  yield put(setIsSeveritiesDistributionPending());

  try {
    const response: AxiosResponse<StatisticsCountListResponse> = yield call(
      fetchSeveritiesStatisticsApi,
      action.payload,
    );
    yield put(setFetchSeveritiesDistributionSuccess(response.data.payload));
  } catch (e) {
    yield put(setSeveritiesDistributionFetchError());
  }
}

function* fetchSeveritiesDistributionOverTimeSaga(
  action: PayloadAction<Partial<QueryParams>>,
) {
  yield put(setIsSeveritiesDistributionOverTimePending());

  try {
    const response: AxiosResponse<StatisticsHistogramCountListResponse> =
      yield call(fetchSeveritiesStatisticsHistogramApi, action.payload);

    yield put(
      setFetchSeveritiesDistributionOverTimeSuccess(response.data.payload),
    );
  } catch (e) {
    yield put(setSeveritiesDistributionOverTimeFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchSeveritiesDistribution.toString(),
    fetchSeveritiesDistributionSaga,
  );
  yield takeLatest(
    fetchSeveritiesDistributionOverTime.toString(),
    fetchSeveritiesDistributionOverTimeSaga,
  );
}

export function* ticketsStatisticsSeveritySagas() {
  yield all([actionWatcher()]);
}
