export const profileKeys = {
  EMAIL: "email",
  NAME: "name",
  PHONE: "phone",
  MOBILE: "mobile",
  LAST_LOGIN: "last_login",
  OTP: "otp",
  TOKEN: "token",
  TOKEN_CREATED: "created",
  TOKEN_LAST_USED: "last_used",

  IS_SUPPOSEDLY_ADMIN: "is_supposedly_admin",

  ORGS: "organizations",
  HAS_ADMIN_USER_GROUP: "has_admin_user_group",
} as const;

export const passwordKeys = {
  OLD_PASSWORD: "old_password",
  NEW_PASSWORD: "new_password",
  NEW_PASSWORD_REPEAT: "new_password_repeat",
} as const;
