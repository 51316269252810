import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  Button,
  CodeBlock,
  CollapsableGroup,
  Grid,
  GridItem,
  Hideable,
  Icon,
  List,
  ListItem,
  ListItemLabel,
  ListItemValue,
  Stack,
  Tag,
  Toast,
} from "@csis.com/components";
import CountdownTimer from "@csis.com/tip/src/components/shared/CountdownTimer/CountdownTimer";
import DownloadAttachmentButton from "@csis.com/tip/src/components/shared/DownloadAttachmentButton/DownloadAttachmentButton";
import ErrorInfo from "@csis.com/tip/src/components/shared/ErrorInfo/ErrorInfo";
import Page from "@csis.com/tip/src/components/shared/Page/Page";
import { DateSpanWrapper as DateSpan } from "@csis.com/tip/src/components/wrappers/DateSpanWrapper";
import { useConfirm } from "@csis.com/tip/src/hooks/useConfirm";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import { secondsToDateHoursMinutesSecond } from "@csis.com/tip/src/utils/dateCalculations";
import urlTemplates from "../../../routes/urlTemplates";
import { emergencyCaseTypeLabels } from "../EmergencyCaseSearch/constants";
import {
  Status,
  StatusColors,
  StatusTranslationKeys,
  StatusValues,
} from "../models/Status";
import { Type } from "../models/Type";
import AcceptEmergencyCaseDialog from "./AcceptEmergencyCaseDialog/AcceptEmergencyCaseDialog";
import CloseEmergencyCaseDialog from "./CloseEmergencyCaseDialog/CloseEmergencyCaseDialog";
import DeclineEmergencyCaseDialog from "./DeclineEmergencyCaseDialog/DeclineEmergencyCaseDialog";
import EditEmergencyCaseAttachmentsDialog from "./EditEmergencyCaseAttachmentsDialog/EditEmergencyCaseAttacmentDialog";
import EditEmergencyCaseDialog from "./EditEmergencyCaseDialog/EditEmergencyCaseDialog";
import {
  getEmergencyCaseResult,
  getPostResendCaseLinkResult,
} from "./selectors";
import {
  downloadAttachment,
  fetchEmergencyCaseById,
  postResendCaseLink,
  resetEmergencyCaseState,
  resetPostCaseLinkState,
} from "./slice";
import {
  getEmergencyCaseAttachments,
  getSecondsToRetainerDeadline,
} from "./utils";

const questionaryVisibleStatuses: Status[] = [
  StatusValues.PENDING_CSIS,
  StatusValues.IN_PROGRESS,
  StatusValues.DECLINED,
  StatusValues.CLOSED,
];

type TQuestionnaireData = {
  description_brief?: string;
  discovery_details?: string;
  affected_stuff?: string;
  done_already?: string;
  csis_engagement?: string;
};

function useDialog(setIsEditActionCompleted: any) {
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleActionCompletedSuccessfully = useCallback(() => {
    setOpen(false);
    setIsEditActionCompleted(true);
  }, [setIsEditActionCompleted]);

  return {
    isOpen,
    handleOpen,
    handleClose,
    handleActionCompletedSuccessfully,
  };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const EmergencyCasePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const history = useHistory();
  const { id } = useParams() as {
    id?: string;
  };
  const { emergencyCase, isEmergencyCasePending, emergencyCaseFetchError } =
    useSelector(getEmergencyCaseResult);
  const [isEditActionCompleted, setIsEditActionCompleted] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchEmergencyCaseById(id));
    }
    return () => {
      dispatch(resetEmergencyCaseState());
    };
  }, [dispatch, id]);

  const editEmergencyCaseDialog = useDialog(setIsEditActionCompleted);
  const editRetainerAttachmentsDialog = useDialog(setIsEditActionCompleted);
  const acceptCaseDialog = useDialog(setIsEditActionCompleted);
  const declineCaseDialog = useDialog(setIsEditActionCompleted);
  const closeCaseDialog = useDialog(setIsEditActionCompleted);

  const attachments = useMemo(() => {
    return getEmergencyCaseAttachments(emergencyCase?.attachments);
  }, [emergencyCase?.attachments]);

  const handleDownloadAttachment = (
    emergencyCaseId: string,
    attachmentId: string,
    filename: string,
  ) => {
    dispatch(
      downloadAttachment({
        emergencyCaseId,
        attachmentId,
        filename,
      }),
    );
  };

  const handleBackAction = () => {
    history.push(urls.ir_cases_search);
  };

  const confirm = useConfirm();

  const {
    isPostResendCaseLinkPending,
    postResendCaseLinkError,
    postResendCaseLinkSuccess,
  } = useSelector(getPostResendCaseLinkResult);

  const handleResetCaseLink = useCallback(() => {
    dispatch(resetPostCaseLinkState());
  }, [dispatch]);

  const handleResendCaseLink = useCallback(() => {
    if (id) {
      confirm(
        {
          title: "Resend Case Link",
          details: "Are you sure you want to resend the Case Link?",
          mode: "neutral",
        },
        () => {
          dispatch(
            postResendCaseLink({
              erCaseExternalId: id,
              messageType: StatusValues.NEW,
            }),
          );
        },
      );
    }
  }, [confirm, dispatch, id]);

  const isQuestionaryDataVisible = questionaryVisibleStatuses.includes(
    emergencyCase?.emergency_response_case_status as Status,
  );

  const isActionsVisible =
    emergencyCase?.emergency_response_case_status === StatusValues.PENDING_CSIS;
  const isInProgress =
    emergencyCase?.emergency_response_case_status === StatusValues.IN_PROGRESS;
  const isDeclineDisabled = emergencyCase?.is_retainer_case;

  const questionnaire_data =
    emergencyCase?.questionnaire_data as TQuestionnaireData;
  const description_brief = questionnaire_data?.description_brief;
  const discovery_details = questionnaire_data?.discovery_details;
  const affected_stuff = questionnaire_data?.affected_stuff;
  const done_already = questionnaire_data?.done_already;
  const csis_engagement = questionnaire_data?.csis_engagement;

  if (emergencyCaseFetchError) {
    return (
      <ErrorInfo
        error={emergencyCaseFetchError}
        backAction={handleBackAction}
      />
    );
  }
  return (
    <Page>
      <Stack gutterSize="gigantic" isVertical>
        <Stack gutterSize="big" isVertical align="stretch">
          <Stack gutterSize="large" align="center" justify="space-between">
            <div className="f_secondary f_big f_semibold">
              Emergency Case Information
            </div>
            <Button
              icon="edit"
              type="text"
              text="Edit"
              onButtonClick={editEmergencyCaseDialog.handleOpen}
              name="edit-emergency-case-btn"
            />
          </Stack>
          <List>
            <ListItem>
              <ListItemLabel>Status:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                <Tag
                  size="small"
                  color={
                    StatusColors[
                      emergencyCase?.emergency_response_case_status as Status
                    ]
                  }
                  isUppercase
                >
                  {t(
                    StatusTranslationKeys[
                      emergencyCase?.emergency_response_case_status as Status
                    ],
                  )}
                </Tag>
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Assignee:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                {emergencyCase?.responsible?.name ||
                  emergencyCase?.responsible?.login ||
                  "-"}
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Type:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                {
                  emergencyCaseTypeLabels[
                    emergencyCase?.emergency_response_case_type as Type
                  ]
                }{" "}
                {emergencyCase?.is_retainer_case
                  ? "(Retainer case)"
                  : "(Non-retainer case)"}
              </ListItemValue>
            </ListItem>
            <CollapsableGroup label="Customer data">
              <ListItem>
                <ListItemLabel>Existing Customer:</ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  {emergencyCase?.business_unit?.full_name || "-"}
                </ListItemValue>
              </ListItem>
              {(emergencyCase?.new_customer_name && (
                <ListItem>
                  <ListItemLabel>New customer name:</ListItemLabel>
                  <ListItemValue isPending={isEmergencyCasePending}>
                    {emergencyCase?.new_customer_name || "-"}
                  </ListItemValue>
                </ListItem>
              )) || <></>}
              <ListItem>
                <ListItemLabel>Primary contact name:</ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  {emergencyCase?.contact_name}
                </ListItemValue>
              </ListItem>
              <ListItem verticalAlign="center">
                <ListItemLabel>Phone number:</ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  <Stack align="center" gutterSize="big">
                    <a href={"tel:" + emergencyCase?.contact_phone_number}>
                      {emergencyCase?.contact_phone_number}
                    </a>
                    {emergencyCase?.emergency_response_case_status ===
                      StatusValues.NEW && (
                      <Button
                        text={"Resend Case Link"}
                        size="small"
                        icon="send"
                        isIconAfterText
                        name="re-send-case-link-btn"
                        onButtonClick={handleResendCaseLink}
                        isLoading={isPostResendCaseLinkPending}
                      />
                    )}
                  </Stack>
                </ListItemValue>
              </ListItem>
              <ListItem>
                <ListItemLabel>Contact email:</ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  <a href={"mailto:" + emergencyCase?.contact_email}>
                    {emergencyCase?.contact_email}
                  </a>
                </ListItemValue>
              </ListItem>
            </CollapsableGroup>
            {emergencyCase?.is_retainer_case && (
              <ListItem>
                <ListItemLabel>
                  <Stack align="center">
                    <p>SLA:</p>
                    <Icon
                      kind="help_filled"
                      size="big"
                      color="secondary"
                      tooltipText="Retainer emergency response cases need to be started within 4 hours of case submission."
                    />
                  </Stack>
                </ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  {emergencyCase?.submitted_at &&
                    !emergencyCase?.decision_at && (
                      <CountdownTimer
                        dataTestId="retainer-case-countdown-timer"
                        stopAtZero={false}
                        initialCount={getSecondsToRetainerDeadline(
                          emergencyCase?.submitted_at,
                          undefined,
                        )}
                        tooltipText="Time remaining to start the case"
                      />
                    )}
                  {emergencyCase?.submitted_at &&
                    emergencyCase?.decision_at &&
                    secondsToDateHoursMinutesSecond(
                      getSecondsToRetainerDeadline(
                        emergencyCase?.submitted_at,
                        emergencyCase?.decision_at,
                      ),
                      false,
                    )}
                </ListItemValue>
              </ListItem>
            )}
            <ListItem>
              <ListItemLabel>Submission code:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                <Hideable>
                  <span className="f_large">
                    {emergencyCase?.submission_code}
                  </span>
                </Hideable>
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>
                <Stack align="center">
                  <p>Customer Submission URL:</p>
                  <Icon
                    kind="help_filled"
                    size="big"
                    color="secondary"
                    tooltipText={`This URL was sent to the customer via SMS and potentially email.
They need to enter the submission code to confirm the pricing and fill out \
the form to start the case.
It is shown here purely as a backup in case the customer has issues receiving it.`}
                  />
                </Stack>
              </ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                {/* remove trailing slash from the URL if it exists to make for a nice URL */}
                <CodeBlock
                  text={
                    emergencyCase
                      ? process.env.REACT_APP_CSIS_REDIRECT_URI?.replace(
                          /\/$/,
                          "",
                        ) +
                        urlTemplates._emergency_case_confirmation_id(
                          emergencyCase.external_id,
                        )
                      : ""
                  }
                  isCopyable
                />
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Case creation:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                <DateSpan mode="table" date={emergencyCase?.created_at} />
                {emergencyCase?.created_by
                  ? " by " + emergencyCase.created_by
                  : ""}
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Pricing confirmation:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                <DateSpan
                  mode="table"
                  date={emergencyCase?.submission_code_used_at}
                />
                {emergencyCase?.submission_code_used_at ? " by customer" : ""}
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Case submission:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                <DateSpan mode="table" date={emergencyCase?.submitted_at} />
                {emergencyCase?.submitted_at ? " by customer" : ""}
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Case acknowledgment:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                <DateSpan mode="table" date={emergencyCase?.acknowledged_at} />
                {emergencyCase?.acknowledged_at ? " by tiger team" : ""}
              </ListItemValue>
            </ListItem>
            <ListItem>
              <ListItemLabel>Case decision:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                <DateSpan mode="table" date={emergencyCase?.decision_at} />
                {emergencyCase?.decision_by
                  ? " by " + emergencyCase.decision_by
                  : ""}
              </ListItemValue>
            </ListItem>
          </List>
        </Stack>

        {isQuestionaryDataVisible && (
          <Stack gutterSize="big" isVertical align="stretch">
            {isActionsVisible && (
              <Stack gutterSize="big">
                <Button
                  text={"Decline Case"}
                  onButtonClick={declineCaseDialog.handleOpen}
                  name="dialog-ok-btn"
                  isDisabled={isDeclineDisabled}
                  color="red"
                  isFullWidth
                  icon="close"
                  type="primary"
                />
                <Button
                  text={isDeclineDisabled ? "Start Case" : "Accept Case"}
                  onButtonClick={acceptCaseDialog.handleOpen}
                  name="dialog-ok-btn"
                  isFullWidth
                  icon="check"
                  type="primary"
                />
              </Stack>
            )}
            {isInProgress && (
              <Grid gutterSize="big" justify="center">
                <GridItem span={6}>
                  <Button
                    size="small"
                    text={"Close case"}
                    onButtonClick={closeCaseDialog.handleOpen}
                    name="case-close-btn"
                    color="red"
                    isFullWidth
                  />
                </GridItem>
              </Grid>
            )}
            <Stack gutterSize="large" align="center" justify="space-between">
              <Stack align="center">
                <div className="f_secondary f_big f_semibold">
                  Submitted data
                </div>
              </Stack>
            </Stack>

            <List>
              <ListItem>
                <ListItemLabel>Description:</ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  <pre>{description_brief}</pre>
                </ListItemValue>
              </ListItem>
              <ListItem>
                <ListItemLabel>Discover details:</ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  <pre>{discovery_details}</pre>
                </ListItemValue>
              </ListItem>
              <ListItem>
                <ListItemLabel>Affected stuff:</ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  <pre>{affected_stuff}</pre>
                </ListItemValue>
              </ListItem>
              <ListItem>
                <ListItemLabel>Done already:</ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  <pre>{done_already}</pre>
                </ListItemValue>
              </ListItem>
              <ListItem>
                <ListItemLabel>CSIS engagement:</ListItemLabel>
                <ListItemValue isPending={isEmergencyCasePending}>
                  <pre>{csis_engagement}</pre>
                </ListItemValue>
              </ListItem>
            </List>
          </Stack>
        )}
        <Stack gutterSize="big" isVertical align="stretch">
          <Stack gutterSize="large" align="center" justify="space-between">
            <Stack align="center">
              <div className="f_secondary f_big f_semibold">
                Case Attachments
              </div>
            </Stack>
            <Button
              icon="edit"
              type="text"
              text="Edit"
              onButtonClick={editRetainerAttachmentsDialog.handleOpen}
              name="edit-retainer-attachment-btn"
            />
          </Stack>

          <List>
            <ListItem>
              <ListItemLabel>Attachments:</ListItemLabel>
              <ListItemValue isPending={isEmergencyCasePending}>
                {attachments.length
                  ? attachments.map((attachment) => (
                      <DownloadAttachmentButton
                        key={attachment.id}
                        id={attachment.id}
                        fileName={attachment.filename}
                        fileSize={attachment.size}
                        onClick={() =>
                          handleDownloadAttachment(
                            emergencyCase?.external_id || "",
                            attachment.id,
                            attachment.filename,
                          )
                        }
                      />
                    ))
                  : "No files attached"}
              </ListItemValue>
            </ListItem>
          </List>
        </Stack>
      </Stack>
      {emergencyCase && acceptCaseDialog.isOpen && (
        <AcceptEmergencyCaseDialog
          onClose={acceptCaseDialog.handleClose}
          onSuccess={acceptCaseDialog.handleActionCompletedSuccessfully}
          caseId={emergencyCase?.external_id}
        />
      )}
      {emergencyCase && declineCaseDialog.isOpen && (
        <DeclineEmergencyCaseDialog
          onClose={declineCaseDialog.handleClose}
          onSuccess={declineCaseDialog.handleActionCompletedSuccessfully}
          caseId={emergencyCase?.external_id}
          contactName={emergencyCase?.contact_name}
        />
      )}
      {editEmergencyCaseDialog.isOpen && (
        <EditEmergencyCaseDialog
          onDialogClose={editEmergencyCaseDialog.handleClose}
          onSuccess={editEmergencyCaseDialog.handleActionCompletedSuccessfully}
        />
      )}
      {emergencyCase && closeCaseDialog.isOpen && (
        <CloseEmergencyCaseDialog
          onClose={closeCaseDialog.handleClose}
          onSuccess={closeCaseDialog.handleActionCompletedSuccessfully}
          caseId={emergencyCase?.external_id}
        />
      )}

      {editRetainerAttachmentsDialog.isOpen && (
        <EditEmergencyCaseAttachmentsDialog
          onDialogClose={editRetainerAttachmentsDialog.handleClose}
          onSuccess={
            editRetainerAttachmentsDialog.handleActionCompletedSuccessfully
          }
        />
      )}

      <Toast
        isShown={isEditActionCompleted}
        onHide={() => setIsEditActionCompleted(false)}
        icon="check"
        text={"Emergency case successfully updated"}
      />

      <Toast
        color={undefined}
        isShown={postResendCaseLinkSuccess}
        onHide={() => handleResetCaseLink()}
        icon="check"
        text="Case link resent successfully"
      />

      <Toast
        color="red"
        isShown={Boolean(postResendCaseLinkError)}
        onHide={() => handleResetCaseLink()}
        icon="warning"
        text={postResendCaseLinkError}
      />
    </Page>
  );
};
