import { History } from "history";
import { ORG_ID_PARAM_KEY } from "../MainApp";

// given a url and wether the ctrl key is pressed or not,
// it either navigates to that or opens it in a new tab
export function navigateOrOpenNewTab(
  url: string,
  history: History<unknown>,
  isCtrlKeyPressed?: boolean,
  selectedOrgId?: string,
) {
  // add the selectedOrgId to the url if it is provided
  if (selectedOrgId) {
    url += `?${ORG_ID_PARAM_KEY}=${selectedOrgId}`;
  }

  if (isCtrlKeyPressed) {
    window.open(url, "_blank");
  } else {
    history.push(url);
  }
}
