import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SelectedOnboardingStep } from "./types";

interface StateSlice {
  selectedOnboardingStep: SelectedOnboardingStep;
  isTutorialDialogDismissedByUser: boolean;
}
const initialState: StateSlice = {
  selectedOnboardingStep: null,
  isTutorialDialogDismissedByUser: false,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: initialState,
  reducers: {
    setOnboardingStep(state, action: PayloadAction<SelectedOnboardingStep>) {
      state.selectedOnboardingStep = action.payload;
    },
    setIsTutorialDialogDismissedByUser(state) {
      state.isTutorialDialogDismissedByUser = true;
    },
  },
});

export default onboardingSlice.reducer;

export const { setOnboardingStep, setIsTutorialDialogDismissedByUser } =
  onboardingSlice.actions;
