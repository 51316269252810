import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";
import { AccessibleOrganizations } from "../../../api/openapi/data-contracts";
import { profileKeys } from "./constants";

export const getProfile = (state: RootState) => state.profileReducer.profile;

export const getIsProfilePending = (state: RootState) =>
  state.profileReducer.isFetchProfilePending;

export const getProfileFetchError = (state: RootState) =>
  state.profileReducer.profileFetchError;

export const getProfileFetchErrorStatus = (state: RootState) =>
  state.profileReducer.profileFetchErrorStatus;

export const getProfileResult = createSelector(
  [
    getProfile,
    getIsProfilePending,
    getProfileFetchError,
    getProfileFetchErrorStatus,
  ],
  (
    profile,
    isFetchProfilePending,
    profileFetchError,
    profileFetchErrorStatus,
  ) => ({
    profile,
    isFetchProfilePending,
    profileFetchError,
    profileFetchErrorStatus,
  }),
);

export const getProfilePatchSuccess = (state: RootState) =>
  state.profileReducer.profilePatchSuccess;

export const getIsPatchProfilePending = (state: RootState) =>
  state.profileReducer.isPatchProfilePending;

export const getProfilePatchError = (state: RootState) =>
  state.profileReducer.profilePatchError;

export const getProfilePatchResult = createSelector(
  [getProfilePatchSuccess, getIsPatchProfilePending, getProfilePatchError],
  (profilePatchSuccess, isPatchProfilePending, profilePatchError) => {
    return { profilePatchSuccess, isPatchProfilePending, profilePatchError };
  },
);

export const getPasswordPatchSuccess = (state: RootState) =>
  state.profileReducer.passwordPatchSuccess;

export const getIsPatchPasswordPending = (state: RootState) =>
  state.profileReducer.isPatchPasswordPending;

export const getPasswordPatchError = (state: RootState) =>
  state.profileReducer.passwordPatchError;

export const getPasswordPatchResult = createSelector(
  [getPasswordPatchSuccess, getIsPatchPasswordPending, getPasswordPatchError],
  (passwordPatchSuccess, isPatchPasswordPending, passwordPatchError) => {
    return { passwordPatchSuccess, isPatchPasswordPending, passwordPatchError };
  },
);

export const getCreateTokenResult = (state: RootState) => {
  return {
    createTokenSuccess: state.profileReducer.createTokenSuccess,
    isCreateTokenPending: state.profileReducer.isCreateTokenPending,
    createTokenError: state.profileReducer.createTokenError,
  };
};

export const getDisableTokenResult = (state: RootState) => {
  return {
    disableTokenSuccess: state.profileReducer.disableTokenSuccess,
    isDisableTokenPending: state.profileReducer.isDisableTokenPending,
    disableTokenError: state.profileReducer.disableTokenError,
  };
};

export const getOtpInfoResult = (state: RootState) => {
  return {
    otpInfo: state.profileReducer.otpInfo,
    isDisableTokenPending: state.profileReducer.isFetchOtpInfoPending,
    disableTokenError: state.profileReducer.otpInfoFetchError,
  };
};

export const getGenerateOtpResult = (state: RootState) => {
  return {
    generateOtpSuccess: state.profileReducer.generateOtpSuccess,
    isGenerateOtpPending: state.profileReducer.isGenerateOtpPending,
    generateOtpError: state.profileReducer.generateOtpError,
  };
};

export const getOrgs = (state: RootState) =>
  state.profileReducer.profile?.[profileKeys.ORGS];

export const getSelectedOrgId = (state: RootState) => {
  const defaultSelectedOrgId =
    state.profileReducer.profile?.[profileKeys.ORGS]?.[0]?.external_id;
  return state.profileReducer.selectedOrgId || defaultSelectedOrgId;
};

export const getSelectedOrg = (state: RootState) => {
  const defaultSelectedOrg =
    state.profileReducer.profile?.[profileKeys.ORGS]?.[0];
  return state.profileReducer.selectedOrgId
    ? state.profileReducer.profile?.[profileKeys.ORGS]?.find(
        (org) => org.external_id === state.profileReducer.selectedOrgId,
      )
    : defaultSelectedOrg;
};

export const getUserSubscribedProductsNew = createSelector(
  [getSelectedOrgId, getOrgs],
  (selectedOrgId, orgs) => {
    if (orgs?.length && selectedOrgId) {
      return orgs.find((org) => org.external_id === selectedOrgId)?.services;
    }
    return undefined;
  },
);

export const getAllValidOrgIdsHelper = (
  orgs: AccessibleOrganizations | undefined,
) => {
  const allOrgs = [...(orgs || [])];
  return allOrgs.map((org) => org.external_id);
};

export const getAllValidOrgIds = createSelector(
  [getOrgs],
  getAllValidOrgIdsHelper,
);

export const getDefaultOrgIdHelper = (
  orgs: AccessibleOrganizations | undefined,
) => {
  if (orgs && orgs.length > 0) {
    return orgs[0]?.external_id;
  }
  return undefined;
};

export const getDefaultOrgId = createSelector([getOrgs], getDefaultOrgIdHelper);
