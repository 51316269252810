import classNames from "classnames";
import React from "react";
import { getDateDifferenceInDaysBetweenTwoDates } from "../../utils/utils";
import HeatLegend from "./HeatLegend";
import {
  DAYS_IN_WEEK,
  GUTTER_SIZE,
  MILLISECONDS_IN_ONE_DAY,
  MINIMUM_HEATCALENDAR_LENGTH_DAYS,
  MONTH_LABEL_GUTTER_SIZE,
  SQUARE_SIZE,
} from "./constants";
import {
  calculateStartDateAsOneYearAgo,
  calculateStartDateAsThreeMonthAgo,
  convertToDate,
  getBeginningTimeForDate,
  getHeatLevelBasesOnCount,
  getHeatLevelRanges,
  getRange,
  shiftDate,
} from "./utils";

export type HeatCalendarValue = {
  date: string;
  count?: number;
  image?: string;
}; // { date: '2016-01-01', count: 1 }

// it is the devs responsibility to provide days, that are between the start date and an end date that are
// also to provide well formated days and the start day should be less than the end date.
export interface HeatCalendarInterface {
  values: Array<HeatCalendarValue>;
  selectedDay?: string; // YYYY-MM-DD
  endDateString: string; // YYYY-MM-DD
  startDateString?: string | null; // YYYY-MM-DD
  onSelectDay: (value: HeatCalendarValue | null) => void;
}

const HeatCalendar: React.FC<HeatCalendarInterface> = ({
  values,
  selectedDay,
  endDateString,
  startDateString,
  onSelectDay,
}) => {
  // Date calculations
  const endDate = convertToDate(endDateString);
  let startDate = startDateString
    ? convertToDate(startDateString)
    : calculateStartDateAsOneYearAgo(endDate);

  // always get 1 year behind - 365 days (for now) if no start date is provided
  // if start date is provided, it cannot be more than 1 year ago of the end date
  // but also for display purposes, it cannot be less than 1 month ago
  if (
    getDateDifferenceInDaysBetweenTwoDates(endDate, startDate) <
    MINIMUM_HEATCALENDAR_LENGTH_DAYS
  ) {
    startDate = calculateStartDateAsThreeMonthAgo(endDate);
  }
  if (getDateDifferenceInDaysBetweenTwoDates(endDate, startDate) > 366) {
    startDate = calculateStartDateAsOneYearAgo(endDate);
  }

  // if count is passed in the values, find the max and generate heat level ranges
  // check if i should memo it
  const maxCount = Math.max(...values.map((v) => v?.count || 0));
  const ranges = getHeatLevelRanges(maxCount);

  function classForValue(value: HeatCalendarValue) {
    if (selectedDay && value.date === selectedDay) {
      return "heatcalendar__day heatcalendar__day-with-content--selected";
    }
    let classes = "heatcalendar__day heatcalendar__day-with-content";

    // if there is a count, give it a class that shows a gradient based on how big the count is
    const count = value.count;
    if (count) {
      classes += " " + getHeatLevelBasesOnCount(count, ranges);
    }

    return classes;
  }

  const titleForValue = (value: HeatCalendarValue) => `${value.date}`;

  function getValueCache(values: Array<HeatCalendarValue>) {
    return values.reduce((memo, value) => {
      const date = convertToDate(value.date);
      const index = Math.floor(
        (date.valueOf() - getStartDateWithEmptyDays().valueOf()) /
          MILLISECONDS_IN_ONE_DAY,
      );

      // @ts-ignore
      memo[index] = {
        value,
        className: classForValue(value),
        title: titleForValue(value),
        image: value.image,
      };
      return memo;
    }, {});
  }

  const valueCache: {
    [key: number]: {
      value: HeatCalendarValue;
      className: string;
      title: string;
    };
  } = getValueCache(values);

  function getValueForIndex(index: number) {
    if (valueCache[index]) {
      return valueCache[index]?.value;
    }
    return null;
  }

  function getClassNameForIndex(index: number) {
    if (valueCache[index]) {
      return valueCache[index]?.className;
    }
    return "heatcalendar__day";
  }

  function getSquareSizeWithGutter() {
    return SQUARE_SIZE + GUTTER_SIZE;
  }

  function getStartDate() {
    return shiftDate(getEndDate(), -getDateDifferenceInDays() + 0);
  }

  function getEndDate() {
    return shiftDate(getBeginningTimeForDate(convertToDate(endDate)), 1); // +1 because endDate is inclusive
  }

  function getDateDifferenceInDays() {
    const timeDiff =
      getEndDate().valueOf() - convertToDate(startDate).valueOf();
    return Math.ceil(timeDiff / MILLISECONDS_IN_ONE_DAY);
  }

  function getNumEmptyDaysAtStart() {
    return getStartDate().getDay();
  }

  function getNumEmptyDaysAtEnd() {
    return DAYS_IN_WEEK - 1 - getEndDate().getDay();
  }

  function getWeekCount() {
    const numDaysRoundedToWeek =
      getDateDifferenceInDays() +
      getNumEmptyDaysAtStart() +
      getNumEmptyDaysAtEnd();
    return Math.ceil(numDaysRoundedToWeek / DAYS_IN_WEEK);
  }

  function getMonthLabelSize() {
    return SQUARE_SIZE + MONTH_LABEL_GUTTER_SIZE;
  }

  function getStartDateWithEmptyDays() {
    return shiftDate(getStartDate(), -getNumEmptyDaysAtStart());
  }

  function getMonthLabelCoordinates(weekIndex: number) {
    return [
      weekIndex * getSquareSizeWithGutter(),
      getMonthLabelSize() - MONTH_LABEL_GUTTER_SIZE,
    ];
  }

  function renderMonthLabels() {
    const weekRange = getRange(getWeekCount() - 1); // don't render for last week, because label will be cut off
    return weekRange.map((weekIndex) => {
      const endOfWeek = shiftDate(
        getStartDateWithEmptyDays(),
        (weekIndex + 1) * DAYS_IN_WEEK,
      );
      const [x] = getMonthLabelCoordinates(weekIndex);
      return endOfWeek.getDate() >= 1 && endOfWeek.getDate() <= DAYS_IN_WEEK ? (
        <div
          key={weekIndex}
          style={{ left: x + "px" }}
          className={`heatcalendar__month-label`}
        >
          {endOfWeek.toLocaleString("default", { month: "short" })}
        </div>
      ) : null;
    });
  }

  function getTooltipForIndex(index: number) {
    if (valueCache[index]) {
      // return custom things

      return (
        <>
          {valueCache?.[index]?.value?.image && (
            <div className="heatcalendar__tooltip-image-container">
              <img
                src={valueCache?.[index]?.value.image}
                alt="tooltip preview"
              />
            </div>
          )}
          <div>{valueCache?.[index]?.title}</div>
        </>
      );
    }
    return "No entries";
  }

  function renderSquare(_dayIndex: number, index: number) {
    const indexOutOfRange =
      index < getNumEmptyDaysAtStart() ||
      index >= getNumEmptyDaysAtStart() + getDateDifferenceInDays();
    if (indexOutOfRange) {
      return null;
    }

    const value = getValueForIndex(index);

    return (
      <div style={{ position: "relative" }} key={index}>
        <div
          style={{ width: SQUARE_SIZE, height: SQUARE_SIZE }}
          className={getClassNameForIndex(index)}
          onClick={() => {
            onSelectDay(value || null);
          }}
          data-test-id={"heatcalendar-rectangle-" + index}
        />
        <div className="heatcalendar__tooltip">{getTooltipForIndex(index)}</div>
      </div>
    );
  }

  function renderWeek(weekIndex: number) {
    const classes = classNames("heatcalendar__week", {
      "heatcalendar__week--first": weekIndex === 0,
    });
    return (
      <div key={weekIndex} className={classes}>
        {getRange(DAYS_IN_WEEK).map((dayIndex) =>
          renderSquare(dayIndex, weekIndex * DAYS_IN_WEEK + dayIndex),
        )}
      </div>
    );
  }

  function renderAllWeeks() {
    return getRange(getWeekCount()).map((weekIndex) => renderWeek(weekIndex));
  }

  const classes = classNames("heatcalendar", {});

  return (
    <div className="heatcalendar__container">
      <div className={classes}>
        <div className={`heatcalendar__month-labels`}>
          {renderMonthLabels()}
        </div>
        <div className={`heatcalendar__all-weeks`}>{renderAllWeeks()}</div>
      </div>
      <div
        className="f_small f_semibold width_100pct f_center"
        data-test-id="heatcalendar-selected-day-div"
      >{`Selected date: ${selectedDay}`}</div>
      {maxCount > 0 && <HeatLegend ranges={ranges} maxCount={maxCount} />}
    </div>
  );
};

export default HeatCalendar;
