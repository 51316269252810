import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ListResponseASNPreview } from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { AsnEntry } from "./Asn";
import { fetchAsnsApi } from "./api/api";

const asnsSlice = createAsyncArrayFetchSlice<AsnEntry, string[]>("asns");

export default asnsSlice.reducer;

export const {
  fetchData: fetchAsns,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = asnsSlice.actions;

// Async stuff - sagas

function* fetchAsnsSaga(action: PayloadAction<string[]>) {
  yield put(setIsPending());
  const countries = action.payload;
  try {
    const response: AxiosResponse<ListResponseASNPreview> = yield call(
      fetchAsnsApi,
      countries,
    );

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    yield put(setFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchAsns.toString(), fetchAsnsSaga);
}

export function* phishingAsnsSagas() {
  yield all([actionWatcher()]);
}
