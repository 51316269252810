import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Icon from "../../icons/Icon";
import Stack from "../../layout/Stack/Stack";
import { GREY, RED } from "../../colors";
import { RADIUS_BIG, RADIUS_NORMAL } from "../../radius";
import { NORMAL, SMALL } from "../../sizes";
import { truncateText } from "../../utils/utils";
import IconButton from "../IconButton/IconButton";
import { MINIMUM_CHARS_TO_TRUNCATE } from "./constants";

const Actions = ({
  isCopyable,
  isDownloadable,
  isCollapsable,
  isCollapsed,
  onCollapseToggle,
  text,
}: {
  isCopyable?: boolean;
  isDownloadable?: boolean;
  isCollapsable?: boolean;
  isCollapsed?: boolean;
  onCollapseToggle: () => void;
  text: string;
}) => {
  const [copyTextFired, setCopyTextFired] = useState<boolean>(false);

  useEffect(() => {
    // after a text is copied and we show the succcess message,
    // reset the button icon to copy again after x seconds
    if (copyTextFired) {
      setTimeout(() => {
        setCopyTextFired(false);
      }, 6000);
    }
  }, [copyTextFired]);

  const handleCopyText = () => {
    // this copies the text to the users clipboard
    navigator.clipboard.writeText(text);
    setCopyTextFired(true);
  };

  const handleDownloadText = () => {
    // Code to download the text as a .txt file
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text),
    );
    element.setAttribute("download", "plaintext.txt");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div className="codeblock__actions">
      <Stack align="center">
        {isCopyable && copyTextFired && (
          <Icon
            size="large"
            kind="check"
            color={"secondary"}
            tooltipText="Copied to clipboard"
          />
        )}
        {isCopyable && !copyTextFired && (
          <IconButton
            icon="copy"
            isCircle
            tooltipText="Copy"
            color={"secondary"}
            type="text"
            spacing="tiny"
            onButtonClick={handleCopyText}
          />
        )}
        {isDownloadable && (
          <IconButton
            icon="download"
            isCircle
            tooltipText="Download as plaintext"
            color={"secondary"}
            type="text"
            spacing="tiny"
            onButtonClick={handleDownloadText}
          />
        )}
        {isCollapsable && (
          <IconButton
            icon={isCollapsed ? "unfold_more" : "unfold_less"}
            isCircle
            tooltipText="Collapse"
            color={"secondary"}
            type="text"
            spacing="tiny"
            onButtonClick={onCollapseToggle}
          />
        )}
      </Stack>
    </div>
  );
};

export interface CodeBlockInterface {
  text: string;
  isMultiline?: boolean;
  isCopyable?: boolean;
  isDownloadable?: boolean;
  isCollapsable?: boolean;
  isFullWidth?: boolean;
  isPreWrap?: boolean; // wrap the preformatted text
  color?: RED | GREY;
  size?: SMALL | NORMAL;
  radius?: RADIUS_BIG | RADIUS_NORMAL;
  maxChars?: number; //used to truncate data. use more than 6
}

const CodeBlock: React.FC<CodeBlockInterface> = ({
  text,
  isMultiline,
  isCopyable,
  isDownloadable,
  isCollapsable,
  color = "red",
  size,
  isPreWrap,
  isFullWidth,
  radius = "normal",
  maxChars,
}) => {
  const classes = classNames("codeblock", {
    "codeblock--multiline": isMultiline || text.includes("\n"),
    "codeblock--fullwidth": isFullWidth,
    "codeblock--grey": color === "grey",
    "codeblock--red": color === "red",
    "codeblock--small": size === "small",
    "codeblock--radius-big": radius === "big",
    "codeblock--prewrap": isPreWrap,
  });

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const actions = (isDownloadable || isCopyable || isCollapsable) && (
    <Actions
      text={text}
      isCopyable={isCopyable}
      isDownloadable={isDownloadable}
      isCollapsable={isCollapsable}
      isCollapsed={isCollapsed}
      onCollapseToggle={() => setIsCollapsed(!isCollapsed)}
    />
  );

  const content =
    maxChars && maxChars > MINIMUM_CHARS_TO_TRUNCATE ? (
      <pre tabIndex={0}>{truncateText({ text, maxChars })}</pre>
    ) : (
      <pre tabIndex={0}>{text}</pre>
    );

  return (
    <div className={classes} title={maxChars ? text : undefined}>
      <Stack align="center" justify={isFullWidth ? "space-between" : "left"}>
        {!isCollapsed ? content : null}
        {actions}
      </Stack>
    </div>
  );
};

export default CodeBlock;
