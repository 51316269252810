import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@csis.com/tip/src/configureStore";

export const getBusinessUnits = (state: RootState) =>
  state.businessUnitReducer.businessUnits;
export const getIsBusinessUnitsPending = (state: RootState) =>
  state.businessUnitReducer.isBusinessUnitsPending;
export const getBusinessUnitsError = (state: RootState) =>
  state.businessUnitReducer.businessUnitsFetchError;

export const getBusinessUnitsWithRetainers = (state: RootState) =>
  state.businessUnitReducer.businessUnitsWithRetainer;

export const getBusinessUnitsResult = createSelector(
  [
    getBusinessUnits,
    getBusinessUnitsWithRetainers,
    getIsBusinessUnitsPending,
    getBusinessUnitsError,
  ],
  (
    businessUnits,
    businessUnitsWithRetainer,
    isBusinessUnitsPending,
    businessUnitsFetchError,
  ) => {
    return {
      businessUnits,
      businessUnitsWithRetainer,
      isBusinessUnitsPending,
      businessUnitsFetchError,
    };
  },
);
