import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { CSIS, GREY } from "../../colors";
import { AccordionItemBodyInterface } from "./AccordionItemBody";
import { AccordionItemHeaderInterface } from "./AccordionItemHeader";

export interface AccordionItemInterface {
  isOpen?: boolean;
  headerGutterSize?: "big" | "space-between";
  isTransparent?: boolean; // passed by the parent! do not pass manually
  headerColor?: CSIS | GREY; // passed by the parent! do not pass manually
  children: React.ReactNode;
}

const AccordionItem: React.FC<AccordionItemInterface> = ({
  isOpen,
  children,
  headerGutterSize = "big",
  isTransparent,
  headerColor,
}) => {
  const [isItemOpen, setIsItemOpen] = useState(isOpen);

  useEffect(() => {
    setIsItemOpen(isOpen);
  }, [isOpen]);

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(
      child as React.ReactElement<
        AccordionItemHeaderInterface | AccordionItemBodyInterface
      >,
      {
        isItemOpen: isItemOpen,
        setIsItemOpen: setIsItemOpen,
        headerGutterSize: headerGutterSize,
        isTransparent: isTransparent,
        headerColor: headerColor,
      },
    );
  });

  const classes = classNames("accordion__item", {
    "accordion__item--transparent": isTransparent,
  });
  return <div className={classes}>{childrenWithProps}</div>;
};

export default AccordionItem;
