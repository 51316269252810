import { Color } from "@csis.com/components/src/colors";
import { IconKind } from "@csis.com/components/src/icons/Icon";
import { Guard, isArrayOf } from "@csis.com/tip/src/models/helpers";
import { TranslationKey } from "@csis.com/tip/src/translations/types";

export const StatusValues = {
  NEW: "new",
  PENDING_CSIS: "pending-csis",
  IN_PROGRESS: "in-progress",
  CLOSED: "closed",
  DECLINED: "declined",
  EXPIRED: "expired",
} as const;

export type Status = (typeof StatusValues)[keyof typeof StatusValues];

export const StatusLabels: { [key in Status]: string } = {
  [StatusValues.NEW]: "New",
  [StatusValues.IN_PROGRESS]: "In Progress",
  [StatusValues.PENDING_CSIS]: "Pending CSIS",
  [StatusValues.CLOSED]: "Closed",
  [StatusValues.DECLINED]: "Declined",
  [StatusValues.EXPIRED]: "Expired",
} as const;

export type StatusLabel = (typeof StatusLabels)[keyof typeof StatusLabels];

export const isStatus = (x: any): x is Status =>
  Object.values(StatusValues).includes(x);

export const isStatusLabel = (x: any): x is StatusLabel =>
  Object.values(StatusLabels).includes(x);

export const isArrayOfStatuses: Guard<Status[]> = isArrayOf(isStatus);

// this is the part we localize
export const StatusTranslationKeys: { [key in Status]: TranslationKey } = {
  [StatusValues.NEW]: "new",
  [StatusValues.IN_PROGRESS]: "in_progress",
  [StatusValues.PENDING_CSIS]: "pending_csis",
  [StatusValues.CLOSED]: "closed",
  [StatusValues.DECLINED]: "declined",
  [StatusValues.EXPIRED]: "expired",
} as const;

export const StatusIcons: { [key in Status]: IconKind } = {
  [StatusValues.NEW]: "new",
  [StatusValues.IN_PROGRESS]: "in_progress",
  [StatusValues.PENDING_CSIS]: "awaiting_csis_reply",
  [StatusValues.CLOSED]: "closed",
  [StatusValues.DECLINED]: "warning",
  [StatusValues.EXPIRED]: "warning",
} as const;

export const StatusColors: { [key in Status]: Color } = {
  [StatusValues.NEW]: "orange",
  [StatusValues.IN_PROGRESS]: "blue",
  [StatusValues.PENDING_CSIS]: "orange",
  [StatusValues.CLOSED]: "green",
  [StatusValues.DECLINED]: "red",
  [StatusValues.EXPIRED]: "red",
} as const;
