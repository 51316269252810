import { createSelector } from "@reduxjs/toolkit";
import { getNewColumnsBasedOnSort } from "@csis.com/tip/src/components/shared/DataTableContainer/utils";
import { RootState } from "@csis.com/tip/src/configureStore";
import { CardsColumns } from "./Table/columns";

export const getCompromisedData = (state: RootState) =>
  state.compromisedDataCCReducer.compromisedData;

export const getIsCompromisedDataPending = (state: RootState) =>
  state.compromisedDataCCReducer.isCompromisedDataPending;

export const getCompromisedDataFetchError = (state: RootState) =>
  state.compromisedDataCCReducer.compromisedDataFetchError;

export const getCompromisedDataResult = createSelector(
  [
    getCompromisedData,
    getIsCompromisedDataPending,
    getCompromisedDataFetchError,
  ],
  (compromisedData, isCompromisedDataPending, compromisedDataFetchError) => {
    return {
      compromisedData,
      isCompromisedDataPending,
      compromisedDataFetchError,
    };
  },
);

export const getCompromisedDataColumns = (state: RootState) =>
  state.compromisedDataCCReducer.columns;

export const getCalculatedColumns = (
  state: RootState,
  sort?: string,
): CardsColumns => {
  const columns = getCompromisedDataColumns(state);
  return getNewColumnsBasedOnSort(columns, sort);
};

export const getHasNextPage = (state: RootState) =>
  state.compromisedDataCCReducer.hasNextPage;

export const getIsCsvExportPending = (state: RootState) =>
  state.compromisedDataCCReducer.isCsvExportPending;

export const getBulkActionSuccess = (state: RootState) =>
  state.compromisedDataCCReducer.bulkUpdateSuccess;

export const getBulkActionError = (state: RootState) =>
  state.compromisedDataCCReducer.bulkUpdateError;

export const getIsBulkActionPending = (state: RootState) =>
  state.compromisedDataCCReducer.isBulkUpdatePending;
