import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  PortalsFrontendApiOrganizationModelsEscalationContactPreview as EscalationContactPreview,
  PortalsFrontendApiOrganizationModelsEscalationContactListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { fetchEscalationContactsApi } from "./api/api";

const escalationContactsInternalSlice = createAsyncArrayFetchSlice<
  EscalationContactPreview,
  string
>("escalationContactsInternal");

export const escalationContactsInternalReducer =
  escalationContactsInternalSlice.reducer;

export const {
  fetchData: fetchEscalationContacts,
  setIsPending: setIsEscalationContactsFetchPending,
  setFetchError: setEscalationContactsFetchError,
  setFetchSuccess: setFetchEscalationContactsSuccess,
} = escalationContactsInternalSlice.actions;

function* fetchEscalationContactsSaga(action: PayloadAction<string>) {
  yield put(setIsEscalationContactsFetchPending());

  try {
    const response: AxiosResponse<PortalsFrontendApiOrganizationModelsEscalationContactListResponse> =
      yield call(fetchEscalationContactsApi, action.payload);
    yield put(setFetchEscalationContactsSuccess(response.data.payload));
  } catch (e) {
    yield put(setEscalationContactsFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchEscalationContacts.toString(),
    fetchEscalationContactsSaga,
  );
}

export function* escalationContactsInternalSagas() {
  yield all([actionWatcher()]);
}
