import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Form,
  GenericFormError,
  InputFile,
  Stack,
  Toast,
} from "@csis.com/components";
import {
  getDeleteEmergencyCaseAttachmentResult,
  getEmergencyCase,
  getUpdateEmergencyCaseAttachmentResult,
} from "../selectors";
import {
  deleteEmergencyCaseAttachmentById,
  resetDeleteEmergencyCaseAttachmentState,
  resetUpdateEmergencyCaseAttachmentsState,
  updateEmergencyCaseAttachments,
} from "../slice";
import { getEmergencyCaseAttachments } from "../utils";

const EditEmergencyCaseAttachmentsDialog = ({
  onDialogClose,
  onSuccess,
}: {
  onDialogClose: () => void;
  onSuccess: () => void;
}) => {
  const dispatch = useDispatch();
  const emergencyCase = useSelector(getEmergencyCase);
  const [isAttachmentsActionCompleted, setIsAttachmentsActionCompleted] =
    useState<boolean>(false);
  const [actionCompleteText, setActionCompleteText] = useState<string>("");

  const {
    emergencyCaseAttachmentUpdateSuccess,
    isEmergencyCaseAttachmentUpdating,
    emergencyCaseAttachmentUpdateError,
  } = useSelector(getUpdateEmergencyCaseAttachmentResult);
  const {
    emergencyCaseAttachmentDeleteSuccess,
    isEmergencyCaseAttachmentDeleting,
    emergencyCaseAttachmentDeleteError,
  } = useSelector(getDeleteEmergencyCaseAttachmentResult);

  const attachments = useMemo(() => {
    return getEmergencyCaseAttachments(emergencyCase?.attachments);
  }, [emergencyCase?.attachments]);

  const handleFileUpload = (files: FileList) => {
    if (emergencyCase?.external_id) {
      dispatch(
        updateEmergencyCaseAttachments({
          caseId: emergencyCase?.external_id,
          files: Array.from(files),
        }),
      );
    }
  };

  const handleFileRemove = (fileId: string) => {
    if (emergencyCase?.external_id) {
      dispatch(
        deleteEmergencyCaseAttachmentById({
          caseId: emergencyCase?.external_id,
          attachmentId: fileId,
        }),
      );
    }
  };

  useEffect(() => {
    if (emergencyCaseAttachmentUpdateSuccess) {
      setIsAttachmentsActionCompleted(true);
      setActionCompleteText("Attachments updated successfully!");
    }
  }, [emergencyCaseAttachmentUpdateSuccess]);

  useEffect(() => {
    if (emergencyCaseAttachmentDeleteSuccess) {
      setIsAttachmentsActionCompleted(true);
      setActionCompleteText("Attachment deleted successfully!");
    }
  }, [emergencyCaseAttachmentDeleteSuccess]);

  useEffect(() => {
    // runs when component unmounts
    return () => {
      dispatch(resetDeleteEmergencyCaseAttachmentState());
      dispatch(resetUpdateEmergencyCaseAttachmentsState());
    };
  }, [dispatch]);
  return (
    <Dialog
      name="emergency-case-attachments-dialog"
      onClose={onDialogClose}
      header="Edit Attachments"
    >
      <Form id="emergency-case-attachments-form" onFormSubmit={onSuccess}>
        <Stack isVertical gutterSize="large" align="stretch">
          <Stack isVertical>
            <InputFile
              isPending={
                isEmergencyCaseAttachmentUpdating ||
                isEmergencyCaseAttachmentDeleting
              }
              name="emergency_case_files"
              files={attachments}
              onFileRemove={handleFileRemove}
              onFilesUpload={handleFileUpload}
            />
          </Stack>
          {emergencyCaseAttachmentUpdateError && (
            <GenericFormError errorText={emergencyCaseAttachmentUpdateError} />
          )}
          {emergencyCaseAttachmentDeleteError && (
            <GenericFormError errorText={emergencyCaseAttachmentDeleteError} />
          )}
        </Stack>
      </Form>
      <Toast
        isShown={isAttachmentsActionCompleted}
        onHide={() => setIsAttachmentsActionCompleted(false)}
        icon="check"
        text={actionCompleteText}
      />
    </Dialog>
  );
};

export default EditEmergencyCaseAttachmentsDialog;
