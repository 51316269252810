import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, ProgressBar, Stack } from "@csis.com/components";
import CountdownTimer from "@csis.com/tip/src/components/shared/CountdownTimer/CountdownTimer";
import { StatusLabels, StatusValues } from "../constants";
import { getEmergencyCaseInfoResult, getSubmissionCode } from "../selectors";
import { getEmergencyCaseInfo } from "../slice";

export const EmergencyCaseTimer = () => {
  const { emergencyCaseInfo } = useSelector(getEmergencyCaseInfoResult);
  const submissionCode = useSelector(getSubmissionCode);
  const dispatch = useDispatch();

  const { milisecondsLeft, percentage } = useMemo(() => {
    if (!emergencyCaseInfo || !emergencyCaseInfo.submitted_at) {
      return { milisecondsLeft: 0, percentage: 0 };
    }

    const totalDuration = 1000 * 60 * 60 * 4; // 4 hours in milliseconds
    const submittedTime = new Date(emergencyCaseInfo.submitted_at).getTime();
    const currentTime = new Date().getTime();
    const finishTime = submittedTime + totalDuration;
    const diff = finishTime - currentTime;
    const timeSpent = currentTime - submittedTime;
    const percentage = (timeSpent / totalDuration) * 100;

    if (diff <= 0) {
      return { milisecondsLeft: 0, percentage: 100 };
    }

    return { milisecondsLeft: Math.floor(diff / 1000), percentage };
  }, [emergencyCaseInfo]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (emergencyCaseInfo && !emergencyCaseInfo?.decision_at) {
      intervalId = setInterval(() => {
        dispatch(
          getEmergencyCaseInfo({
            caseId: emergencyCaseInfo.external_id,
            code: submissionCode,
          }),
        );
      }, 60000);
    } else {
      intervalId && clearInterval(intervalId);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [dispatch, emergencyCaseInfo, submissionCode]);

  return (
    <Card hasShadow={false}>
      <div className="emergency-case-confirmation-page__timer">
        {emergencyCaseInfo?.decision_at ? (
          <Stack isExpanded isVertical gutterSize="large" align="center">
            {emergencyCaseInfo?.emergency_response_case_status ===
              StatusLabels[StatusValues.IN_PROGRESS] && (
              <Stack isVertical align="center" gutterSize="large">
                <span className="f_big f_semibold f_center">
                  {
                    "We have started investigating your case and will contact you shortly."
                  }
                </span>
                <span className="f_big f_semibold f_center">
                  {"Rest Assured!"}
                </span>
              </Stack>
            )}
            {emergencyCaseInfo?.emergency_response_case_status ===
              StatusLabels[StatusValues.DECLINED] && (
              <Stack isVertical align="center" gutterSize="large">
                <span className="f_big f_semibold f_center">
                  {`Thank you for reaching out to CSIS Security Group A/S for your emergency response needs. We appreciate your trust in our expertise and services. We regret to inform you that, after careful assessment, we are unable to take on your particular case at this time. The reason being ${emergencyCaseInfo?.decision_comment} .`}
                </span>
                <span className="f_big f_semibold f_center">
                  {
                    "We value your understanding on this matter and hope that we can serve you in the future under different circumstances."
                  }
                </span>
              </Stack>
            )}
            {emergencyCaseInfo?.emergency_response_case_status ===
              StatusLabels[StatusValues.CLOSED] && (
              <span className="f_big f_semibold f_center">
                {` The case has been closed.`}
              </span>
            )}
          </Stack>
        ) : (
          <Stack isExpanded isVertical gutterSize="large" align="center">
            <Stack isVertical align="center">
              <span className="f_big f_semibold f_center">
                {"Your case has been received."}
              </span>
              <span className="f_big f_semibold f_center">
                {
                  "We are contacting our Emergency Response Team and will get back to you as soon as possible."
                }
              </span>
            </Stack>

            {emergencyCaseInfo?.is_retainer_case && (
              <Stack isExpanded isVertical align="center">
                <div className="emergency-case-confirmation-page__timer__countdown-wrapper">
                  <CountdownTimer initialCount={milisecondsLeft} />
                </div>
                <div className="emergency-case-confirmation-page__timer__proggress-bar-wrapper">
                  <ProgressBar completed={percentage} />
                </div>
              </Stack>
            )}
          </Stack>
        )}
      </div>
    </Card>
  );
};
